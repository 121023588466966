import React from "react";
import ReactDOM from "react-dom/client";    
import { CONSTANTS } from "../bundles/constants.es6.jsx"; 
import { SearchMenu } from "../components/search_menu.es6.jsx"; 
import { Paginate } from "../components/paginate.es6.jsx"; 
import { OrderStatus } from "../components/order_status.es6.jsx"; 
import Swal from "sweetalert2"; 
import * as Models from '../bundles/model.js';
 
class WelcomeIndex extends React.Component {
  constructor(props) {
    super(props);
 
    this.state = { 
      // "orderModel": (new Models.OrderModel()),
      // "pageSize" : 20,
      // "currentPage": this.props.reactProps.current_page, 
      // "isLastPage": this.props.reactProps.is_last_page, 
      // "showDatas" : true,
      // "currentSite": this.props.reactProps.current_site,
      // "datas": datas
    }
 
    // this.setCurrentPage = this.setCurrentPage.bind(this);
    this.thisComponentStyle = this.thisComponentStyle.bind(this);    
  }
  
  thisComponentStyle(){
    const id = "WelcomeStyle";
    if( document.getElementById(id) == null ){
      const $style = document.createElement("style");
      $style.setAttribute("id", id);
      $style.setAttribute("class", "react-component-style");
      document.head.appendChild($style); 
      $style.innerHTML = `  
 
      `; 
    } 
  }
 
  componentDidMount() { 
    const that = this;  
    that.thisComponentStyle();  
  }
    
  render() {  
    const that = this;   
    return <React.Fragment>  
      <div className="navbar-container" style={{ backgroundColor: "#f07345" }}>
        <nav className="navbar navbar-expand-lg navbar-dark" data-overlay data-sticky="top">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src="/logo.png" style={{ height: "60px" }} />
            </a>
            <div className="collapse navbar-collapse order-3 order-lg-2 justify-content-lg-end" id="navigation-menu">
              <ul style={{ fontSize: "18px", fontWeight: "bold" }} className="navbar-nav my-3 my-lg-0">
                <li className="nav-item">
                  <div className="dropdown">
                    <a style={{ color: "white" }} className="nav-link nav-item aboutUs" role="button" href="#aboutUs">關於我們</a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <a style={{ color: "white" }} className="nav-link nav-item serviceIn" role="button" href="#serviceIn">服務內容</a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <a style={{ color: "white" }} className="nav-link nav-item systemSpec" role="button" href="#systemSpec">系統特色</a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <a style={{ color: "white" }} className="nav-link nav-item priceItem" role="button" href="#priceItem">收費標準</a>
                  </div>
                </li>  
                { !that.props.reactProps.current_user &&
                  <li className="nav-item">
                    <div className="dropdown">
                      <a style={{ border: "1px solid white", borderRadius: "5px", color: "white" }} className="nav-link nav-item" role="button" href="/users/sign_in">登入</a>
                    </div>
                  </li> 
                }
                { !that.props.reactProps.current_user &&
                  <li className="nav-item">
                    <div className="dropdown">
                      <a style={{ border: "1px solid white", borderRadius: "5px", color: "white" }} className="nav-link nav-item ml-2" role="button" href="/users/sign_up">註冊</a>
                    </div>
                  </li> 
                }                
                { that.props.reactProps.current_user &&
                  <li className="nav-item">
                    <ul className="navbar-nav ml-md-auto">
                      <li className="nav-item dropdown">
                        <a style={{ color: "white" }} className="nav-link dropdown-toggle mr-md-2" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          yy
                        </a>
                        <div style={{ backgroundColor: "#f07345" }} className="dropdown-menu dropdown-menu-right" aria-labelledby="bd-user">
                          <a style={{ color: "white" }} className="dropdown-item" href="/users/edit">修改密碼</a>
                          <div className="dropdown-divider"></div>
                          <a style={{ color: "white" }} className="dropdown-item" href="/sites/new">申請新網站</a>
                          <div className="dropdown-divider"></div>                          
                          <a style={{ color: "white" }} className="dropdown-item" rel="nofollow" data-method="delete" href="/users/sign_out">登出</a>
                        </div>
                      </li>
                    </ul>
                  </li> 
                }  
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div className="bg-gradient position-relative" data-overlay>
        <section className="pb-0 pt-5">
          <div className="container">
            <div className="row justify-content-around align-items-center">
              <div className="col-lg-6 col-xl-5 mb-4 mb-sm-5 mb-lg-0">
                <div className="text-white text-center text-lg-left mb-4 mb-xl-5" data-aos="fade-up">
                  <h3>一次貼文 , 多元上架 , 輕鬆方便。<br/><br/>
                    一次付款 , 多元功能 , 清楚明瞭。</h3>
                  <p className="lead">
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-xl-7 col-md-9 slider_ad  mt-5" data-aos="fade-left" data-aos-delay="250">
                <img src="/assets/j/img/slider-46b521e0b012b49e982ad57df29fe6d1.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div> 
          <a className="button-rect btn_down m-auto" href="#btn-rect">
            <img src="/assets/j/img/icon_scrolldown-6ed317cfe778b8ea6dd84491f2e89cac.svg" alt=""/></a>
          <div className="divider divider-bottom bg-white"></div>
        </section>

      </div>
      <section className="pb-0 pt-5 mt-5" id="aboutUs">
        <div className="container" id="btn-rect">
          <div className="row justify-content-center py-md-4">
            <div className="row section-title justify-content-center mt-5">
              <div className="col-md-6 col-lg-6 col-xl-5">
                <h3 className="display-5 text-center">學習！讓生意更升級！</h3>
                <h3 className="display-5 text-center">團媽UP 工作生活都UP</h3>
                <div className="text-left mt-5 title_contant m-4">『團媽』一詞，是社群網路中創業者的代表。
                  創造生活樂趣給消費者，也創造工作機會！
                  解決面對家庭與工作蠟燭兩頭燒的團媽壓力，
                  因而追求【提高團媽工作效能】的系統，是我們一路
                  以來的願景，也是系統開發秉持的態度！
                  因而我們期許透過系統的運用，讓創業者朝著更優質的數位系統前進！<br /><br />
                  <h5 className="mt-3">生意要做大，好的管理系統是您最佳的選擇！</h5>
                </div>
              </div>

            </div>
            <div className="hand_01 d-lg-block d-none"><img src="/assets/j/img/about_hand01-227bcd97869ff7c81b0d664cd2680bde.png" alt="" /></div>
            <div className="hand_02 d-lg-block d-none"><img src="/assets/j/img/about_hand02-6440f382ff49693cf92abf23694bfd64.png" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <hr className="mt-5 mb-0"/>
            </div>
          </div>
        </div>
      </section>
   







      <footer className="bg-primary-3 text-white links-white pb-4 footer-1" id="call">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 mr-xl-5 col-md-6">
              <div className="footer_logo mb-3"><img src="/assets/j/img/logo-bed7482a27abcf07f4b2ef906c20443a.png" alt=""/></div>
              <p>
                系統是解決問題！ 不是有租就好！<br />
                系統是遇到困難！ 有人正面處理！<br />
                系統是營運最大利器！ <br />
                不要省錢丟了市場！<br />
                <div className="mt-3">歡迎有任何問題都可以詢問喔！</div>
              </p>
            </div>
            <div className="col-xl-auto mr-xl-5 col-md-6">
              <h5>團媽UP</h5>
              <ul className="nav flex-row flex-md-column">
                <li className="nav-item mr-3 mr-md-0">
                  <a href="#aboutUs" className="aboutUs nav-link px-0 py-2">關於我們</a>
                </li>
                <li className="nav-item mr-3 mr-md-0">
                  <a href="#serviceIn" className="serviceIn nav-link px-0 py-2">服務內容</a>
                </li>
                <li className="nav-item mr-3 mr-md-0">
                  <a href="#systemSpec" className="systemSpec nav-link px-0 py-2">系統特色</a>
                </li>
                <li className="nav-item mr-3 mr-md-0">
                  <a href="#priceItem" className="priceItem nav-link px-0 py-2">收費標準</a>
                </li>
                <li className="nav-item mr-3 mr-md-0">
                  <a href="/privacy_and_terms" className="nav-link px-0 py-2">隱私權政策與服務條款</a>
                </li>
              </ul>
            </div>
            <div className="col-lg mt-2 mt-md-5 mt-lg-0 order-lg-3 order-xl-4">
              <h5>聯絡我們</h5>
              <div className="col-12 mt-3 pl-0">
                <a href="https://www.facebook.com/MammyUp.tw" className="hover-fade-out d-flex align-items-center"
                  target="_blank">
                  <div className="facebook_icon d-flex justify-content-center align-items-center">
                    <img src="/assets/j/img/facebook-2e0a7b97dd863eff96b081c647b86fab.svg" alt="Facebook" className="icon icon-xs"/>
                  </div>
                  <div className="ml-2">粉絲專頁</div>
                </a>
              </div>
              <div className="col-12 mt-3 pl-0 mb-5">
                <a href="https://line.me/R/ti/p/fS6adWbRTW" className="hover-fade-out d-flex align-items-center"
                  target="_blank">
                  <div className="line_icon d-flex justify-content-center align-items-center">
                    <img src="/assets/j/img/line-217f3a7f063ff6202dd888075a750e4e.png" alt="LINE" className=""/>
                  </div>
                  <div className="ml-2">專屬ID @mammyup</div>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <hr/>
            </div>
          </div>
          <div className="row flex-column flex-lg-row align-items-center justify-content-center  text-center">
            <div className="col-auto">
              <div className="d-flex flex-column flex-sm-row align-items-center text-small">
                <div className="text-muted"> 本站最佳瀏覽環境請使用Google Chrome、Firefox或IE10以上版本 Powered by &copy; 團媽UP
                </div>
              </div>
            </div>

          </div>
        </div>
      </footer> 
    </React.Fragment>;
  }  
} 
  
window.reactComponents = window.reactComponents ? window.reactComponents : {};
window.reactComponents.WelcomeIndex = WelcomeIndex;