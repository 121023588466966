import React from 'react'
import ReactDOM from "react-dom"; 

class SearchMenu extends React.Component {
  constructor(props) {
    super(props);
    let { settings } = this.props;
 
    this.state = {
      data : settings
    } 
  }

  componentDidMount() {  

  }
 
  createDom(obj,index) {
    let that = this; 

    switch(obj.type) {
      case "input": 
        return <div key={index} className="form-group"> 
            <label style={{ marginLeft: "3px"}}>{obj.title}</label>
            <input style={{ marginLeft: "3px"}} type="text" className="form-control" name={obj.name} placeholder={obj.placeholder} value={obj.value} onChange={(e)=>{that.state.data[index] = { ...obj, value: e.target.value }; that.setState({data: that.state.data});}}></input>
          </div> 
      case "select": 
        return <div key={index} className="form-group">
          {obj.showTitle &&
            <label style={{ marginLeft: "3px"}}>{obj.title}</label> 
          }
          <select style={{ marginLeft: "3px"}} name={obj.name} className="form-control" value={obj.value} onChange={(e)=>{that.state.data[index] = { ...obj, value: e.target.value }; that.setState({data: that.state.data});}}>
            {obj.defaultOption != undefined && <option value={obj.defaultOption.value}>{obj.defaultOption.name}</option>}
            { obj.options.map((option, opInx) => 
              Array.isArray(option) ? <option key={opInx} value={option[1]}>{option[0]}</option> : <option key={opInx} value={option.value}>{option.name}</option>
            )}     
          </select>
        </div> 
      case "productTagSelect": 
        return <div key={index} className="form-group">
          {obj.showTitle &&
            <label style={{ marginLeft: "3px", marginRight: "3px"}}>{obj.title}</label> 
          }
          <select className="form-control" value={ obj.value } name={obj.name} onChange={(e)=>{that.state.data[index] = { ...obj, value: e.target.value }; that.setState({data: that.state.data});}}> 
            <option value="">請選擇</option>  
            { obj.productTags.filter((v,i)=>{ return (v["product_category_id"] ?? "" ) == "" }).length > 0 && 
              <optgroup label="無分類">
                { obj.productTags.map((tag, index2) =>  
                  <option className={ `${ (tag["product_category_id"] ?? "" ) == "" ? "" : "hide" }` } value={ tag["id"] } key={ index2 }>{ tag["name"] }</option>
                )}
              </optgroup>
            } 
            { obj.productCategories.map((product_category, index) =>  
              <optgroup key={ index } label={ product_category.name }>
                { obj.productTags.map((tag, index2) =>  
                  <option className={ `${ tag["product_category_id"] == product_category.id ? "" : "hide" }` } value={ tag["id"] } key={ index2 }>{ tag["name"] }</option>
                )}
              </optgroup> 
            )} 
          </select>
        </div>

    }
  }

  render () {  
    let { data } = this.state; 

    return <React.Fragment>
      { data.map((dom, index) => 
        this.createDom(dom,index)
      )}
    </React.Fragment>;
  }   
}

SearchMenu.defaultProps = {
  settings: [], 
};

export { SearchMenu }  
