Array.prototype.ids_to_class = async function(className) {
  if ( !this || this.length == 0 ) {
    return [];
  } 
  if (className == "Orders") {
    let orders = (await new OrderModel().where("id in ?", [this]).get_datas()).rows;
    return orders.to_orders_class();
  }
  if (className == "Users") {
    let users = (await new UserModel().where("id in ?", [this]).get_datas()).rows;
    return users.to_users_class();
  }  
  if (className == "Products") {
    let products = (await new ProductModel().where("id in ?", [this]).get_datas()).rows;
    return products.to_products_class();
  }
  if (className == "CouponRelations") {
    let couponRelations = (await new CouponRelationModel().where("id in ?", [this]).get_datas()).rows;
    return couponRelations.to_coupon_relations_class();
  }
  if (className == "Coupons") {
    let coupons = (await new CouponModel().where("id in ?", [this]).get_datas()).rows;
    return coupons.to_coupons_class();
  }
  if (className == "ShipmentItems") {
    let shipmentItems = (await new ShipmentItemModel().where("id in ?", [this]).get_datas()).rows;
    return shipmentItems.to_shipment_items_class();
  }  
  if (className == "Shipments") {
    let shipments = (await new ShipmentModel().where("id in ?", [this]).get_datas()).rows;
    return shipments.to_shipments_class();
  }   
}    
Array.prototype.batch_load = async function(loadName, params = []) {
  const props = {
    user: { className : "Users", foreignKey: "user_id"},
    product: { className : "Products", foreignKey: "product_id"},
    site: { className : "Sites", foreignKey: "site_id"},
    creator: { className : "Users", foreignKey: "created_by"}, 
    updator: { className : "Users", foreignKey: "updated_by"}, 
    coupon_relation: { className : "CouponRelations", foreignKey: "coupon_relation_id"} 
  }

  let { className, foreignKey } = props[loadName];
 
  foreignKey = foreignKey ?? `${ loadName }_id`;
  let datas = await this.map(d => d[foreignKey]).ids_to_class(className);
  for ( let each of this ) { 
    if (each[foreignKey]) { 
      await each['load_'+loadName].apply(each,[ datas, ...params]);
    } 
  }  
  return this;
} 
Array.prototype.batch_load_product_tags = async function(productTags = [], productTagRelations = []) {
  for( let each of this ){ 
    if (each instanceof ProductModel) {
      each.load_product_tags(productTags, productTagRelations);
    } else if ( each instanceof ProductCategoryModel ) {
      each.load_product_tags(productTags);
    } 
  } 
  return this;
}
Array.prototype.batch_load_product = async function(datas) {
  if (datas) {
    for( let each of this ){
      each.load_product(datas);
    }
    return this;
  } else { 
    return await this.batch_load("product");
  } 
} 
Array.prototype.batch_load_user = async function(datas) {
  if (datas) {
    for( let each of this ){
      each.load_user(datas);
    }
    return this;
  } else { 
    return await this.batch_load("user");
  } 
} 
Array.prototype.batch_load_site = async function(datas) {
  if (datas) {
    for( let each of this ){
      each.load_site(datas);
    }
    return this;
  } else { 
    return await this.batch_load("site");
  } 
} 
Array.prototype.batch_load_coupon_relation = async function(datas) {
  if (datas) {
    for( let each of this ){
      each.load_coupon_relation(datas);
    }
    return this;
  } else { 
    return await this.batch_load("coupon_relation");
  } 
} 
Array.prototype.batch_load_creator = async function(datas) {
  if (datas) {
    for( let each of this ){
      each.load_creator(datas);
    }
    return this;
  } else { 
    return await this.batch_load("creator");
  }
} 
Array.prototype.to_product_categories_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof ProductCategoryModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ProductCategoryModel(each)); 
    } 
  }
  return modelArray;
} 
Array.prototype.to_advances_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof AdvanceModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new AdvanceModel(each)); 
    } 
  }
  return modelArray;
}  
Array.prototype.to_charged_record_payments_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof ChargedRecordPaymentModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ChargedRecordPaymentModel(each)); 
    } 
  }
  return modelArray;
} 
Array.prototype.to_sites_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof SiteModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new SiteModel(each)); 
    } 
  }
  return modelArray;
}  
Array.prototype.to_charged_records_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof ChargedRecordModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ChargedRecordModel(each)); 
    } 
  }
  return modelArray;
}  
Array.prototype.to_shipments_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof ShipmentModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ShipmentModel(each)); 
    } 
  }
  return modelArray;
}  
Array.prototype.to_shipment_items_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof ShipmentItemModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ShipmentItemModel(each)); 
    } 
  }
  return modelArray;
}   
Array.prototype.to_advance_balances_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof AdvanceBalanceModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new AdvanceBalanceModel(each)); 
    } 
  }
  return modelArray;
}  
Array.prototype.to_orders_class = function(isIds = false) {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof OrderModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new OrderModel(each)); 
    } 
  }
  return modelArray;
}  
Array.prototype.to_coupons_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof CouponModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new CouponModel(each)); 
    } 
  }
  return modelArray;
}  
Array.prototype.to_coupon_product_relations_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof CouponProductRelationModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new CouponProductRelationModel(each)); 
    } 
  }
  return modelArray;
}   
Array.prototype.to_coupon_limits_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof CouponLimitModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new CouponLimitModel(each)); 
    } 
  }
  return modelArray;
}   
Array.prototype.to_coupon_relations_class = function() {
  let modelArray = [];
  for ( let each of this ) { 
    if (each instanceof CouponRelationModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new CouponRelationModel(each)); 
    } 
  }
  return modelArray;
}   
Array.prototype.to_product_models_class = function() {
  let modelArray = []; 
  for ( let each of this ) { 
    if (each instanceof ProductModelClass) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ProductModelClass(each)); 
    } 
  } 
  return modelArray;
}  
Array.prototype.to_users_class = function() {
  let modelArray = []; 
  for ( let each of this ) { 
    if (each instanceof UserModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new UserModel(each)); 
    } 
  }  
  return modelArray;
}  
Array.prototype.to_products_class = function() {
  let modelArray = []; 
  for ( let each of this ) { 
    if (each instanceof ProductModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ProductModel(each)); 
    } 
  }  
  return modelArray;
}  
Array.prototype.to_product_prices_class = function() {
  let modelArray = []; 
  for ( let each of this ) { 
    if (each instanceof ProductPriceModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ProductPriceModel(each)); 
    } 
  } 
  return modelArray;
}
Array.prototype.to_product_images_class = function() {
  let modelArray = []; 
  for ( let each of this ) { 
    if (each instanceof ProductImageModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new ProductImageModel(each)); 
    } 
  } 
  return modelArray;
}   
Array.prototype.to_memberships_class = function() {
  let modelArray = []; 
  for ( let each of this ) { 
    if (each instanceof MembershipModel) { 
      modelArray.push(each); 
    } else { 
      modelArray.push(new MembershipModel(each)); 
    } 
  } 
  return modelArray;
}   
 
// Array.prototype.load_coupon_relation = async function(throughWhatColumn) {
//   if (this.first() instanceof OrderModelModel) {
//     let orderIds = this.map(o => o.id); 
//     let buyOrderCouponRelations = (await (new CouponRelationModel()).where(` ${ throughWhatColumn } in ? `,[orderIds]).get_datas()).rows.to_coupon_relations_class();

//     let orderModels = this;
//     for ( let order of orderModels ) {
//       order.load_coupon_relation(throughWhatColumn, buyOrderCouponRelations); 
//     } 
//     return orderModels; 
//   }
//   return [];  
// }   

Array.prototype.load_coupon_limits = async function(couponLimits) {
  if (this.first() instanceof CouponModel) {
    let couponIds = this.map(c => c.id); 
    couponLimits = couponLimits ? couponLimits.to_coupon_limits_class() : (couponIds.length == 0 ? [] : (await (new CouponLimitModel()).where("coupon_id in ? ",[couponIds]).get_datas()).rows.to_coupon_limits_class());
    let couponModels = this;
    for ( let couponModel of couponModels ) {
      couponModel.load_coupon_limits(couponLimits); 
    } 
    return couponModels;
  }
  return [];  
} 

Array.prototype.load_product_prices = async function(productPrices) {
  if (this.first() instanceof ProductModelClass) {
    let productModelIds = this.map(c => c.id); 

    if (productPrices == undefined) { 
      productPrices = productModelIds.length == 0 ? [] : (await (new ProductPriceModel()).where("product_model_id in ? ",[productModelIds]).get_datas()).rows.to_product_prices_class();
    }

    let productModels = this;
    for ( let productModel of productModels ) {
      productModel.load_product_prices(productPrices); 
    } 
    return productModels;    
  }
  return []; 
} 
 
export class Model {
  constructor(that) { 
    for (const key in that) {
      if (that["id"] && key.indexOf('_was') == -1) {
        this[`${key}_was`] = that[key];
      } 
      this[`${key}`] = that[key];
    } 
    this.tableName = ""; 
    this.customSelect = "*";
    this.orderBy = "id DESC";
    this.keyWhereConditions = {};
    this.customeWhereConditions = {};
    this.joinConditions = [];
    this.currentPage = 1; //default 1
    this.pageSize = 20; //default 20
    this.afterSaveData = {}; 
    this.dataCount = 0; 
  }
 
  async ajax(params={}){  
    let { method="GET", contentType="application/json", body=null, url=""  } = params;
    let settings = {
      "method": method, 
      headers: {
        'Content-Type': contentType,
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      } 
    };

    if (body) {
      settings["body"] = JSON.stringify(body);
    }

    try {
      const fetchResponse = await fetch(url, settings);
      const data = await fetchResponse.json();  
      return data; 
    } catch (e) { 
      return {
        success: false,
        errors: [e.message]
      } 
    }  
  } 
 
  async destroy_by_ajax(params={}){  
    if ( !params["domain"] ) { 
      alert('沒有定義domain');
      return false;
    }  
    if ( !params["tablePluralName"] || !params["tableSingularName"] ) { 
      alert('沒有定義tablePluralName 或是 tableSingularName');
      return false;
    }
 
    let body = {};
    body[params["tableSingularName"]] = params["data"];

    return await this.ajax({
      ...params,
      method: "DELETE",  
      body:body, 
      url:`/${ params["domain"] }/${ params["isBackend"] ? "backend/" : "" }${ params["tablePluralName"] }${ params["isNew"] ? "" : ("/"+params["id"]) }`
    }); 
  }  

  async save_ajax(params={}){  
    if ( params["isNew"] !== true && params["isNew"] !== false ) { 
      alert('沒有定義isNew');
      return false;
    } 
    if ( !params["isAdmin"] && !params["domain"] ) { 
      alert('沒有定義domain');
      return false;
    } 
    if ( !params["isNew"] && !params["id"] ) { 
      alert('沒有定義id');
      return false;
    }
    if ( !params["tablePluralName"] || !params["tableSingularName"] ) { 
      alert('沒有定義tablePluralName 或是 tableSingularName');
      return false;
    }
 
    let body = {};
    body[params["tableSingularName"]] = params["data"];

    return await this.ajax({
      ...params,
      method: params["isNew"] ? "POST" : "PUT",  
      body:body, 
      url:`/${ params["domain"] ? `${ params["domain"] }/` : `` }${ params["isBackend"] ? "backend/" : "" }${ params["isAdmin"] ? "admin/" : "" }${ params["path"] ? params["path"] : params["tablePluralName"] }${ params["isNew"] ? "" : ("/"+params["id"]) }`
    }); 
  }  

  async get_datas_by_ajax(params) {  
    if ( !params["domain"] ) { 
      alert('沒有定義domain');
      return false;
    }  
    if ( !this.tableName ) { 
      alert('沒有定義 this.tableName');
      return false;
    } 
 
    // let body = {};
    // body = params; 

    params["isAjax"] = true;

    let searchParams = new URLSearchParams(params);
 
    return await this.ajax({
      ...params,
      method: "GET",   
      url:`/${ params["domain"] }/${ params["isBackend"] ? "backend/" : "" }${ this.tableName }.json?${ searchParams.toString() }`
    }); 
  }

  clone(){
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  filter_whitelist(whitelist) {
    let result = {};
    for (let key in this) {
      if (whitelist.includes(key)) {
          result[key] = this[key];
      }
    }
    return result;
  }

  to_json(keys){
    return this.filter_whitelist(keys);
  }
 
  page_size(size){
    this.pageSize = size;
    return this;
  }

  current_page(page){
    this.currentPage = page;
    return this;
  }

  after_save(){
    return {...this.afterSaveData};
  }

  clear_conditions() {
    this.keyWhereConditions = {};
    this.customeWhereConditions = {};
    this.joinConditions = [];
    return this;
  }

  set_import(params) {  
    for( let key in params ) { 
      this[key] = params[key];
    } 
    return this;
  }

  set_id(id) {
    this.id = id;
    return this;
  }

  set_uuid() {
    this.uuid = this.uuid ? this.uuid : Utility.generateID();
    return this;
  }

  async set_last_page() {
    this.dataCount = await this.get_data_count(); 
    this.currentPage = Math.ceil( this.dataCount/this.pageSize ); 
  }

  async get_last_page() {
    let dataCount = await this.get_data_count(); 
    return Math.ceil( dataCount/this.pageSize ); 
  }  

  page(value) {  
    if (Number.isInteger(Number(value))) { 
      this.currentPage = Number(value);
    } else {
      alert('page只可輸入數字'); 
    }  
    return this;
  } 
  size(value) { 
    if (Number.isInteger(Number(value))) { 
      this.pageSize = Number(value);
    } else {
      alert('size只可輸入數字');  
    }
    return this;
  }   

  join(condition){
    this.joinConditions.push(condition);
    return this;
  } 

  where(key, value = []) {
    if( Array.isArray(value) ){
      this.customeWhereConditions[key] = value;
    } else { 
      this.keyWhereConditions[key] = value; 
    }
    return this;
  } 

  select(customSelect){
    this.customSelect = customSelect;
    return this;
  }

  order_by(orderBy){
    this.orderBy = orderBy;
    return this;
  }

  async get_data_count(){
    let that = this; 
    let sqlObjs = [{sql: "BEGIN"}, 
    { 
      tableName: this.tableName,
      sql: `SELECT COUNT(*) FROM ${ this.tableName } ` 
    }, 
    {sql: "END"}];

    let count = 0;
    let isLastPage = false;
    await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => { 
      count = results?.datasByTableName[that.tableName]?.rows[0]["count"];
    }).catch(async err => { 
      if( err?.message ){ 
        alert('發生錯誤', err.message, 'error'); 
      } 
      throw err;
    });
    return count;
  }
 
  generate_sql(customeWhereConditions, keyWhereConditions) {
    let sql = "";
    let params = [];
    let i = 1;

    // Add keyWhereConditions to the SQL query
    for (const [key, value] of Object.entries(keyWhereConditions)) {
      sql += `${key} = ? AND `;
      if (Array.isArray(value)) {
        params = [ ...params, ...value ]; 
      } else { 
        params.push(value);
      } 
      i++;
    }

    // Add customeWhereConditions to the SQL query
    for (const [key, value] of Object.entries(customeWhereConditions)) { 
      let condition = key;
 
      for (let data of value) {
        //如果 碰到 內容是 array 就改造sql內容
        if (Array.isArray(data)) { 
          condition = condition.replace("?",` (${ Array.from({length: data.length}, () => "$").join() }) `);
        } else { 
          condition = condition.replace("?","$");
        } 
      }
      condition = condition.replace(/\$/gi,'?'); 

      sql += `( ${condition} ) AND`;  

      value.forEach((val) => { 
        if (Array.isArray(val)) {
          params = [ ...params, ...val ]; 
        } else { 
          params.push(val);
        } 
        i++;
      });
    }

    // Remove the extra "AND" at the end of the query
    sql = sql.slice(0, -4);

    i = 1; 
    return {
      sql : sql.replace(/\?/g, () => `$${i++}`),
      params: params,
    };
  }

  get_sql() { 
    let sqlResult = this.generate_sql(this.customeWhereConditions, this.keyWhereConditions);
    let currentPage = this.currentPage; 
    let pageSize = this.pageSize;  

    let sql = `SELECT ${ this.customSelect } FROM ${ this.tableName } 
            ${ this.joinConditions.join(" ") }
            ${ sqlResult["sql"].length > 0 ? "WHERE" : "" } ${ sqlResult["sql"] } 
            ${ this.orderBy ? `ORDER BY ${ this.orderBy }` : `` }  
            ${ pageSize ? `LIMIT ${ pageSize + 1 } ` : `` }
            ${ (currentPage && pageSize) ? `OFFSET ${ (currentPage-1)*pageSize }` : `` } 
            `; 
    let params = sqlResult["params"]; 
    return {
      sql: sql,
      params: params
    }
  }

  async get_datas(){  
    let that = this;
    let whereClauseParts = [];  
    let sqlResult = this.generate_sql(this.customeWhereConditions, this.keyWhereConditions);
    let sqlObjs = [{sql: "BEGIN"}, 
    { 
      tableName: this.tableName,
      sql:  `SELECT ${ this.customSelect } FROM ${ this.tableName } 
            ${ this.joinConditions.join(" ") }
            ${ sqlResult["sql"].length > 0 ? "WHERE" : "" } ${ sqlResult["sql"] }  
            ${ this.orderBy ? `ORDER BY ${ this.orderBy }` : `` } 
            ${ this.pageSize ? `LIMIT ${ this.pageSize + 1 } ` : `` }
            ${ (this.currentPage && this.pageSize) ? `OFFSET ${ (this.currentPage-1)*this.pageSize }` : `` } 
            `, 
      params: sqlResult["params"]
    }, 
    {sql: "END"}];

    let rows = [];
    let isLastPage = false;
    await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => { 
      rows = results?.datasByTableName[that.tableName]?.rows;
      // Object.assign(this, product); 
    }).catch(async err => { 
      if( err?.message ){ 
        alert('發生錯誤', err.message, 'error'); 
      } 
      throw err;
    }); 
 
    if (this.pageSize) {
      isLastPage = rows.length > this.pageSize ? false : true;
      rows = rows.slice(0, this.pageSize); 
    } 

    return {
      rows: rows,
      isLastPage: isLastPage
    };
  }

  async destroy(){
    let that = this; 
    let sqlObjs = [{sql: "BEGIN"}, 
      ...this.destroy_sql(), 
    {sql: "END"}];

    let rows = [];
    let rowCount = 0; 
    await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => { 
      rowCount = results?.datasByTableName[that.tableName]?.rowCount; 
    }).catch(async err => { 
      if( err?.message ){ 
        alert('發生錯誤', err.message, 'error'); 
      } 
      throw err;
    }); 
  
    return {
      rowCount: rowCount 
    };
  }
 
  destroy_sql(){
    let that = this;
    let whereClauseParts = [];  
    let defaultConditions = {};

    if (that.id) {
      defaultConditions["id"] = that.id;
    }

    let keyWhereConditions = { ...defaultConditions, ...this.keyWhereConditions }; 
    let sqlResult = this.generate_sql(this.customeWhereConditions, keyWhereConditions);
 
    let hasWhereConditions = sqlResult["params"].length > 0;
    let sqlObjs = []; 
    if( hasWhereConditions || that.id ){
      sqlObjs = [
      ...sqlObjs,
      { 
        tableName: this.tableName,
        sql:  `DELETE FROM ${ this.tableName }  
              ${ hasWhereConditions ? "WHERE" : "" } ${ sqlResult["sql"] }    
              `, 
        params: sqlResult["params"] 
      },{ 
        sql:  `SELECT id FROM ${ this.tableName }  
              ${ hasWhereConditions ? "WHERE" : "" } ${ sqlResult["sql"] } 
              `,
        params: sqlResult["params"],        
        validate: function(currentResult, allResults){   
          let success = (currentResult.rows.length == 0); 
          return success;
        },
        errorMessage: "刪除失敗"
      }];
    } else {
      alert('不可以無條件刪除資料');
    }
  
    return [...sqlObjs];
  }
 
  async load_order(orders) {  
    if (this.order_id == 0) {
      return; 
    }

    let row = await this.load_one_relation_table_by_foreign_key(orders, "orders", "order", "order_id");
    return row;  
  }

  async load_gift_orders(orders, foreignKey, CONSTANTS) {  
    let rows = await this.load_plural_relation_table_by_foreign_key(orders, "orders", foreignKey);  //"coupon_relation_id"
    rows = rows ? rows.filter(o => o.coupon_type == CONSTANTS.CouponProductRelation.TYPE["贈品"] ) : []; 
    this.gift_orders = this.orders ? this.orders.to_orders_class().filter(o => o.coupon_type == CONSTANTS.CouponProductRelation.TYPE["贈品"] ) : [];  
    return rows;  
  }

  async load_buy_order(orders, foreignKey, CONSTANTS) {  
    let rows = await this.load_plural_relation_table_by_foreign_key(orders, "orders", foreignKey);  //"coupon_relation_id"
    rows = rows ? rows.filter(o => o.coupon_type == CONSTANTS.CouponProductRelation.TYPE["優惠商品"] ) : []; 
    this.buy_order = this.orders ? this.orders.to_orders_class().filter(o => o.coupon_type == CONSTANTS.CouponProductRelation.TYPE["優惠商品"] )?.first() : null;  
    return rows;  
  }

  load_member_level(member_levels) {   
    if( member_levels ){
      this.member_level = member_levels.filter(m => m.id == this.member_level_id).first();
    } 
    return this; 
  }

  async load_product(products) {  
    let row = await this.load_one_relation_table_by_foreign_key(products, "products", "product", "product_id");
    return row; 
  }

  async load_product_model(product_models) {  
    let row = await this.load_one_relation_table_by_foreign_key(product_models, "product_models", "product_model", "product_model_id");
    return row; 
  }

  async load_coupon_relation(coupon_relations) {  
    let row = await this.load_one_relation_table_by_foreign_key(coupon_relations, "coupon_relations", "coupon_relation", "coupon_relation_id");
    return row; 
  }  

  load_gift_product(products) {  
    if( products ){
      this.gift_product = products.filter(p => p.id == this.gift_product_id).to_products_class().first();
      return this; 
    }     
  }

  load_buy_product(products) {  
    if( products ){
      this.buy_product = products.filter(p => p.id == this.buy_product_id).to_products_class().first();
      return this; 
    }     
  }

  async load_user(users) {  
    let row = await this.load_one_relation_table_by_foreign_key(users, "users", "user", "user_id");
    return row; 
  }
 
  async load_creator(users) {  
    if( users ){
      this.creator = users.filter(u => u.id == this.created_by).to_users_class().first();
      return this; 
    } else { 
      let users = (await new UserModel().where("id in ?", [[this.created_by]]).get_datas()).rows.to_users_class();
      let that = await this.load_creator(users);  
      return that; 
    } 
  }

  async load_updator(users) {  
    if( users ){
      this.updator = users.filter(u => u.id == this.updated_by).to_users_class().first();
      return this; 
    } else { 
      let users = (await new UserModel().where("id in ?", [[this.updated_by]]).get_datas()).rows.to_users_class();
      let that = await this.load_updator(users);  
      return that; 
    } 
  }

  async load_site(sites) { 
    let row = await this.load_one_relation_table_by_foreign_key(sites, "sites", "site", "site_id");
    return row; 
  } 

  async load_coupon(coupons) { 
    let row = await this.load_one_relation_table_by_foreign_key(coupons, "coupons", "coupon", "coupon_id");
    return row; 
  } 

  async load_coupon_limits(coupon_limits, foreignKey) { 
    let rows = await this.load_plural_relation_table_by_foreign_key(coupon_limits, "coupon_limits", foreignKey);
    rows = rows ? rows : [];
    for ( let row of rows ) {
      row.resource = await (new CouponLimitModel(row)).load_resource();
    }
    return rows; 
  } 

  async load_coupon_product_relations(coupon_product_relations, foreignKey) { 
    let rows = await this.load_plural_relation_table_by_foreign_key(coupon_product_relations, "coupon_product_relations", foreignKey);
    rows = rows ? rows : []; 
    return rows; 
  } 

  async load_product_prices(product_prices, foreignKey) { 
    let rows = await this.load_plural_relation_table_by_foreign_key(product_prices, "product_prices", foreignKey);
    rows = rows ? rows : []; 
    return rows; 
  }  

  async load_product_models(product_models, foreignKey) { 
    let rows = await this.load_plural_relation_table_by_foreign_key(product_models, "product_models", foreignKey);
    rows = rows ? rows : []; 
    return rows; 
  }   
 
  async load_plural_relation_table_by_foreign_key(datas, tablePluralName, foreignKey) { 
    if (this["id"] == undefined) {
      alert(`請先定義id`);
      return [];
    }

    let that = this;
    if (datas) {
      this[tablePluralName] = datas.filter(o => o.id == that[foreignKey]);
    } else { 
      let sqlObjs = [{sql: "BEGIN"}, 
      { 
        tableName: tablePluralName,
        sql: `SELECT * FROM ${ tablePluralName } WHERE ${foreignKey} = $1`, 
        params: [ this["id"] ] 
      }, 
      {sql: "END"}];

      await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => { 
        this[tablePluralName] = results?.datasByTableName[tablePluralName]?.rows ?? [];
      }).catch(async err => { 
        if( err?.message ){ 
          alert('發生錯誤', err.message, 'error'); 
        } 
        throw err;
      });         
    } 
    return this[tablePluralName];  
  } 
 
  async load_one_relation_table_by_foreign_key(datas, tablePluralName, tableSingularName, foreignKey) { 
    if (this[foreignKey] == undefined) {
      alert(`請先定義${ foreignKey }`);
      return [];
    }

    let that = this;
    if (datas) {
      this[tableSingularName] = datas.filter(o => o.id == that[foreignKey]).first();

      if (!this[tableSingularName]) {
        alert(`load_${tableSingularName}失敗`);
      } 
    } else { 
      let sqlObjs = [{sql: "BEGIN"}, 
      { 
        tableName: tablePluralName,
        sql: `SELECT * FROM ${ tablePluralName } WHERE id = $1`, 
        params: [ that[foreignKey] ] 
      }, 
      {sql: "END"}];

      await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => { 
        this[tableSingularName] = results?.datasByTableName[tablePluralName]?.rows[0];
      }).catch(async err => { 
        if( err?.message ){ 
          alert('發生錯誤', err.message, 'error'); 
        } 
        throw err;
      });         
    } 
    return this[tableSingularName];  
  }
 
  dynamic_update_number() { 
    let that = this;
    let number = null;
    return [{   
      dynamicParams: {
        number: function(results){   
          number = that.set_number_append_id(results[that.tableName].rows[0].id); 
          that.afterSaveData["number"] = number;
          return number;  
        },
        id: function(results){   
          return results[that.tableName].rows[0].id;  
        }         
      },  
      sql: `
        UPDATE ${ that.tableName }
        SET number = {@number}
        WHERE id = {@id}`
    }];  
  }

  set_number_append_id(id) {
    id = id*1 > 0 ? id : -1*id; 
    const now = new Date();
    let year = now.getFullYear().toString();
    let month = (now.getMonth() + 1).toString().padStart(2, '0');
    let day = now.getDate().toString().padStart(2, '0');
    let hour = now.getHours().toString().padStart(2, '0');
    let minute = now.getMinutes().toString().padStart(2, '0');
    let second = now.getSeconds().toString().padStart(2, '0');
    let milliseconds = now.getMilliseconds().toString().padStart(3, '0');

    let number = `${year}${month}${day}${hour}${minute}${second}${milliseconds}`;
    let idString = (id % 1000).toString().padStart(3, '0');

    if (Number.isInteger(Number(idString))) {
      return `P${number.substring(0, number.length - 4)}${idString}`; 
    } else {
      return null;
    } 
  } 
}
 
export class ShipmentModel extends Model {
  constructor(that) { 
    super(that)

    this.tableName = "shipments";
  }
 
  async load_shipment_items_and_orders_and_advances(orders, shipmentItems, advances) { 
    if( orders ) {  
      this.shipment_items = shipmentItems.filter(i => i.shipment_id == this.id);

      for ( let shipmentItem of this.shipment_items ) {
        await shipmentItem.load_order(orders);
      }

      this.advances = advances.filter(i => i.shipment_id == this.id);
      let thisShipmentOrderIds = shipmentItems.filter(i => i.shipment_id == this.id).map(i => i.order_id );
      this.orders = orders.filter(o=> thisShipmentOrderIds.includes(o.id) );  
    } else { 
      let shipmentItems = (await new ShipmentItemModel().where("shipment_id", this.id).get_datas()).rows.to_shipment_items_class();
      let orders = await shipmentItems.map(i => i.order_id).ids_to_class("Orders"); 
      let advances = (await new AdvanceModel().where("shipment_id", this.id).get_datas()).rows.to_advances_class();
      return await this.load_shipment_items_and_orders_and_advances(orders, shipmentItems, advances);
    } 
    return this;
  }  

  async load_coupon_relation(couponRelations){
    if( couponRelations ){ 
      couponRelations = couponRelations.to_coupon_relations_class();
      this.coupon_relation = couponRelations?.filter( c => c.shipment_id == this.id )?.first();
      return this; 
    } else {  
      let couponRelations = (await (new CouponRelationModel()).where(" shipment_id = ? ",this.id).get_datas()).rows.to_coupon_relations_class();
      return this.load_coupon_relation(couponRelations);
    } 
  }

  async batch_load_coupon_relation_and_gift_orders(shipments, couponRelations = null, shipmentGiftOrders = null) {  
    shipments = shipments.to_shipments_class(); 
    let shipmentIds = shipments.map(o => o.id);

    if (!shipmentIds || shipmentIds.length == 0 ) {
      return shipments;
    }

    couponRelations = couponRelations ? couponRelations.to_coupon_relations_class() : (await (new CouponRelationModel()).where(" shipment_id in ? ",[shipmentIds]).get_datas()).rows.to_coupon_relations_class();
    let couponRelationIds = couponRelations.map(c => c.id); 
    shipmentGiftOrders = shipmentGiftOrders ? shipmentGiftOrders.to_orders_class() : (couponRelationIds.length == 0 ? [] : (await (new OrderModel()).where("coupon_relation_id in ? ",[couponRelationIds]).get_datas()).rows.to_orders_class());

    shipmentGiftOrders = await new OrderModel().batch_load_product(shipmentGiftOrders);

    for ( let shipment of shipments ) { 
      shipment.load_coupon_relation(couponRelations); 
      if (shipment.coupon_relation) {
        shipment.coupon_relation.set_import({ "CONSTANTS" : this.CONSTANTS }).load_gift_orders(shipmentGiftOrders);
        let giftOrderModels = ( shipment.coupon_relation.gift_orders ?? [] ).to_orders_class();
      } 
    }
  
    return shipments;  
  } 
 
  dynamic_validate_number(){
    return [{ 
      sql: "SELECT * FROM shipments WHERE id = {@shipment_id} AND shipments.number IS NOT NULL", 
      dynamicParams: {
        shipment_id: function(results){   
          return results.shipments.rows[0].id;  
        }
      },
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length != 0); 
        return success;
      },
      errorMessage: "尚未寫入出貨編號"
    }]; 
  }
 
  validate_column_logic_sql(){
    return [
      ...this.dynamic_validate_number(),
      // ...this.validate_advance_balance() 
    ];
  } 

  set_buy_orders(orders){
    this.buy_orders = orders.to_orders_class();
    return this;
  }
 
  set_remark(remark){
    this.remark = remark;
    return this;
  }

  set_shipped_at(shipped_at){
    this.shipped_at = shipped_at;
    return this;
  }
 
  set_status(status){

    if ( status && !this.CONSTANTS) {
      alert('shipment set_status 沒有 this.CONSTANTS');
      return this;
    }
 
    if ( status && !Object.values(this.CONSTANTS.Shipment.STATUS).includes(status)) {
      alert(' shipment set_status 傳入的參數 沒有包含在 CONSTANTS.Shipment.STATUS ');
      return this;
    }
  
    this.status = status;
    return this;
  }

  set_order_amount(order_amount){
    this.order_amount = order_amount;
 
    //會影響以下兩個參數 所以設為NULL 必須重算
    this.advance_amount = null;
    this.total_amount = null;
    return this;
  }
 
  set_tax_and_discount_amount(){
    if (!this.site) {
      alert('set_tax_and_discount_amount 沒有 load_site');
    }
 
    this.tax_included = this.site.tax_included;
    this.tax_rate = this.site.tax_rate; 
    this.tax = ( this.tax_included ? Math.round(this.order_amount - (this.order_amount / (1 + this.tax_rate/100))) : Math.round(this.order_amount * (this.tax_rate / 100))) || 0;
     
    if (this.coupon_relation) { 
      this.discount_amount_was = this.discount_amount;
      if (this.coupon_relation.discount_type == this.CONSTANTS.Coupon.DISCOUNT_TYPE["固定金額"] ) {
        this.discount_amount = this.coupon_relation.discount;
      } else if( this.coupon_relation.discount_type == this.CONSTANTS.Coupon.DISCOUNT_TYPE["比率"] ) {
        if ([undefined, null, ""].includes(this.order_amount)) {
          alert('沒有設定 this.order_amount');
          return;
        }  
        this.discount_amount = this.coupon_relation.coupon.get_discount_amount( this.tax_included ? this.order_amount : ( this.order_amount + this.tax ) , this.CONSTANTS);
      } else {
        this.discount_amount = 0; 
      }

      //把 如果折扣價格被改變了 就把 total_amount 設定為 null, 必須重算
      if (this.discount_amount_was != this.discount_amount) { 
        this.total_amount = null; 
      } 
    } else {
      this.discount_amount = 0;
    }  
  
    this.advance_amount = null;
    
    return this;
  }
 
  set_advance_amount(advance_amount){
    this.advance_amount = advance_amount;
    return this;
  }
 
  set_total_amount(){
 
  //要按照以下順序
        // .set_order_amount(this.get_shipment_orders_amount())
        // .set_tax(false, 0, 0) // tax 還沒算
        // .set_discount_amount(0) //結帳優惠 還沒做
        // .set_advance_amount(0) // 儲值金額 還沒做
        // .set_total_amount();
 
    if ( [undefined, null, ""].includes(this.tax) ) {
      alert(' set_total_amount 請先 定義 this.tax ');
      return;
    }
    if ( [undefined, null, ""].includes(this.order_amount) ) {
      alert(' set_total_amount 請先 定義 this.order_amount ');
      return;
    }
    if ( [undefined, null, ""].includes(this.discount_amount) ) {
      alert(' set_total_amount 請先 定義 this.discount_amount ');
      return;
    }
    // if ( [undefined, null, ""].includes(this.advance_amount) ) {
    //   alert(' set_total_amount 請先 定義 this.advance_amount ');
    //   return;
    // }

    this.advance_amount = this.advance_amount ?? 0;
 
    this.total_amount = this.order_amount - this.discount_amount + ( this.tax_included ? 0 : (this.tax ?? 0) ); 
 
    //如果 顧客願意支付的儲值金 超過 目前應付金額 , 自動調降 顧客願意支付的儲值金, 則 儲值金 和 應付金額 剛好抵銷
    if (this.advance_amount > this.total_amount) {
      this.advance_amount = this.total_amount;
      this.total_amount = 0;
    } else {
      this.total_amount = this.total_amount - this.advance_amount;
    }
  
    return this;
  }
 
  set_site(site){
    this.site = site;
    return this;
  }
 
  set_buyer(user_id){
    this.user_id = user_id;
    return this;
  }

  // set_tax(tax_included, tax_rate) { 
  //   this.tax_included = tax_included;
  //   this.tax_rate = tax_rate; 
  //   this.tax = ( this.tax_included ? Math.round(this.order_amount - (this.order_amount / (1 + this.tax_rate/100))) : Math.round(this.order_amount * (this.tax_rate / 100))) || 0;
    
  //   //會影響以下兩個參數 所以設為NULL 必須重算
  //   this.advance_amount = null;
  //   this.total_amount = null;  
  //   return this;
  // }

  set_receiver(receiver, received_mobile) {
    this.receiver = receiver;
    this.received_mobile = received_mobile;
    return this;
  }
  
  show_order_detail_list(CONSTANTS){ 
    let stringList = [];
    for (let order of this.buy_orders) {
      stringList = [...stringList, `${ order?.product?.name }${ order?.quantity ? `*${order?.quantity}` : `` } $${ order?.quantity * order?.price }`];
      if (
        order.coupon_relation &&  
        order.coupon_relation.type_code == CONSTANTS.Coupon.TYPE_CODE["買多贈品"]
        ) { 
        let couponRelation = order.coupon_relation;
        if (order.coupon_relation.gift_product) { 
          stringList = [...stringList, `(贈品)${ couponRelation.gift_product?.name }${ couponRelation?.gift_count ? `*${couponRelation?.gift_count}` : `` } `];
        } else { 
          stringList = [...stringList, `(贈品)${ couponRelation.gift }${ couponRelation?.gift_count ? `*${couponRelation?.gift_count}` : `` } `];
        } 
      }
      if (
        order.coupon_relation && 
        order.coupon_relation.type_code == CONSTANTS.Coupon.TYPE_CODE["買多折扣"]
        ) { 
        stringList = [...stringList, `(折扣)${ order?.product?.name }${ order?.discount_amount ? ` -$${order?.discount_amount}` : `` } `];
      }
    } 
    return stringList;
  }
  
  async save_sql(options = {}){ 
    let that = this; 
    let validate = true;

    if ( !window.app.state.staff.user_id ) {
      alert('user_id 為空值, 可能沒有登入');
      validate = false;
    } 

    if ( validate && !this.orders ) {
      alert('沒有訂單');
      validate = false;
    }
 
    if ( validate && this.orders && this.orders.map((order) => order.user_id).unique().length > 1 ) {
      alert("出貨單內的訂單不可同時包含多個購買人");
      validate = false;
    }
 
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      "shipments", 
      "id,number(NullIfEmpty),site_id,device_id(NullIfEmpty),user_id,remark,shipped_at(NullIfEmpty),status,order_amount,discount_amount,tax,tax_rate,total_amount,advance_amount,receiver,received_mobile,created_at,updated_at,tax_included" 
    )[0];

    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};

    let shipmentItemsSaveSql = []; 
    let couponRelationsSql = [];
    let couponRelationGiftOrdersSql = []; 
    let advanceSaveSql = []; 
    let updateOrderStatusSql = [];
    if( options["saveRelationTable"] ) {

      if (this.orders == undefined) {
        alert('請先定義orders');
        return [];
      }
 
      //儲存 shipment_items  
      for(const order of this.orders ) {

        //每一個 order 都跟第一筆 order比較site_id 如果發生不同 則資料錯誤
        if (order["site_id"] != this.orders[0]["site_id"]) {
          alert('訂單的site_id資料不同');
          validate = false;
        }
        //每一個 order 都跟第一筆 order比較user_id 如果發生不同 則資料錯誤
        if (order["user_id"] != this.orders[0]["user_id"]) {
          alert('訂單的user_id資料不同');
          validate = false;
        } 

        let shippedQty = ( order["shipped_qty_for_shipment"] ? order["shipped_qty_for_shipment"]*1 : ( order["quantity"]*1 - (order["shipped_qty"] ?? 0)*1 ) )*1;
        let item = new ShipmentItemModel()
                   .set_shipment(null, shippedQty, order.total_price)
                   .set_order_id(order.id);
 
        await item.load_order(); 
        shipmentItemsSaveSql = [ ...shipmentItemsSaveSql, ...item.save_sql({"saveRelationTable": true}) ];
 
      } 

      updateOrderStatusSql = this.update_orders_status_to_complete_Sql(this.orders.map(o => o.id));



      //如果有滿額優惠 就save coupon_relation

      //coupon_relation_used_count
      // shipment_discount_amount
      // user_id

      if (this.coupon_relation) { 
        couponRelationsSql = this.coupon_relation 
          .save_sql({ 
            "CONSTANTS": options["CONSTANTS"],
            "saveRelationTable" : true, 
            "dynamicParams" : { 
              shipment_id: function(results){   
                return results.shipments.rows?.first()?.id 
              }          
            }, 
        }); 
 
        if (this.coupon_relation.gift_orders) {
 
          for (let giftOrder of this.coupon_relation.gift_orders) {
            // giftOrder.load_product(products);

            giftOrder.set_status(this.CONSTANTS.Order.STATUS["完成"], giftOrder.created_by);

            let giftOrderSaveResult = (await giftOrder.save_sql({  
              // caches: {
              //   products: products, //批次處理 
              //   productModels: productModels //批次處理
              // }, 
              saveRelationTable:true, 
              passStockQtyValidate: true, 
              dynamicParams: { 
                coupon_relation_id: function(results) {   
                  return results?.coupon_relations?.rows[0]?.id; 
                } 
              }
            }));

            let sql = giftOrderSaveResult["sql"]; 
            
            couponRelationGiftOrdersSql = [...couponRelationGiftOrdersSql, ...sql];
          } 
        }   
      } 
 
      let firstOrder = this.orders[0];
      if( this.advance_amount ) {
 
        advanceSaveSql = new AdvanceModel()
        .set_system_created(true)
        .set_site(firstOrder.site_id)
        .set_whose_advance(firstOrder.user_id) 
        .set_amount(this.advance_amount * -1)
        .set_constants(options["CONSTANTS"].Advance.TYPE_CODE['折抵'])
        .set_editor(this.user_id)
        .save_sql({ 
          "saveRelationTable" : true, 
          "dynamicParams" : {
            description: function(results){     
              return `出貨編號：${ that.afterSaveData["number"]  }` 
            },
            shipment_id: function(results){   
              return results.shipments.rows?.first()?.id 
            }          
          }, 
        }); 
      } 
    }
 
    if (validate) { 
      let result = [
        saveSql,  
        ...shipmentItemsSaveSql,
        ...super.dynamic_update_number(),  
        ...this.validate_column_logic_sql(),
        ...couponRelationsSql,
        ...couponRelationGiftOrdersSql,
        ...advanceSaveSql,
        ...updateOrderStatusSql,


        // { 
        //   sql: "SELECT * FROM member_levels WHERE id=0", 
        //   params: [],
        //   validate: function(currentResult, allResults){ 
        //     let success = false; 
        //     return success;
        //   },
        //   errorMessage: "就是錯" 
        // }, 
      ]; 
      return result;
    } 
    return [];
  }

  update_orders_status_to_complete_Sql(orderIds, status) {
    if (!orderIds || orderIds.length == 0) {
      return [];
    }
 
    let idsSql = [];
    let start = 2;
    let end = orderIds.length - 1 + start;
    for (let i = start; i <= end; i++) {
      idsSql.push(i);
    } 

    let idsSqlString = '$' + idsSql.join(',$');
  
    return [{
      tableName: "orders",
      params:[ this.CONSTANTS.Order.STATUS["完成"], ...orderIds ],
      sql: `
        UPDATE orders
        SET status = $1  
        WHERE shipped_qty = quantity AND id IN (${ idsSqlString })`
      }
    ];
  }
 
  destroy_shipment_item_sql(orderIds){
    if (!orderIds || orderIds.length == 0) {
      return [];
    }
 
    let idsSql = [];
    let start = 1;
    let end = orderIds.length - 1 + start;
    for (let i = start; i <= end; i++) {
      idsSql.push(i);
    } 

    let idsSqlString = '$' + idsSql.join(',$');
 
    return [{
      tableName: "shipment_items",
      params:[ ...orderIds ],
      sql: `
        DELETE FROM shipment_items 
        WHERE order_id IN (${ idsSqlString })`
    }];
  }

  against_advance_sql(againstAdvances) {
    let shipmentId = this.shipment_id;
    let sqls = [];

    for ( let againstAdvance of againstAdvances ) {
      sqls = [ ...sqls,  ...new AdvanceModel()
        .set_system_created(true)
        .set_site(this.site_id)
        .set_whose_advance(againstAdvance.user_id) 
        .set_amount(againstAdvance.amount * -1)
        .set_constants(this.CONSTANTS.Advance.TYPE_CODE['作廢'])
        .set_editor(window.app.state.staff.user_id)
        .save_sql({ 
          "saveRelationTable" : true, 
          "dynamicParams" : {
            description: function(results){     
              return `預儲金退還 原因︰出貨單作廢` 
            },
            shipment_id: function(results){   
              return shipmentId;
            }          
          }
        })
      ];
    } 
    return sqls;
  }

  update_shipment_item_sql_for_destroy() {
    if (!this.shipment_items) {
      alert(' update_shipment_item_sql_for_destroy 沒有 this.shipment_items ');
    }

    let sql = [];
    for ( let shipmentItem of this.shipment_items ) { 
      sql = [ ...sql, 
        ...shipmentItem 
        .set_order_id(0) //設成0 代表已經跟原先的訂單切斷連接了 shipmentItem本身就變成一個紀錄檔案
        .set_recored_data_of_canceled_shipment()
        .save_sql() 
      ]; 
    }
    return sql; 
  }
 
  async destroy_sql(options = {}){
    let that = this; 
    let updateOrderStatusAndShippedQtySql = [];
    let updateShipmentItemForDestroySql = [];
    let updateAdvanceBalanceSql = [];
    let againstAdvanceSql = []; 
    let destroyOrdersSql = [];
    let destroyCouponRelationSql = [];
 
    if ( !this.orders ) {
      alert('shipment destroy_sql 請先定義 this.orders');
      return [];
    }

    if ( !this.shipment_items ) {
      alert('shipment destroy_sql 請先定義 this.shipment_items');
      return [];
    }

    if (options["destroyRelationTable"]) {  
      // updateOrderStatusAndShippedQtySql = this.update_orders_status_to_complete_Sql(this.orders.map(o => o.id), this.CONSTANTS.Order.STATUS["出貨處理中"], false);
      // updateShipmentItemForDestroySql = this.destroy_shipment_item_sql(this.orders.map(o => o.id));

      this.orders = this.orders.to_orders_class();

      // let orderIds = this.orders.map(o => o.id);
      // let thisOrdersShipmentItems = (await new AdvanceBalanceModel().where("site_id",this.site_id).where("user_id",this.user_id).get_datas()).rows.to_advance_balances_class().first();


      for (let order of this.orders ) { 
        if (!order.is_on_site_checkout_order) {
          // 這裡要把 線上訂單 改回去尚未出貨的狀況 ====

      //     //
      // 1. pending => completed

      // 2. shipped_qty => 0 => 2

      // 3. ShipmentItemModel 砍掉

      // 4. 如果有使用 advance 則要再新增一個 advance 用來返還儲值金   

          // order.set_status(this.CONSTANTS.Order.STATUS["出貨處理中"], window.app.state.staff.user_id);


          await order.load_shipment_items(this.shipment_items);
          updateOrderStatusAndShippedQtySql = [ ...updateOrderStatusAndShippedQtySql, ...order.set_import({ "CONSTANTS" : this.CONSTANTS }).update_status_and_shipped_qty_sql_for_destroy_shipment(this.id) ];    

          // let currentShipmentItem = this.shipment_items.filter(i => i.order_id == order.id).first();
          // if (currentShipmentItem) {
          //   // order.load_shipment_items(this.shipment_items);
          //   // order.update_status_and_shipped_qty_sql_for_destroy_shipment();


          //   // order
          //   //   // quantity, shipped_qty_for_shipment, price, couponType, cost = null, shippedQty = null, isGift = false, CONSTANTS
          //   //   .set_quantity_and_price(order.quantity, null , order.price,  order.coupon_type, order.cost, order.shipped_qty + currentShipmentItem.shipped_qty, this.CONSTANTS)
          //   //   .set_discount_amount(this.CONSTANTS, splitOrder.coupon_relation)
          //   //   .set_total_price();
          // } else {
          //   alert('資料有誤 Order 沒有 ShipmentItem 無法正確處理');
          // }



        } else {
          let orderSql = await order.destroy_sql({
            CONSTANTS: this.CONSTANTS,
            destroyRelationTable: true
          });
          destroyOrdersSql = [ 
            ...destroyOrdersSql, 
            ...orderSql
          ];          
        } 
      }

      if(!this.advances){
        alert('ShipmentModel destroy_sql 沒有 this.advances ');
      } 
 
      //如果有用預儲金 要沖銷 
      if(this.advances.filter(a => !a.against_advance_id).length > 0) { 
        againstAdvanceSql = this.against_advance_sql(this.advances.filter(a => !a.against_advance_id)); 
      }
 
      let advanceBalanceModel = (await new AdvanceBalanceModel().where("site_id",this.site_id).where("user_id",this.user_id).get_datas()).rows.to_advance_balances_class().first();
      updateAdvanceBalanceSql = advanceBalanceModel.update_balance_sql();

      destroyCouponRelationSql = new CouponRelationModel().where("shipment_id", this.id).destroy_sql();
   


      //     //
      // 1. pending => completed

      // 2. shipped_qty => 0 => 2

      // 3. ShipmentItemModel 砍掉

      // 4. 如果有使用 advance 則要再新增一個 advance 用來返還儲值金   
 
    }

    // this.set_status(this.CONSTANTS.Shipment.STATUS["作廢"]);
 
    updateShipmentItemForDestroySql = this.update_shipment_item_sql_for_destroy(); 
 
    let sqlObjs = [{sql: "BEGIN"}, 
      {
        tableName: "shipments",
        params:[ this.id, this.CONSTANTS.Shipment.STATUS["作廢"] ],
        sql: `
          UPDATE shipments
          SET status = $2 
          WHERE id = $1`
      }, 
      ...updateOrderStatusAndShippedQtySql,
      ...destroyOrdersSql,
      ...updateShipmentItemForDestroySql, 
      ...againstAdvanceSql,
      ...updateAdvanceBalanceSql, 
      ...destroyCouponRelationSql, 
      // { 
      //   sql: "SELECT * FROM member_levels WHERE id=0", 
      //   params: [],
      //   validate: function(currentResult, allResults){ 
      //     let success = false; 
      //     return success;
      //   },
      //   errorMessage: "就是錯" 
      // },

    {sql: "END"}];
  
    return sqlObjs;
  } 



  async destroy(options = {}){
    let that = this;  
    let success = true;
    let errors = [];
  
    let sqlObjs = await this.destroy_sql(options);

    let rowCount = 0; 
    await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => {  
      rowCount = results?.datasByTableName[that.tableName]?.rowCount;  
    }).catch(async err => { 
      if( err?.message ) { 
        success = false;
        errors.push(err?.message); 
      }  
    }); 
 
    return {
      success: success,
      errors: errors,
      rowCount: rowCount 
    };
  }  
}

export class CouponProductRelationModel extends Model {
  constructor(that) { 
    super(that)

    this.tableName = "coupon_product_relations";
  }  

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  }  
   
  load_product(products){
    if( products ){
      this.product = (products?.filter(p=>(p.id == this.product_id ))?.first() ?? {});  
      this.product = [this.product].to_products_class().first();
    }
    return this; 
  }

  set_coupon_product_relation(couponId, productId, productModelId, coupon_type) {
    this.coupon_id = couponId;
    this.product_id = productId;
    this.product_model_id = productModelId;
    this.coupon_relation_id = null;
    this.quantity = null;
    this.coupon_type = coupon_type;
    return this;
  }
  
  // set_coupon_relation_product_relation(productId, productModelId, couponRelationId, quantity, type) {
  //   this.coupon_id = null;
  //   this.product_id = productId;
  //   this.product_model_id = productModelId; 
  //   this.coupon_relation_id = couponRelationId;
  //   this.quantity = quantity;    
  //   this.type = type;
  //   return this;
  // }

  save_sql(options = {}){ 
    let that = this; 
    let dynamicParams = options["dynamicParams"] ?? {};
    this.afterSaveData = {...this};

    if (!this.product_id) {
      alert(`CouponProductRelationModel save_sql product_id 或是 coupon_type 不可以是空值`);
      return;
    }

    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      this.tableName, 
      `id,coupon_id${ dynamicParams["coupon_id"] ? "(IsDynamicParam)" : "" },product_id,product_model_id(NullIfEmpty),coupon_type,created_at,updated_at`,
      {...dynamicParams}  
    )[0];

    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};

    let addStockQtySql = [];
    if (this.quantity) {
      if (this.product_id) {
        if (this.product_model_id) {
          addStockQtySql = new ProductModelClass({ id: this.product_model_id, product_id: this.product_id }).add_stock_qty_sql(this.quantity*-1, true); 
        } else {
          addStockQtySql = new ProductModel({id: this.product_id}).add_stock_qty_sql(this.quantity*-1); 
        }
      }
    }

    return [
      saveSql,
      ...addStockQtySql 
    ]
  }
 
}
 

export class CouponRelationModel extends Model {
  constructor(that) { 
    super(that)

    this.tableName = "coupon_relations";
  }  

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 

  set_coupon_relation_columns(coupon){

    if (!coupon) {
      alert(`沒有定義 CouponRelationModel set_coupon_relation_columns coupon`);
      return;
    }
    this.coupon = coupon;
    this.coupon_id = coupon.id; 
    this.discount_type = coupon.discount_type;
    this.discount = coupon.discount;
    this.limit_amount = coupon.limit_amount;
    this.name = coupon.name;
    this.code = coupon.code;
    this.type_code = coupon.type_code;
    this.limited_count = coupon.limited_count;
    this.start_time = coupon.start_time;
    this.end_time = coupon.end_time;
    this.used_count = coupon.used_count;
    this.gift = coupon.gift; 
    this.buy_product_id = coupon.buy_product_id;
    this.gift_product_id = coupon.gift_product_id;
    this.buy_count = coupon.buy_count;
    this.gift_count = coupon.gift_count; 
    this.limit_user_used_count = coupon.limit_user_used_count; 
    return this;
  }
 
  coupon_can_be_used() { 
    this.notice_messages = [];
    if ( [this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"], this.CONSTANTS.Coupon.TYPE_CODE["買多折扣"]].includes(this.type_code*1) && 
      (!this.buy_order || this.buy_order.quantity != this.buy_count) ) {
      if (this.buy_order) { 
        this.notice_messages.push(`再加${ this.buy_count - this.buy_order.quantity }件商品( ${ this.coupon.buy_product.name } )滿足優惠條件`); 
      // console.log(`再加${ this.buy_count - this.buy_order.quantity }件商品( ${ this.coupon.buy_product.name } )滿足優惠條件`);
      } 
 
      return false;
    }
 
    if ( this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"] == this.type_code ||
          this.CONSTANTS.Coupon.TYPE_CODE["滿額贈品"] == this.type_code  ) {
      if ( !this.gift_orders ) { 
        this.notice_messages.push(`可加贈 ${ this.coupon.gift_product.name } ${ this.coupon.gift_count }件`); 
      // console.log(`可加贈 ${ this.coupon.gift_product.name } ${ this.coupon.gift_count }件`);

        return false;
      } 

      let giftOrdersQuantitySum = this.gift_orders_quantity_sum();
      if (giftOrdersQuantitySum != this.gift_count) {
        this.notice_messages.push(`可加贈 ${ this.coupon.gift_product.name } ${ this.coupon.gift_count - giftOrdersQuantitySum }件`); 
      // console.log(`可加贈 ${ this.coupon.gift_product.name } ${ this.coupon.gift_count - giftOrdersQuantitySum }件`);
        return false; 
      }
    }
 
    return true;
  }

  gift_orders_quantity_sum(){
    let giftOrdersQuantitySum = 0;
    if (!this.gift_orders) { 
      return 0;
    }
    for ( let giftOrder of this.gift_orders ) {
      giftOrdersQuantitySum = giftOrdersQuantitySum + giftOrder.quantity;
    } 
    return giftOrdersQuantitySum;
  }

  clear_buy_order_and_gift_order_bind() { 
    if (this.buy_order) {
      this.buy_order.coupon_relation = null; 
      this.buy_order
        .set_quantity_and_price(this.buy_order.quantity, this.buy_order.shipped_qty_for_shipment, this.buy_order.price, null, this.buy_order.cost, this.buy_order.shipped_qty, this.CONSTANTS)
        .set_discount_amount(this.CONSTANTS)
        .set_total_price();
 
      this.buy_order = null; 
    }
    if (this.gift_orders) {
      for (let giftOrder of this.gift_orders ) {
        giftOrder.coupon_relation = null;
        giftOrder
          .set_quantity_and_price(giftOrder.quantity, giftOrder.shipped_qty_for_shipment, giftOrder.price, null, giftOrder.cost, giftOrder.shipped_qty, this.CONSTANTS)
          .set_discount_amount(this.CONSTANTS)
          .set_total_price();
      } 
      this.gift_orders = null; 
    } 
    return this;
  }


 
  batch_load_gift_orders_and_buy_order_and_related_products(CONSTANTS, couponRelations, buyAndGiftOrders, buyAndGiftOrderProducts) {  
    // let couponRelationIds = couponRelations.map(o => o.id);
    // let orders = couponRelationIds.length == 0 ? [] : (await (new OrderModel()).where(" coupon_relation_id in ? ",[couponRelationIds]).get_datas()).rows.to_orders_class();

    // let productIds = orders.map(o => o.product_id);
    // let products = productIds.length == 0 ? [] : (await (new ProductModel()).where(" id in ? ",[productIds]).get_datas()).rows.to_products_class();

    buyAndGiftOrders = buyAndGiftOrders.to_orders_class();
    buyAndGiftOrderProducts = buyAndGiftOrderProducts.to_products_class();

    for ( let order of buyAndGiftOrders ) {
      order.load_product(buyAndGiftOrderProducts); 
    }

    couponRelations = couponRelations.to_coupon_relations_class();
    for ( let couponRelation of couponRelations ) {
      couponRelation.load_buy_order(CONSTANTS, buyAndGiftOrders);
      couponRelation.set_import({ "CONSTANTS" : CONSTANTS }).load_gift_orders(buyAndGiftOrders);
    } 
  } 
 
  async load_gift_orders(orders){
    if( orders ){
      this.gift_orders = (orders?.filter(o=>(o.coupon_relation_id == this.id )) ?? []).to_orders_class(); 
      this.gift_orders = this.gift_orders.filter(o => o.coupon_type == this.CONSTANTS.CouponProductRelation.TYPE["贈品"] ); 
      return this; 
    } else { 
      let ordersWas = this.orders;
      await super.load_gift_orders(null, "coupon_relation_id",this.CONSTANTS);  
      this.orders = ordersWas;
      return this; 
    } 
  }
 
  async load_buy_order(CONSTANTS, orders){
    if( orders ){
      let thisCouponRelationOrders = (orders?.filter(o=>(o.coupon_relation_id == this.id )) ?? [])?.to_orders_class(); 
      this.buy_order = thisCouponRelationOrders.filter(o => o.coupon_type == CONSTANTS.CouponProductRelation.TYPE["優惠商品"] ).first(); 
      return this; 
    } else { 
      let ordersWas = this.orders;
      await super.load_buy_order(null, "coupon_relation_id",CONSTANTS);  
      this.orders = ordersWas;
      return this; 
    } 
  }

  set_buy_and_gift_count(coupon){
    this.buy_count = coupon.buy_count;
    this.gift_count = coupon.gift_count;   
    return this; 
  }
  
  set_shipment_discount_amount(coupon, needToPayAmount, CONSTANTS) {
    switch (coupon.type_code) {
      case CONSTANTS.Coupon.TYPE_CODE["買多折扣"]:
        coupon.get_discount_amount(needToPayAmount, CONSTANTS);
      　break;
      case CONSTANTS.Coupon.TYPE_CODE["買多贈品"]:
        this.shipment_discount_amount = coupon.cost_of_gift(CONSTANTS);
      　break; 
      case CONSTANTS.Coupon.TYPE_CODE["滿額紅利(回饋儲值金)"]: 
        coupon.get_discount_amount(needToPayAmount, CONSTANTS);
      　break;
      case CONSTANTS.Coupon.TYPE_CODE["滿額贈品"]: 
        this.shipment_discount_amount = coupon.cost_of_gift(CONSTANTS); 
      　break;
      case CONSTANTS.Coupon.TYPE_CODE["滿額折扣"]: 
        coupon.get_discount_amount(needToPayAmount, CONSTANTS);
      　break; 
      default: 
    }    
 
    return this;
  }
  set_whose_buy(userId){
    this.user_id = userId;
    return this;
  }
  set_buy_order(orderId){
    this.buy_order_id = orderId;
    return this;  
  }
  // set_gift_order(orderId){
  //   this.gift_order_id = orderId;
  //   return this;
  // }

  validate_column_logic_sql(){
    if (!this.coupon_id) {
      alert('CouponRelationModel validate_column_logic_sql this.coupon_id 沒設定');
    }

    let sqlObj = new CouponModel().active().where("id", this.coupon_id).get_sql(); 

    return [{ 
      sql: sqlObj.sql, 
      params: sqlObj.params, 
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length != 0); 
        return success;
      },
      errorMessage: "此優惠已停用"
    }]; 
  }
   
  async load_buy_order_and_product_and_product_models(orders, products, productModels) {  
    if (orders) { 
      orders = orders.to_orders_class();
    } else {
      orders = (await new OrderModel().where("coupon_relation_id", this.id).get_datas()).rows.to_orders_class();
    }
    let productIds = orders.map(o => o.product_id).filter(i => i).unique(); 
    
    if (products) { 
      products = products.to_products_class();
    } else {
      products = productIds.length == 0 ? [] : (await new ProductModel().where("id in ?", [productIds]).get_datas()).rows.to_products_class();
    }

    if (productModels) { 
      productModels = productModels.to_productModels_class();
    } else {
      productModels = productIds.length == 0 ? [] : (await new ProductModelClass().where("product_id in ?", [productIds]).get_datas()).rows.to_product_models_class();
    } 

    for (let product of products) {
      product.load_product_models(productModels); 
    }

    for (let order of orders) {
      order.load_product(products); 
    }
 
    return row; 
  }


  save_sql(options){
    let that = this;

    if(!options["CONSTANTS"]){
      alert(`沒有傳入CONSTANTS參數`);
      return false;
    }

    let shipment_id = this.shipment_id ? this.shipment_id : "{@shipment_id}";
    this.afterSaveData = {...this};
 
    let saveSql = [{...this, shipment_id: shipment_id}].toSaveSqlObjs(
      "coupon_relations",  
      `id,shipment_id(IsDynamicParam),coupon_id,shipment_discount_amount,discount_type,discount,limit_amount,end_time,used_count,type_code,limited_count,start_time,name,code,gift,limit_user_used_count,coupon_relation_used_count,user_id,buy_count,gift_count`,
      {
        shipment_id: function(results){   
          return results.shipments?.rows?.first()?.id 
        } 
      }  
    )[0];
 
    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
      if(options?.afterSaveCallBack){
        options?.afterSaveCallBack.apply(this,[result, sqlString, sqlParams, datasByTableName]);
      }      
    }};
 
    let increaseCouponUsedCountSql = [];
    let couponProductRelationModelSqls = [];
    let validateStockQtySql = [];
    if (options["saveRelationTable"]) {
      increaseCouponUsedCountSql = this.increase_coupon_used_count_sql();
    }
  
    return [ 
      saveSql,
      ...increaseCouponUsedCountSql, 
      ...this.validate_column_logic_sql(),
      ...validateStockQtySql 
    ]
  }
 
  increase_coupon_used_count_sql() {

    if (!this.coupon_id) {
      alert("沒有設定coupon_id");
    }

    return [{
      tableName: "coupons",
      params:[this.coupon_id ],
      sql: `
        UPDATE coupons
        SET used_count = used_count + 1
        WHERE id = $1`
    }];
  } 
}

export class ShipmentItemModel extends Model {

  constructor(that) { 
    super(that)
    this.tableName = "shipment_items";
  }  

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 

  async batch_load_order_and_related_tables(shipmentItems){
    let orderIds = shipmentItems.map(o => o.order_id);
    let allOrders = await orderIds.ids_to_class("Orders");

    let couponRelationIds = allOrders.map(o => o.coupon_relation_id);
    let allCouponRelations = await couponRelationIds.ids_to_class("CouponRelations");
 
    let couponIds = allCouponRelations.map(o => o.coupon_id);
    let allCoupons = await couponIds.ids_to_class("Coupons");
  
    for ( let couponRelation of allCouponRelations ) {
      couponRelation.load_coupon(allCoupons);
    }

    for ( let order of allOrders ) {
      order.load_coupon_relation(allCouponRelations);
    }    

    for ( let shipmentItem of shipmentItems ) {
      shipmentItem.load_order(allOrders);
    } 

    return shipmentItems;
  }
 
  set_shipment(shipmentId, shippedQty, shipmentTotalPrice){
    this.shipment_id = shipmentId;
    this.shipped_qty = shippedQty;
    this.shipment_total_price = shipmentTotalPrice;
    return this;
  } 

  set_order_id(orderId){
    this.order_id = orderId;
    return this;
  }
 
  set_recored_data_of_canceled_shipment(){

    if (!this.order) {
      alert(` set_recored_data_of_canceled_shipment 沒有讀取 this.order `);
      return this;
    } 
    if (!this.order.product) {
      alert(` set_recored_data_of_canceled_shipment 沒有讀取 this.order.product `);
      return this;
    }

    this.price = this.order.price; 
    this.discount_amount = this.order.discount_amount;  
    this.total_price = this.order.total_price;    
    this.buyer_name = this.order.buyer_name;  
    this.buyer_mobile = this.order.buyer_mobile;  
    this.product_name = this.order.product.name;  
    this.coupon_type = this.order.coupon_type;  
    this.coupon_relation_id = this.order.coupon_relation_id;   
    this.coupon_name = this.order.coupon_relation ? this.order.coupon_relation.coupon.name : "";  

    return this;
  }

  save_sql(options = {}){
    let that = this;
    if (this["order_id"] == undefined) {
      alert('請先定義order_id');
      return [];
    }
    if (this.order.constructor.name != 'Order') {
      this.order = new OrderModel(this.order);
    }

    let shipment_id = this.shipment_id ? this.shipment_id : "{@shipment_id}";
    let isDynamicShipmentId = this.shipment_id ? false : true;
    this.afterSaveData = {...this};

    let saveSql = [{...this, shipment_id: shipment_id}].toSaveSqlObjs(
      "shipment_items", 
      `id,shipment_id(IsDynamicParam),order_id,shipped_qty,shipment_total_price,discount_amount,total_price,buyer_name,buyer_mobile,product_name,coupon_type,coupon_relation_id,coupon_name,updated_at,created_at`,
      {  
        shipment_id: function(results){   
          return isDynamicShipmentId ? results.shipments.rows?.first()?.id : shipment_id;
        }
      }   
    )[0];
  
    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};

    let triggerAddShippedQtySql = [];
    if (options["saveRelationTable"]) {
      triggerAddShippedQtySql = new OrderModel({ "id": that["order_id"] }).add_shipped_qty_sql(this["shipped_qty"]);
    }
 
    return [
      saveSql, 
      ...triggerAddShippedQtySql, 
      ...this.order.validate_column_logic_sql()
    ]
  }
 
  random_data(){ 
    let data = {  
      shipment_id: Math.floor(Math.random() * 1000000),
      order_id: Math.floor(Math.random() * 1000000),
      shipped_qty: Math.random() * 100,
      created_at: new Date(),
      updated_at: new Date()       
    }
 
    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }  
}
  
export class AdvanceModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "advances";
  }
 
  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 

  random_data(){ 
    let data = { 
      site_id : Math.floor(Math.random() * 1000000),
      user_id : Math.floor(Math.random() * 1000000),
      amount : Math.random() * 100,
      description : "Some random description",
      system_created : Math.random() > 0.5,
      shipment_id : Math.floor(Math.random() * 1000000),
      deleted : Math.random() > 0.5,
      against_advance_id : Math.floor(Math.random() * 1000000),
      type_code : Math.floor(Math.random() * 1000000),
      created_by : Math.floor(Math.random() * 1000000),
      updated_by : Math.floor(Math.random() * 1000000),
      created_at : new Date(),
      updated_at : new Date()       
    }
 
    for (const key in data) {
      // this[`${key}_was`] = data[key];
      this[`${key}`] = data[key];
    }  
  }

  can_destroy(CONSTANTS) {
    if(!CONSTANTS){
      alert(`沒有傳入CONSTANTS參數`);
      return false;
    } 
    return !this.against_advance_id && ( !this.system_created || this.type_code == CONSTANTS.Advance.TYPE_CODE["延遲付款"] )
  }

  // update_balance_sql() { 
  //   if (this["destroyed?"] == undefined) {
  //     alert('請先定義destroyed?');
  //     return [];
  //   }

  //   return [{ 
  //     params:[this.site_id, this.user_id],
  //     sql: `
  //       INSERT INTO advance_balances (site_id, user_id, balance, id)
  //       SELECT  $1, $2, 0 ,(SELECT COALESCE((SELECT MIN(id) FROM advance_balances WHERE id < 0), 0)-1)
  //       WHERE
  //       NOT EXISTS (  SELECT 1 FROM advance_balances WHERE site_id = $1 AND user_id = $2 );`
  //   },
  //   {
  //     tableName: "advance_balances",
  //     params:[this.user_id, this.site_id, this["destroyed?"] ? -1*this.amount : this.amount ],
  //     sql: `
  //       UPDATE advance_balances
  //       SET balance = balance + $3
  //       WHERE site_id = $2 AND user_id = $1`
  //   }]; 
  // }

  validate_column_logic_sql(){
    return [];
  }
 
  set_site(siteOrId) {
    if (siteOrId.id) {
      this.site_id = siteOrId.id;
    } else {
      this.site_id = siteOrId; 
    }
    return this;
  }

  set_system_created(systemCreated){
    this.system_created = systemCreated;
    return this;
  }

  set_whose_advance(userOrId)  {
    if (userOrId.id) {
      this.user_id = userOrId.id;
    } else {
      this.user_id = userOrId; 
    }
    return this;
  }
  
  set_amount(amount) {
    this.amount = amount;
    return this;
  }
  
  set_constants(typeCode) {
    this.type_code = typeCode;
    return this; 
  }
  
  set_editor(editUserId) {
    this.created_by = this.created_by ? this.created_by : editUserId;
    this.updated_by = editUserId; 
    return this;   
  }
   
  save_sql(options = {}){ 
    let that = this; 
 
    let dynamicParams = options["dynamicParams"] ?? {};
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      "advances", 
      `updated_at,site_id,user_id,amount,id,system_created,shipment_id${ dynamicParams["shipment_id"] ? "(IsDynamicParam)" : "" },deleted(FalseIfBlank),against_advance_id,type_code,created_by,updated_by,created_at,description${ dynamicParams["description"] ? "(IsDynamicParam)" : "" }`,
      {...dynamicParams}  
    )[0];

    let updateAdvanceBalanceSql = [];
    if( options["saveRelationTable"] ) { 
      updateAdvanceBalanceSql = [...new AdvanceBalanceModel({"site_id": that.site_id ,"user_id": that.user_id}).add_balance_sql(that["destroyed?"] ? -1*that.amount : that.amount)];
    } 

    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};
 
    return [
      saveSql,   
      ...updateAdvanceBalanceSql,
      ...this.validate_column_logic_sql()
    ]
  }
} 
 
//product_model 的 class  
export class ProductModelClass extends Model {
  constructor(that) { 
    super(that);
    this.tableName = "product_models";
  }
 
  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 

  async load_coupon_product_relations(coupon_product_relations) { 
    if( coupon_product_relations ){
      return this.coupon_product_relations = coupon_product_relations.filter(p=>(p.product_model_id == this.id && p.product_model_id ));  
    } else { 
      return await super.load_coupon_product_relations(coupon_product_relations, "product_model_id");
    } 
  } 

  async load_product(products){
    if( products ){
      return this.product = (products?.filter(p=>(p.id == this.product_id ))?.first() ?? {});  
    } else { 
      return await super.load_product();
    } 
  }
    
  async load_product_prices(product_prices) { 
    if( product_prices ){
      return this.product_prices = product_prices.filter(p=>(p.product_model_id == this.id && p.product_model_id ));  
    } else { 
      return await super.load_product_prices(product_prices, "product_model_id");
    } 
  } 

  add_stock_qty_sql(addQty, triggerProductAddStockQtySql = false) {
    if ( !this.id ) {
      alert('add_stock_qty_sql 請先定義id');
      return [];
    }

    if ( !addQty && addQty != 0 ) {
      alert('請先定義addQty');
      return [];
    }

    let productAddStockQtySql = [];
    if (triggerProductAddStockQtySql && this.product_id) {
      productAddStockQtySql = (new ProductModel({id: this.product_id })).add_stock_qty_sql(addQty);
    }
 
    return [{
      sql: `
        UPDATE ${ this.tableName } 
        SET stock_qty = stock_qty + $2
        WHERE id=$1 AND stock_qty IS NOT NULL ;`, 
      params: [ this.id, addQty ]
    },
    ...productAddStockQtySql
    ];
  }

  validate_ean_after_save_sql(options = {}){ 
    if ( !this.ean ) { 
      return [];
    }
 
    let sqlObj = { 
      sql: ` 
        (
          SELECT id,ean
          FROM products
          WHERE ean = $1
        )
        UNION
        (
          SELECT id,ean
          FROM  product_models 
          WHERE ean = $2
        ) 
      `, 
      params: [ this.ean, this.ean ],
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length <= 1); 
        return success;
      },
      errorMessage: "已有相同國際條碼"
    }
 
    return [sqlObj];
  }

  validate_column_logic_sql(options){
    return [
      ...this.validate_ean_after_save_sql(options)
    ];
  }  
 
  to_json(whitelist){
    let data = whitelist ? whitelist : ["id", "product_id", "name", "ean", "price", "cost", "stock_qty", "site_id", "created_at", "updated_at"] 
    return super.to_json(data);
  }

  get_current_price(memberLevelId){ 
 
    if ( !this.product_prices ) {
      alert('請先定義 ProductModel 的 this.product_prices');
      return 0;
    }

    // if(!this.product){
    //   await this.load_product();
    // }
    if(!this?.product?.product_prices){
      alert('ProductModelClass get_current_price 請先定義 this.product.product_prices');
      return 0;
    } 
 
    let defaultMemberLevelPrice = this.product.product_prices.filter(r=>(!r.product_model_id && r.member_level_id == memberLevelId )).first();
    let price = (defaultMemberLevelPrice && defaultMemberLevelPrice.price) ? defaultMemberLevelPrice.price : this.product.price; //預設是商品的價格
    let reason = ""; 
    let isProductPrice = true;
    let thisProductPrices = this.product_prices ? this.product_prices : [];

    //接下來判斷是否套用會員等級價格
    if(isProductPrice && !memberLevelId){
      reason = "該會員沒有設定等級";
      isProductPrice = false;
    }

    if(isProductPrice && thisProductPrices.length == 0){
      reason = "沒有設定會員任何等級價格";
      isProductPrice = false;
    }   
 
    if(isProductPrice){
      let currentMemberLevelPrice = thisProductPrices.filter(price => price.member_level_id == memberLevelId ).first();
      if(currentMemberLevelPrice && currentMemberLevelPrice.price){
        price = currentMemberLevelPrice.price
      } else {
        reason = "沒有設定相對應的會員等級價格";
        isProductPrice = false;        
      } 
    }
 
    return price;
  }  
} 


 
//product 的 class 
export class ProductModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "products";
  }
   
  get_current_price(memberLevelId, productModelId){
    let defaultPrice = this.price;

    if(!this?.product_prices){
      alert('ProductModel get_current_price 請先定義 this.product_prices');
      return [];
    } 

    if (memberLevelId && productModelId) { //是會員 又選形式
      let thisPrice = this.product_prices.filter(p => p.member_level_id == memberLevelId && p.product_model_id == productModelId)?.first()?.price;
      if (thisPrice) {
        return thisPrice*1;
      } else {
        return this.get_current_price(memberLevelId, '');
      }
    } else if (memberLevelId && !productModelId) { //是會員 不選形式
      let thisPrice = this.product_prices.filter(p => p.member_level_id == memberLevelId && !p.product_model_id)?.first()?.price;
      if (thisPrice) {
        return thisPrice*1;
      } 
    } else if (!memberLevelId && productModelId) { //不是會員 選形式
      let thisPrice = this.product_prices.filter(p => !p.member_level_id && p.product_model_id == productModelId)?.first()?.price;
      if (thisPrice) {
        return thisPrice*1;
      } 
    } else if(!memberLevelId && !productModelId) { //不是會員 不選形式
      //do nothing
    }     
    return defaultPrice*1;
  }

  async get_autocomplete(params={}){   
    return await super.ajax({
      ...params,
      method:"GET",  
      url:'/' + params.domain +`/backend/products/autocomplete.json?limit=10&searchOtherData=${ params.searchOtherData }&include_off_shelf_product=&no_order=1&term=` + (params.value ?? '') + '&user_id=' + (params.currentUserId ?? '') + '&product_id=' + (params.productId ? params.productId : "")
    }); 
  } 

  async get_products(params={}){  
    return await super.ajax({
      ...params,
      method:"GET",    
      url: `/${ params.domain }/backend/products/get_products.json?include_relatied_data=${ params["include_relatied_data"] }&ids=${ params["ids"] ? encodeURIComponent(JSON.stringify(params["ids"])) : ""}` 
    }); 
  } 

  async create_batch_order_by_ajax(body) {
    return await this.ajax({ 
      method: "POST",  
      body:body, 
      url:`/${ body.current_site.domain }/backend/products/${ body.product.id }/create_batch_order.json`
    });
  }

  // async register_by_ajax(params) { 
  //   return await this.ajax({ 
  //     method: "POST",  
  //     body:params, 
  //     url:`/sites`
  //   }); 
  // }

  validate_product_and_product_model_stock_qty_sql(){ 
    if ( !this.id ) { 
      return [];
    }
 
    let sqlObj = { 
      sql: ` 
        (
          SELECT id AS product_id, null AS product_model_id, stock_qty
          FROM products
          WHERE id = $1 AND stock_qty < 0
        )
        UNION
        (
          SELECT product_id AS product_id, id AS product_model_id, stock_qty
          FROM  product_models 
          WHERE product_id = $2 AND stock_qty < 0
        ) 
      `, 
      params: [ this.id, this.id ],
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length == 0); 
        return success;
      },
      errorMessage: "商品庫存不足"
    } 
    return [sqlObj];
  }
 
  async load_product_prices(product_prices) { 
    if( product_prices ){
      return this.product_prices = product_prices.filter(p=>(p.product_id == this.id && !p.product_model_id ));  
    } else { 
      return await super.load_product_prices(product_prices, "product_id");
    } 
  }  

  load_product_models(product_models) {  
    if( product_models ){
      this.product_models = product_models.filter(p=>( p.product_id == this.id ));  
      return this;
    }  
  } 

  async load_product_tags(productTags = null, productTagRelations = null) {  
    if( productTags && productTagRelations ) {
      this.product_tags = productTagRelations.filter(p=>( p.product_id == this.id ));  
      for (let product_tag of this.product_tags) {
        product_tag.name = productTags?.filter(t => t.id == product_tag.product_tag_id)?.first()?.name;
      }
      return this;
    }    
  } 

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 

  not_set_product_model_prices() { 
    if (!this.product_models) {
      alert('not_set_product_model_prices 沒定義 product_models');
      return;
    }
 
    for ( let productModel of this.product_models ) {  
      if (!productModel.product_prices) {
        alert('not_set_product_model_prices 沒定義 product_models 的 product_prices'); 
        return;
      } 
    }

    let notSetProductModelPrices = true;
    for ( let productModel of this.product_models ) {  
      for ( let productPrice of productModel.product_prices) {
        if (productPrice.price || productPrice.price === 0) {
          notSetProductModelPrices = false;
        }
      }
    }
    return notSetProductModelPrices; 
  }
    
  validate_ean_after_save_sql(options = {}){ 
    if ( !this.ean ) { 
      return [];
    }
 
    let sqlObj = { 
      sql: ` 
        (
          SELECT id,ean
          FROM products
          WHERE ean = $1
        )
        UNION
        (
          SELECT id,ean
          FROM  product_models 
          WHERE ean = $2
        ) 
      `, 
      params: [ this.ean, this.ean ],
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length <= 1); 
        return success;
      },
      errorMessage: "已有相同國際條碼"
    }
 
    return [sqlObj];
  }

  validate_column_logic_sql(options){
    return [
      ...this.validate_ean_after_save_sql(options)
    ];
  } 
   
  add_stock_qty_sql(addQty) {
    if ( !this.id ) {
      alert(' ProductModel add_stock_qty_sql 請先定義id');
      return [];
    }

    if ( !addQty ) {
      alert('請先定義addQty');
      return [];
    }
 
    return [{
      sql: `
        UPDATE ${ this.tableName } 
        SET stock_qty = stock_qty + $2
        WHERE id=$1 AND stock_qty IS NOT NULL ;`, 
      params: [ this.id, addQty ]
    }];
  }

  // get_current_price(memberLevelId){  
  //   if ( !this.id ) {
  //     alert('Product 請先定義id');
  //     return [];
  //   }
  //   if ( !this.price ) {
  //     alert('Product 請先定義price');
  //     return [];
  //   }

  //   if(!this?.product_prices){
  //     alert('ProductModel get_current_price 請先定義 this.product_prices');
  //     return [];
  //   } 
 
  //   let defaultMemberLevelPrice = this.product_prices.filter(r=>(!r.product_model_id && r.member_level_id == memberLevelId )).first();
  //   let price = (defaultMemberLevelPrice && defaultMemberLevelPrice.price) ? defaultMemberLevelPrice.price : this.price;  
  //   return price;
  // }   
 
  increase_orders_count_sql() { 
    if ( !this.id ) {
      alert('Product 請先定義id');
      return [];
    }
 
    return [{
      sql: `
        UPDATE ${ this.tableName } 
        SET orders_count = COALESCE(orders_count,0) + 1
        WHERE id=$1;`, 
      params: [ this.id ]
    }];
  } 

  decrease_orders_count_sql() { 
    if ( !this.id ) {
      alert('Product 請先定義id');
      return [];
    }
 
    return [{
      sql: `
        UPDATE ${ this.tableName } 
        SET orders_count = COALESCE(orders_count,0) - 1
        WHERE id=$1;`, 
      params: [ this.id ]
    }];
  } 
  
  async check_changes_stock_and_package_qty(params){
    let { order, caches, newQuantity, newProductModelId, newProductId, oldProductId, orderId, passStockQtyValidate = false } = params; 
    let result = {};
 
    if (oldProductId == newProductId) {
      result = this.check_changes_stock_and_package_qty_in_the_same_product({
        order : order, 
        caches : caches,
        newQuantity: newQuantity, 
        newProductModelId: newProductModelId,
        productId: newProductId,
        orderId: orderId,
        passStockQtyValidate: passStockQtyValidate 
      });
    } else { 
      //如果商品變更 就拆開兩個看待 舊得還回去 新的加進來
      if (oldProductId) { 
        result = await this.check_changes_stock_and_package_qty_in_the_same_product({
          order : order,  
          caches : caches,          
          newQuantity: 0, 
          newProductModelId: null,
          productId: oldProductId,
          orderId: orderId,
          passStockQtyValidate: passStockQtyValidate 
        });
      } 
      if (newProductId) { 
        result = await this.check_changes_stock_and_package_qty_in_the_same_product({
          order : order,  
          caches : caches, 
          newQuantity: newQuantity, 
          newProductModelId: newProductModelId,
          productId: newProductId,
          orderId: null,
          success: result["success"], 
          errors: result["errors"],
          messages: result["messages"],
          stockAndPackageQtyMessages: result["stockAndPackageQtyMessages"],
          updateStockAndPackageQtySqlObjs: result["updateStockAndPackageQtySqlObjs"],
          passStockQtyValidate: passStockQtyValidate 
        });
      }        
    } 
    return result;
  }

  async check_changes_stock_and_package_qty_in_the_same_product(params)
  {  
    let { caches, newQuantity, newProductModelId, productId, orderId = null, success = true, errors = [], messages = [], stockAndPackageQtyMessages = {}, updateStockAndPackageQtySqlObjs = [], passStockQtyValidate = false } = params; 
    let calculateProductStockResult = {};

    if ( success && !productId) {
      success = false;
      errors.push("尚未選取商品");
    }

    let sqlObjs = null;
    if ( success ) { 
      sqlObjs = [{sql: "BEGIN"},
      { 
        tableName: "orders",
        sql: "SELECT * FROM orders WHERE id=$1",
        params: [orderId] 
      },{ 
        tableName: "products",
        sql: "SELECT * FROM products WHERE id=$1 OR id={@product_id}",
        params: [productId],
        dynamicParams: {
          product_id: function(results){  
            return results.orders.rows?.first()?.product_id ?? productId;  
          }
        }        
      },{ 
        tableName: "product_models",
        sql: "SELECT * FROM product_models WHERE product_id=$1 OR id={@product_id}",
        params: [productId],
        dynamicParams: {
          product_id: function(results){  
            return results.orders.rows?.first()?.product_id ?? productId;  
          }
        } 
      },
      {sql: "END"}]; 
    }

    if (success) { 

      if ( caches && caches.products && caches.productModels) {

        //傳入cache 資料 的情況下 預設 是要新增 所以  thisOrder, quantityWas, oldProductModelId 給空直
        //為什麼要寫這麼怪的邏輯 一切都是為了速度 Orz
        let thisProduct = caches.products.filter(p => p.id == productId)?.first();
        let thisOrder = null; //order;
        let thisProductModels = caches.productModels.filter(p => p.product_id == productId);
        let productModelsGroupById = thisProductModels.groupBy("id"); 
        let quantityWas = 0; //thisOrder?.quantity ?? 0;
        let oldProductModelId = null; //thisOrder?.product_model_id;
        calculateProductStockResult = calculateProductStock(thisProduct, thisOrder, thisProductModels, productModelsGroupById, quantityWas, oldProductModelId, success, errors, stockAndPackageQtyMessages, updateStockAndPackageQtySqlObjs);

      } else { 
        await window.db.queryWithPool(sqlObjs, "ROLLBACK").then((results) => {
   
          let thisProduct = results?.datasByTableName?.products?.rows[0];
          let thisOrder = results?.datasByTableName?.orders?.rows[0];
          let thisProductModels = results?.datasByTableName?.product_models?.rows ?? [];
          let productModelsGroupById = thisProductModels.groupBy("id"); 
          let quantityWas = thisOrder?.quantity ?? 0;
          let oldProductModelId = thisOrder?.product_model_id;
          calculateProductStockResult = calculateProductStock(thisProduct, thisOrder, thisProductModels, productModelsGroupById, quantityWas, oldProductModelId, success, errors, stockAndPackageQtyMessages, updateStockAndPackageQtySqlObjs);
   
        }).catch(async err => { 
          if( err?.message ) {  
            success = false;
            errors.push(err.message); 
          } 
          throw err;
        });          
      } 
    }
 
    function calculateProductStock(product, order, productModels, productModelsGroupById, quantityWas, oldProductModelId, success, errors, stockAndPackageQtyMessages, updateStockAndPackageQtySqlObjs){

      if ( success && !product ) {
        success = false;
        errors.push("無此商品");
      }

      if ( success && orderId != null && !order) {
        success = false;
        errors.push("無此訂單");   
      } 

      let subQuantity = newQuantity - quantityWas;

      if(success) {
        // 扣商品可訂購量
        if (product.stock_qty) { //有設定可訂購量才驗證 
          let productStockQty = product.stock_qty
          if (productStockQty >= subQuantity) {
            if (subQuantity != 0) {
              messages.push(`商品可訂購量${(subQuantity > 0 ? "" : "+")}${subQuantity*-1}`);
              stockAndPackageQtyMessages["product"] = {
                "id" : product.id,
                "from_stock_qty" : productStockQty,
                "to_stock_qty" : productStockQty - subQuantity,
                "add_quantity" : -1 * subQuantity
              }   

              updateStockAndPackageQtySqlObjs = [ 
                ...updateStockAndPackageQtySqlObjs,
                ...new ProductModel({id: product.id}).add_stock_qty_sql(-1 * subQuantity)
              ]; 

              if (!passStockQtyValidate) {
                updateStockAndPackageQtySqlObjs = [...updateStockAndPackageQtySqlObjs, 
                {
                  sql: `
                    SELECT * 
                    FROM products 
                    WHERE id=$1 AND stock_qty = $2
                  `,  
                  params: [ product.id, productStockQty - subQuantity],
                  validate: function(currentResult, allResults){ 
                    let success = (currentResult.rows.length != 0); 
                    return success;
                  },
                  errorMessage: "商品庫存調整發生錯誤" 
                }]
              } 
            } 
          } else {
            success = false; 
            errors.push(`商品(${ product.name })可訂購量不足,目前庫存${ productStockQty }`);
          }
        }
      }

      let productModelChanges = {};
      if (success && ( oldProductModelId || newProductModelId ) ) {
        // 先返還舊的庫存
        if (oldProductModelId) {
          productModelChanges[oldProductModelId] = oldProductModelId ? {
            "from" : quantityWas,
            "to" : 0 //預設給0
          } : null;            
        } 

        // 再扣掉新買的庫存
        if (newProductModelId) {
          productModelChanges[newProductModelId] = newProductModelId ? {
            "from" : productModelChanges[newProductModelId]?.from ? productModelChanges[newProductModelId]?.from : 0,
            "to" : newQuantity //預設給0
          } : null;            
        } 

        //判斷訂購量是否足夠
        for (var key in productModelChanges) {
          let productModelId = key;
          let addQuantity = productModelChanges[key]["from"] - productModelChanges[key]["to"];
          // ps. addQuantity 有可能是正的也有可能是負的

          let currentProductModel = productModelsGroupById[productModelId]?.first();
          if ( success && !currentProductModel ) {
            success = false; 
            errors.push(`商品形式(${ productModelId })不存在`);
          } 

          if (currentProductModel["stock_qty"] == null ||
            currentProductModel["stock_qty"] == undefined ) {
            //沒設定數量就沒必要繼續處理數量加減了
            break;
          }

          if ( success && (currentProductModel["stock_qty"] + addQuantity) < 0 ) {
            success = false; 
            errors.push(`${currentProductModel["name"]}可訂購量不足,目前庫存${ currentProductModel["stock_qty"] }`);
          }

          if (success) { 
            messages.push(`商品形式(${currentProductModel["name"]}) 可訂購量${ addQuantity > 0 ? "+" : "" }${addQuantity}`);
            updateStockAndPackageQtySqlObjs = [ 
              ...updateStockAndPackageQtySqlObjs, 
              ...new ProductModelClass({id: currentProductModel["id"]}).add_stock_qty_sql(addQuantity)
            ];  

            if (!passStockQtyValidate) {
              updateStockAndPackageQtySqlObjs = [...updateStockAndPackageQtySqlObjs, 
              {
                sql: `
                  SELECT * 
                  FROM product_models 
                  WHERE id=$1 AND stock_qty = $2
                `,  
                params: [ currentProductModel.id, currentProductModel["stock_qty"] + addQuantity],
                validate: function(currentResult, allResults){ 
                  let success = (currentResult.rows.length != 0); 
                  return success;
                },
                errorMessage: "商品形式庫存調整發生錯誤" 
              }]
            }  
          } 
        } 
      }
      return {
        success : success, 
        errors : errors, 
        stockAndPackageQtyMessages : stockAndPackageQtyMessages, 
        updateStockAndPackageQtySqlObjs : updateStockAndPackageQtySqlObjs
      }
    } 

    return {
      success: calculateProductStockResult["success"], 
      errors: calculateProductStockResult["errors"], 
      messages: calculateProductStockResult["messages"],
      stockAndPackageQtyMessages: calculateProductStockResult["stockAndPackageQtyMessages"],
      updateStockAndPackageQtySqlObjs: calculateProductStockResult["updateStockAndPackageQtySqlObjs"]  
    } 
  }  
} 
 
export class OrderModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "orders";
    this.saveTableName = "orders";

    this.price = [undefined, null, ""].includes(this.price) ? this.price : this.price*1;
    this.quantity = [undefined, null, ""].includes(this.quantity) ? this.quantity : this.quantity*1;
    this.cost = [undefined, null, ""].includes(this.cost) ? this.cost : this.cost*1;
    this.shipped_qty = [undefined, null, ""].includes(this.shipped_qty) ? this.shipped_qty : this.shipped_qty*1;
    this.total_price = [undefined, null, ""].includes(this.total_price) ? this.total_price : this.total_price*1;
    // this.shipment_items = [];
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 10000),
      site_id : Math.floor(Math.random() * 100) + 1,
      device_id : Math.floor(Math.random() * 100) + 1,
      user_id : Math.floor(Math.random() * 100) + 1,
      product_id : Math.floor(Math.random() * 100) + 1,
      product_model_id : Math.floor(Math.random() * 100) + 1,
      product_source_message_id : Math.floor(Math.random() * 100) + 1,
      member_level_id : Math.floor(Math.random() * 100) + 1,
      sale_type : Math.floor(Math.random() * 100) + 1,
      source : Math.floor(Math.random() * 100) + 1,
      number : Math.random().toString(36).substring(2, 8),
      status : Math.random() > 0.5 ? 'pending' : 'completed',
      change_status_by : Math.floor(Math.random() * 100) + 1,
      change_status_at : new Date(),
      canceled_reason : Math.random() > 0.5 ? 'out of stock' : '',
      remark : Math.random() > 0.5 ? 'some remark' : '',
      seller_message : Math.random() > 0.5 ? 'some seller message' : '',
      buyer_message : Math.random() > 0.5 ? 'some buyer message' : '',
      price : Math.random() * 1000,
      cost : Math.random() * 1000,
      quantity : Math.floor(Math.random() * 10) + 1, 
      shipped_qty : Math.floor(Math.random() * 10) + 1,
      total_price : price * quantity, 
      model : "Model " + Math.floor(Math.random() * 100),
      source_message : "Source Message " + Math.floor(Math.random() * 100),
      created_by : Math.floor(Math.random() * 100),
      updated_by : Math.floor(Math.random() * 100),
      created_at : new Date(),
      updated_at : new Date(),
      buyer_name : "Buyer " + Math.floor(Math.random() * 100),
      buyer_mobile : "111-111-1111" 
    }
 
    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }  

  async update_status(params){ 
    let body = params;  
    return await this.ajax({
      ...params,
      method: "PUT",  
      body:body, 
      url:`/${ params["domain"] }/backend/orders/${ this.id }/update_status`
    });  
  }
   
  async load_shipment_items(shipmentItems){
    if( shipmentItems ) {
      this.shipment_items = shipmentItems.filter(i=>( i.order_id == this.id )); 
      return this;  
    } else { 
      let shipmentItems = this.id ? (await new ShipmentItemModel().where("order_id", this.id ).get_datas()).rows.to_shipment_items_class() : []; 
      return await this.load_shipment_items(shipmentItems);
    }
  }

  update_status_and_shipped_qty_sql_for_destroy_shipment(shipmentId){
    let sql = [];

    //贈品不做處理
    if ( this.coupon_type == this.CONSTANTS.CouponProductRelation.TYPE["贈品"] ) {
      return [];
    }
 
    if (!this.shipment_items) {
      alert('update_status_and_shipped_qty_sql_for_destroy_shipment 沒有 shipment_items');
      return [];
    }

    let shipmentItem = this.shipment_items.filter(i => i.shipment_id == shipmentId).first();

    if (!shipmentItem) {
      alert('update_status_and_shipped_qty_sql_for_destroy_shipment 沒有對應的 shipment_item');
      return [];
    }

    return [{
      tableName: "orders",
      params:[ this.CONSTANTS.Order.STATUS["出貨處理中"], shipmentItem.shipped_qty, this.id ],
      sql: `
        UPDATE orders
        SET status = $1, 
            shipped_qty = shipped_qty - $2 
        WHERE id = $3`
    }];  
  }

  async batch_load_product(orders, products = null, productModels = null, productPrices = null) {
    orders = orders.to_orders_class();

    if (!orders || orders.length == 0 ) {
      return orders;
    }

    let productIds = orders.map(o => o.product_id);
    let allProducts = products ? products.to_products_class() : (await new ProductModel().where("id in ?", [productIds]).get_datas()).rows.to_products_class();
    let allProductModels = productModels ? productModels.to_product_models_class() :  (await new ProductModelClass().where("product_id in ?", [productIds]).get_datas()).rows.to_product_models_class();
    let allProductPrices = productPrices ? productPrices.to_product_prices_class() :  (await new ProductPriceModel().where("product_id in ?", [productIds]).get_datas()).rows.to_product_prices_class();
   
    for (let productModel of allProductModels) {
      await productModel.load_product_prices(allProductPrices); 
      await productModel.load_product(allProducts); 
    }

    for (let product of allProducts) {
      await product.load_product_models(allProductModels); 
    } 

    for ( let order of orders ) {
      order.load_product(allProducts);
    }

    return orders;
  }
  
  async batch_load_shipment_items(orders, shipmentItems = null) {  
    orders = orders.to_orders_class(); 
    let orderIds = orders.map(o => o.id);

    if (!shipmentItems) { 
      shipmentItems = orderIds.length == 0 ? [] : (await (new ShipmentItemModel()).where(" order_id in ? ",[orderIds]).get_datas()).rows.to_shipment_items_class();
    }

    for ( let order of orders ) {
      order.load_shipment_items(shipmentItems);
    } 
    return orders;  
  }
 
  async batch_load_coupon_relation_and_gift_orders(orders) {  
    orders = orders.to_orders_class(); 
    let couponRelationIds = orders.map(o => o.coupon_relation_id);

    if (!couponRelationIds || couponRelationIds.length == 0 ) {
      return orders;
    }

    let couponRelations = (await (new CouponRelationModel()).where(" id in ? ",[couponRelationIds]).get_datas()).rows.to_coupon_relations_class();
    // let couponRelationIds = couponRelations.map(c => c.id);
    let orderGiftOrders = (await (new OrderModel()).where(" coupon_relation_id in ? ",[couponRelationIds]).get_datas()).rows.to_orders_class();

    orderGiftOrders = await new OrderModel().batch_load_product(orderGiftOrders);

    for ( let order of orders ) { 
      if (order.coupon_relation_id) {
        order.load_coupon_relation(couponRelations); 
        if (order.coupon_relation) {
          order.coupon_relation.set_import({ "CONSTANTS" : this.CONSTANTS }).load_gift_orders(orderGiftOrders);
          let giftOrderModels = ( order.coupon_relation.gift_orders ?? [] ).to_orders_class();
        }         
      }
    }
  
    return orders;  
  }

  async batch_load_coupon_relation(orders) {  
    orders = orders.to_orders_class();
    let couponRelationIds = orders.map(o => o.coupon_relation_id);
    let couponRelations = (await (new CouponRelationModel()).where(" id in ? ",[couponRelationIds]).get_datas()).rows.to_coupon_relations_class();

    for ( let order of orders ) { 
      if (order.coupon_relation_id) { 
        order.load_coupon_relation(couponRelations); 
      } 
    }  
  } 

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 
   
  async destroy_sql(options = {}){
    let that = this; 
    let decreaseOrdersCountSql = [];
    let errorMessage = []; 
    let checkStockResult = {};
 
    let destroyCouponRelationSql = [];
    let destroyGiftSql = [];
 
    if ( !options.CONSTANTS ) {
      alert('OrderModel destroy 請先定義 options.CONSTANTS');
      return [];
    }
 
    if ( !this.product_id ) {
      alert('OrderModel 請先定義 this.product_id');
      return [];
    }

    if ( !this.id ) {
      alert('OrderModel 請先定義 this.id');
      return [];
    } 
 
    if (options["destroyRelationTable"]) { 
      decreaseOrdersCountSql = new ProductModel({id: this.product_id}).decrease_orders_count_sql(); 
   
      checkStockResult = await new ProductModel().check_changes_stock_and_package_qty({
        newQuantity: 0, 
        newProductModelId: null, 
        newProductId: this.product_id, 
        oldProductId: this.product_id, 
        orderId: this.id,
        passStockQtyValidate: true
      });

      if (!checkStockResult["success"]) {  
        errorMessage = [{ 
          sql: "SELECT * FROM member_levels WHERE id=0", //主要是顯示錯誤, 隨便亂寫一個sql
          params: [],
          validate: function(currentResult, allResults){ 
            let success = false; 
            return success;
          },
          errorMessage: checkStockResult["errors"].join(',') 
        }]; 
      } 

      //如果不是贈品才刪除以下內容
      if (!options.isDeleteGift) {
        let couponRelationModel = (await new CouponRelationModel().where("id",this.coupon_relation_id).get_datas()).rows?.to_coupon_relations_class().first();     
        if (couponRelationModel) { 
          destroyCouponRelationSql = couponRelationModel?.destroy_sql();
          await couponRelationModel.set_import({ "CONSTANTS" : options.CONSTANTS }).load_gift_orders();
          if (couponRelationModel.gift_orders) { 
            for ( let giftOrder of couponRelationModel.gift_orders ) {
              let sql = await giftOrder.destroy_sql({destroyRelationTable: true, isDeleteGift: true, CONSTANTS: options.CONSTANTS })
              destroyGiftSql = [ ...destroyGiftSql, ...sql ];
            } 
            // destroyGiftSql = await couponRelationModel.gift_order.destroy_sql({destroyRelationTable: true, isDeleteGift: true, CONSTANTS: options.CONSTANTS });            
          } 
        }         
      } 
    }
 
    let sqlObjs = [
      // {sql: "BEGIN"}, 
      ...super.destroy_sql(), 
      ...decreaseOrdersCountSql,
      ...errorMessage,
      ...( checkStockResult["updateStockAndPackageQtySqlObjs"] ?? [] ),
      
      ...destroyCouponRelationSql,
      ...destroyGiftSql, 
      // { 
      //   sql: "SELECT * FROM member_levels WHERE id=0", 
      //   params: [],
      //   validate: function(currentResult, allResults){ 
      //     let success = false; 
      //     return success;
      //   },
      //   errorMessage: "就是錯" 
      // }, 

    // {sql: "END"}
    ];
  
    return sqlObjs;
  } 

  set_current_used_coupon(coupon){
    this.current_used_coupon = coupon;
    return this;
  }
 

  // 判斷此訂單(this) 可以用那些優惠 並且 顯示尚未達成的 buy_product 條件 
  // 然後設定成 order.active_coupons 
  set_active_coupons(activeCoupons, quantity) {

    if (!this.product_id) {
      alert('set_active_coupons 沒有定義 this.product_id');
      return this;
    }

    if (!this.CONSTANTS) {
      alert('set_active_coupons 沒有定義 this.CONSTANTS');
      return this;
    }

    this.active_coupons = activeCoupons.filter(c => (
      c.buy_product_id == this.product_id &&
      [this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"], this.CONSTANTS.Coupon.TYPE_CODE["買多折扣"]].includes(c.type_code*1)
    ));
 
    this.active_coupons = this.active_coupons.to_coupons_class();

    for ( let activeCoupon of this.active_coupons ) {  
      activeCoupon
        .set_import({ "Swal" : this.Swal, "CONSTANTS" : this.CONSTANTS })
        .order_coupon_buy_product_validate(this); 
    }
 
    return this; 
  } 

  set_site(siteId) {
    this.site_id = siteId;
    return this;
  }  

  set_discount_amount(CONSTANTS, couponRelation){
    // if ( coupon && !couponRelation ) {
    //   alert(' set_total_price 請先 load_coupon_relation ');
    //   return;
    // }

    if (couponRelation) { 
      this.discount_amount_was = this.discount_amount;
      if (couponRelation.discount_type == CONSTANTS.Coupon.DISCOUNT_TYPE["固定金額"] ) {
        this.discount_amount = couponRelation.discount;
      } else if( couponRelation.discount_type == CONSTANTS.Coupon.DISCOUNT_TYPE["比率"] ) {

        if ([undefined, null, ""].includes(this.price)) {
          alert('沒有設定 this.price');
          return;
        } 
        if ([undefined, null, ""].includes(this.quantity)) {
          alert('沒有設定 this.quantity');
          return;
        }

        // this.discount_amount = (this.price * this.quantity * couponRelation.discount / 100 ); 
        // 20 代表 20% = 打8折

        this.discount_amount = couponRelation.coupon.get_discount_amount(this.price * ( this.shipped_qty_for_shipment || this.quantity ), CONSTANTS);
        // 20 代表 20% = 打8折

        //get_discount_amount

      } else {
        this.discount_amount = 0; 
      }

      //把 如果折扣價格被改變了 就把 total_price 設定為 null, 必須重算
      if (this.discount_amount_was != this.discount_amount) { 
        this.total_price = null; 
      } 
    } else {
      this.discount_amount = 0;
    }  
    return this;
  }

  set_total_price(isGift = false) {
 
    if ( [undefined, null, ""].includes(this.discount_amount) ) {
      alert(' set_total_price 請先 定義 this.discount_amount ');
      return;
    }
    if ( [undefined, null, ""].includes(this.price) ) {
      alert(' set_total_price 請先 定義 this.price ');
      return;
    }
    if ( [undefined, null, ""].includes(this.quantity) ) {
      alert(' set_total_price 請先 定義 this.quantity ');
      return;
    }

    if (isGift) {
      this.total_price_if_not_gift = (this.price_if_not_gift * ( this.shipped_qty_for_shipment || this.quantity ) - this.discount_amount);
      this.total_price = 0;
      return this;
    }

    if ( this.id ) { 

      if ( !this.shipment_items) {
        alert(' set_total_price 由於是部分出貨訂單 所以必須有 this.shipment_items 以做判斷 ');
        return;
      }
 
      let sumOfShipmentItemsShippedQty = this.shipment_items.reduce((accumulator, currentValue) => { return accumulator + currentValue.shipped_qty; }, 0) ?? 0;
      let sumOfShipmentItemsShipmentTotalPrice = this.shipment_items.reduce((accumulator, currentValue) => { return accumulator + currentValue.shipment_total_price*1; }, 0) ?? 0;
      if ( ( sumOfShipmentItemsShippedQty + ( this.shipped_qty_for_shipment || this.quantity ) ) == this.quantity ) {
        this.total_price = this.total_price_was - sumOfShipmentItemsShipmentTotalPrice;
      } else { 
        this.total_price = (this.price * ( this.shipped_qty_for_shipment || this.quantity ) - this.discount_amount);
      }
    } else {
      this.total_price = (this.price * ( this.shipped_qty_for_shipment || this.quantity ) - this.discount_amount);
    }

    return this;
  }
 
  set_quantity_and_price(quantity, shipped_qty_for_shipment, price, couponType, cost = null, shippedQty = null, CONSTANTS){
    // shipped_qty_for_shipment = shipped_qty_for_shipment || quantity;

    //傳進來的 cost 是一個商品數目的 cost 
    if (quantity == undefined || quantity == null) {
      alert(' set_quantity_and_price quantity 數值不正確 ');
      return;
    }
    if (price == undefined || price == null) {
      alert(' set_quantity_and_price price 數值不正確 ');
      return;
    }
 
    this.cost = cost == null ? this.cost : cost; 
    this.shipped_qty = shippedQty == null ? this.shipped_qty : shippedQty; 
    this.shipped_qty_for_shipment = [undefined, null, ""].includes(shipped_qty_for_shipment) ? shipped_qty_for_shipment : shipped_qty_for_shipment*1;

    //把 total_price 和 discount_amount 設定為 null, 必須重算
    this.total_price = null;
    this.discount_amount = null; 

    this.quantity = [undefined, null, ""].includes(quantity) ? quantity : quantity*1;
    this.coupon_type = couponType; 

    if ( this.coupon_type == CONSTANTS.CouponProductRelation.TYPE["贈品"] ) { 
      this.price_if_not_gift = price;
      this.price = 0;
    } else {
      this.price = price; 
    }  
    return this;
  }


  // set_coupon_type(type){
  //   this.coupon_type = type; 
  //   return this;
  // }


  set_message_and_remark(remark = null, sellerMessage = null, buyerMessage = null) {   
    this.remark = remark == null ? this.remark : remark;
    this.seller_message = sellerMessage == null ? this.seller_message : sellerMessage;
    this.buyer_message = buyerMessage == null ? this.buyer_message : buyerMessage; 
    return this;
  }
 
  set_status(status = null, changeStatusBy = null, canceledReason = null) { 

    if ( status && !this.CONSTANTS) {
      alert('order set_status 沒有 this.CONSTANTS');
      return this;
    }
 
    if ( status && !Object.values(this.CONSTANTS.Order.STATUS).includes(status)) {
      alert(' order set_status 傳入的參數 沒有包含在 CONSTANTS.Order.STATUS ');
      return this;
    }
 
    let nowTime = new Date();
    this.change_status_by = changeStatusBy == null ? this.change_status_by : changeStatusBy;
    this.change_status_at = changeStatusBy == null ? this.change_status_at : nowTime;

    this.status = status == null ? this.status : status;
    this.canceled_reason = canceledReason == null ? this.canceled_reason : canceledReason;
    return this;
  }

  set_is_on_site_checkout_order(isOnSiteCheckoutOrder = false) { 
    this.is_on_site_checkout_order = isOnSiteCheckoutOrder;
    return this;
  } 

  set_buyer_related_information(userId = null, memberLevelId = null, buyerName = null, buyerMobile = null) {
    this.user_id = userId == null ? this.user_id : userId;
    this.member_level_id = memberLevelId == null ? this.member_level_id : memberLevelId;
    this.buyer_name = buyerName == null ? this.buyer_name : buyerName;
    this.buyer_mobile = buyerMobile == null ? this.buyer_mobile : buyerMobile;
    return this;
  } 

  set_product_related_information(productId = null, productModelId = null, modelName = null) {  
    this.product_id = productId == null ? this.product_id : productId;
    this.product_model_id = productModelId == null ? this.product_model_id : productModelId;
    this.model = modelName == null ? this.model : modelName;
    return this; 
  } 
  
  set_constants(saleType = null, source = null) {  
    this.sale_type = saleType == null ? this.sale_type : saleType;
    this.source = source == null ? this.source : source;
    return this;     
  }

  set_editor(editUserId) {  
    this.created_by = this.created_by ? this.created_by : editUserId;
    this.updated_by = editUserId; 
    return this;       
  }

  before_save_validate(){
    if ([undefined, null, ""].includes(this.total_price)) {
      alert(`orderModel save_sql total_price 沒有設定 `);
      return false;
    }
    if ([undefined, null, ""].includes(this.discount_amount)) {
      alert(`orderModel save_sql discount_amount 沒有設定 `);
      return false; 
    }
    return true;
  }

  validate_coupon_relation_gift_count_match(couponRelationId) {
    let isDynamic = false;
    if (typeof couponRelationId == 'function' ) {
      isDynamic = true;
    }
 

// select sum(quantity)   
// from orders inner join coupon_relations ON orders.coupon_relation_id = coupon_relations.id
// where orders.coupon_relation_id IS NOT NULL


 //還要接著改
    let sqlObj = { 
      sql: `SELECT * FROM orders WHERE id = ${ isDynamic ? "{@coupon_relation_id}" : "$1" } AND quantity < shipped_qty`, 
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length == 0); 
        return success;
      },
      errorMessage: "贈品數目不對"
    }

    if (isDynamic) {
      let saveTableName = this.saveTableName;
      sqlObj["dynamicParams"] = {
        coupon_relation_id: couponRelationId
      }
    } else {
      sqlObj["params"] = [ this.id ];
    }

    return [sqlObj]; 
  }
  
  async save_by_ajax(params) { 

    let jsonArray = []; 
    for (let data of params["datas"]) {
      let dataJson = null;
      dataJson = data.to_json(["coupon_relation","tableName","id","site_id","device_id","user_id","product_id","product_model_id","product_source_message_id","member_level_id","sale_type","source","number","status","change_status_by","change_status_at","canceled_reason","remark","seller_message","buyer_message","price","cost","quantity","package_qty","shipped_qty","total_price","model","source_message","created_by","updated_by","created_at","updated_at","buyer_name","buyer_mobile","discount_amount","coupon_type","coupon_relation_id","is_on_site_checkout_order"]);
      
      if (dataJson["coupon_relation"]) {
        dataJson["coupon_relation"] = dataJson["coupon_relation"].to_json(["gift_orders","tableName","id","shipment_id","coupon_id","shipment_discount_amount","discount_type","discount","limit_amount","name","code","type_code","limited_count","start_time","end_time","used_count","gift","buy_count","gift_count","user_id","limit_user_used_count","coupon_relation_used_count","buy_product_id","gift_product_id"]);

        if (dataJson["coupon_relation"]?.gift_orders) { 
          for (let i = 0; i < dataJson["coupon_relation"].gift_orders.length; i++) {
            dataJson["coupon_relation"].gift_orders[i] = dataJson["coupon_relation"].gift_orders[i].to_json(["tableName","id","site_id","device_id","user_id","product_id","product_model_id","product_source_message_id","member_level_id","sale_type","source","number","status","change_status_by","change_status_at","canceled_reason","remark","seller_message","buyer_message","price","cost","quantity","package_qty","shipped_qty","total_price","model","source_message","created_by","updated_by","created_at","updated_at","buyer_name","buyer_mobile","discount_amount","coupon_type","coupon_relation_id","is_on_site_checkout_order"]);
          } 
        }
      }
 
      if (dataJson) {
        jsonArray.push(dataJson);
      } 
    }
  
    let result = await super.save_ajax({ 
      tablePluralName: "orders",
      tableSingularName: "order", 
      data : jsonArray,
      domain : params["domain"],
      isNew : params["id"] ? false : true,
      isBackend : [undefined, null, ""].includes(params["isBackend"]) ? true : params["isBackend"]
    });

    return result;
  }


  async destroy(params) {  
    let that = this; 
    let result = await super.destroy_by_ajax({ 
      tablePluralName: "orders",
      tableSingularName: "order", 
      id: that.id,
      domain : params["domain"], 
      isBackend : [undefined, null, ""].includes(params["isBackend"]) ? true : params["isBackend"]
    });

    return result;
  }


  async save_sql(options = {}){
    let that = this; 
    // let validate = true; 
    let success = true;
    let errors = [];
    let sqlArray = [];
    let dynamicParams = options["dynamicParams"] ?? {};

    if ( !options.currentUserId ) {
      errors = ['user_id 為空值, 可能沒有登入']; 
      success = false;
      return {
        success: success,
        errors: errors,  
        sql: sqlArray   
      };
    } 

    if (!this.before_save_validate()) {
      return;
    } 

    if (!this.id) { 
      this.created_by = options.currentUserId;
      this.status = this.status == 'on_site_checkout' ? this.status : "pending";
    } 
    this.updated_by = options.currentUserId;
  
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      // "orders", 
      this.saveTableName,
      `id, site_id,coupon_relation_id${ dynamicParams["coupon_relation_id"] ? "(IsDynamicParam)" : "" }, coupon_type, device_id(NullIfEmpty), user_id, product_id, product_model_id(NullIfEmpty), product_source_message_id(NullIfEmpty), member_level_id(NullIfEmpty), sale_type(NullIfEmpty), source(NullIfEmpty), number(NullIfEmpty), status, change_status_by(NullIfEmpty), change_status_at(NullIfEmpty), canceled_reason, remark, seller_message, buyer_message, price, cost(NullIfEmpty), quantity, package_qty(NullIfEmpty), shipped_qty(ZeroIfBlank), total_price, model, source_message,${ this.id ? "" : "created_by(NullIfEmpty)," }updated_by(NullIfEmpty), created_at, updated_at, buyer_name, buyer_mobile, discount_amount, is_on_site_checkout_order`, 
      {...options.dynamicParams}
    )[0];
 
    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id; 
      if(options?.afterSaveCallBack){
        options?.afterSaveCallBack.apply(this,[result, sqlString, sqlParams, datasByTableName]);
      }
    }};
 
    let checkStockResult = {};
    let addOrdersCountSql = [];
    if (options["saveRelationTable"]) { 
      checkStockResult = await new ProductModel().check_changes_stock_and_package_qty({ 
        order : this, 
        caches : { 
          products : options?.caches?.products, 
          productModels : options?.caches?.productModels, 
        },
        newQuantity: this.quantity, 
        newProductModelId: this.product_model_id, 
        newProductId: this.product_id, 
        oldProductId: this.product_id_was, 
        orderId: this.id,
        passStockQtyValidate: options.passStockQtyValidate            
      }); 

      if (!checkStockResult["success"]) { 
        // alert(checkStockResult["errors"].join(','));
        success = false; 
        errors = checkStockResult["errors"];
      }   
      addOrdersCountSql = new ProductModel({id: this.product_id}).increase_orders_count_sql(); 
    }

    if (success) {

      let dynamicUpdateNumber = ( this.id ? [] : super.dynamic_update_number() );

      sqlArray = [
        saveSql,   
        ...dynamicUpdateNumber,    
        ...this.validate_column_logic_sql({ "isDynamic": this.id ? false : true }), 
        ...( checkStockResult["updateStockAndPackageQtySqlObjs"] ?? [] ),
        ...addOrdersCountSql 
      ];
 
    } else {
      sqlArray = [];   
    }

    return {
      success: success,
      errors: errors,
      sql: sqlArray
    }  
  }

  add_shipped_qty_sql(addQty) {

    if ( !this.id ) {
      alert('add_shipped_qty_sql 請先定義id');
      return [];
    }

    if ( !addQty ) {
      alert('請先定義addQty');
      return [];
    }

    return [{
      sql: `
        UPDATE ${ this.tableName } 
        SET shipped_qty = shipped_qty + $2
        WHERE id=$1;`, 
      params: [ this.id, addQty ]
    }];
  }
  
  validate_shipped_qty_sql(options = {}){

    if ( !options["isDynamic"] && this["id"] == undefined) {
      alert('validate_shipped_qty_sql 請先定義id');
      return [];
    }
 
    let sqlObj = { 
      sql: `SELECT * FROM orders WHERE id = ${ options["isDynamic"] ? "{@order_id}" : "$1" } AND quantity < shipped_qty`, 
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length == 0); 
        return success;
      },
      errorMessage: "訂單出貨數量超出正常數量"
    }

    if (options["isDynamic"]) {
      let saveTableName = this.saveTableName;
      sqlObj["dynamicParams"] = {
        order_id: function(results){    
          return results[saveTableName].rows[0].id;  
        }
      }
    } else {
      sqlObj["params"] = [ this.id ];
    }

    return [sqlObj];
  }

  validate_column_logic_sql(options){
    return [
      ...this.validate_shipped_qty_sql(options)
    ];
  } 
    
  create_coupon_relation(coupon) { 
    let couponModel = [coupon].to_coupons_class()?.first();

    if (!this.total_price) {
      alert(' create_coupon_relation 的 total_price 是空直或是0 ');
      return this;
    }

    this.coupon_relation = new CouponRelationModel()
      .set_coupon_relation_columns(couponModel) 
      .set_shipment_discount_amount(couponModel, this.total_price, this.CONSTANTS)
      .set_whose_buy(this.user_id)
      .set_buy_and_gift_count(couponModel); 
 
    return this;
  }
 
  split_buy_order(CONSTANTS, buyCount, createdBy) { 
    let packageQty = 1; 
    let buyQuantity = buyCount * packageQty;

    if ( this.quantity < buyQuantity) {
      // alert('split_buy_order 訂單數量不可小於分割數量');
      return null;
    }
  
    let splitOrder = new OrderModel().set_import({ CONSTANTS: CONSTANTS });

    splitOrder = splitOrder
    .set_uuid()
    .set_site(this.site_id)
    .set_message_and_remark(this.remark, this.seller_message, this.buyer_message)
    .set_status(this.status, createdBy)
    .set_buyer_related_information(this.user_id, this.member_level_id, this.buyer_name, this.buyer_mobile)
    .set_product_related_information(this.product_id, this.product_model_id, this.model) 
    .set_constants(this.sale_type, this.source)
    .set_is_on_site_checkout_order(this.is_on_site_checkout_order)
    .set_editor(createdBy) 
    .set_quantity_and_price(buyQuantity, this.shipped_qty_for_shipment, this.price, CONSTANTS.CouponProductRelation.TYPE["優惠商品"], this.cost, this.shipped_qty, CONSTANTS)
    .set_discount_amount(CONSTANTS, splitOrder.coupon_relation)
    .set_total_price();

    this.set_quantity_and_price(this.quantity - buyQuantity, this.shipped_qty_for_shipment, this.price, this.coupon_type, this.cost, this.shipped_qty, CONSTANTS)
    .set_discount_amount(CONSTANTS)
    .set_total_price();

    return splitOrder;
  }

  //分割出符合優惠的數量的 贈品訂單  
  split_gift_order(CONSTANTS, giftCount, createdBy) { 
    let packageQty = 1; 
    let giftQuantity = giftCount * packageQty;

    if ( this.quantity < giftQuantity) {
      // alert('split_buy_order 訂單數量不可小於分割數量');
      return null;
    }
  
    let splitOrder = new OrderModel().set_import({ CONSTANTS: CONSTANTS });

    splitOrder = splitOrder
    .set_uuid()
    .set_site(this.site_id)
    .set_message_and_remark(this.remark, this.seller_message, this.buyer_message)
    .set_status(this.status, createdBy)
    .set_buyer_related_information(this.user_id, this.member_level_id, this.buyer_name, this.buyer_mobile)
    .set_product_related_information(this.product_id, this.product_model_id, this.model) 
    .set_constants(CONSTANTS.Order.SALE_TYPE["贈品"], this.source)
    .set_is_on_site_checkout_order(this.is_on_site_checkout_order)
    .set_editor(createdBy) 
    .set_quantity_and_price(giftQuantity, this.shipped_qty_for_shipment, this.price, CONSTANTS.CouponProductRelation.TYPE["贈品"], this.cost, this.shipped_qty, CONSTANTS)
    .set_discount_amount(CONSTANTS)
    .set_total_price(true);

    this
      .set_quantity_and_price(this.quantity - giftQuantity, this.shipped_qty_for_shipment, this.price, this.coupon_type, this.cost, this.shipped_qty, CONSTANTS)
      .set_discount_amount(CONSTANTS)
      .set_total_price(false);

    return splitOrder;
  }
 
  async save_sql_by_coupon(couponModel, createdBy, giftProductModels, CONSTANTS, currentUserId, couponProductRelations = null, couponBuyAndGiftProducts = null, couponBuyAndGiftProductModels = null){
    // let couponOrder = null;
    // let giftOrder = null;
    // let orderModel = new OrderModel(); 
    let remainingOrderSql = []; 
    let allCouponRelationAndBuyAndGiftSql = [];
    let allCouponRelationAndBuyAndGiftModels = [];
    let giftInStockAreSufficient = true;

    if (couponModel) { 
      couponModel.load_coupon_product_relations_and_related_product({
        CONSTANTS : CONSTANTS,
        coupon_product_relations : couponProductRelations,
        products : couponBuyAndGiftProducts,
        productModels : couponBuyAndGiftProductModels 
      }); 
    }
  
    //贈品庫存是否足夠
    if (
        couponModel && 
        couponModel.id &&
        couponModel.gift_product && 
        couponModel.gift_product.stock_qty && 
        couponModel.gift_product.stock_qty < couponModel.gift_count  
      ) {
      giftInStockAreSufficient = false;
    } 
 
    if (
        ( couponModel && couponModel.id ) &&
        [CONSTANTS.Coupon.TYPE_CODE["買多贈品"], CONSTANTS.Coupon.TYPE_CODE["買多折扣"]].includes(couponModel.type_code*1) &&
        giftInStockAreSufficient
      ) {
      // let couponRelationAndBuyAndGiftSql = [];
      let isFirst = true;
      let maximumNumberOfTimes = Utility.createNumberArray(1,210);  
      let loopCount = 0;

      for ( let index of maximumNumberOfTimes ) {
        let couponRelationAndBuyAndGift = await create_coupon_relation_and_buy_and_gift_sql(this, couponModel, createdBy, giftProductModels, CONSTANTS, couponBuyAndGiftProducts, couponBuyAndGiftProductModels);
        // couponRelationAndBuyAndGiftSql = couponRelationAndBuyAndGift["sql"];
 
        if ( couponRelationAndBuyAndGift?.models?.couponRelation || couponRelationAndBuyAndGift?.models?.buyOrder ) {
          // allCouponRelationAndBuyAndGiftSql = [ ...allCouponRelationAndBuyAndGiftSql, ...couponRelationAndBuyAndGiftSql];
          // allCouponRelationAndBuyAndGiftModels.push(couponRelationAndBuyAndGift["models"]["couponRelation"]);

          if ( couponRelationAndBuyAndGift["models"]["couponRelation"] && couponRelationAndBuyAndGift["models"]["giftOrders"] ) {
            couponRelationAndBuyAndGift["models"]["couponRelation"].gift_orders = couponRelationAndBuyAndGift["models"]["giftOrders"];
          }

          couponRelationAndBuyAndGift["models"]["buyOrder"].coupon_relation = couponRelationAndBuyAndGift["models"]["couponRelation"];

          allCouponRelationAndBuyAndGiftModels.push(couponRelationAndBuyAndGift["models"]["buyOrder"]);
          // allCouponRelationAndBuyAndGiftModels = [ ...allCouponRelationAndBuyAndGiftModels , ...couponRelationAndBuyAndGift["models"]["giftOrders"] ];
          allCouponRelationAndBuyAndGiftModels = allCouponRelationAndBuyAndGiftModels.filter(o => o);
          loopCount = index;
        } else {
          break;
        }
      } 

      //優惠的判斷最高200次 太高機器也大概承受不住
      if (loopCount >= 200) {
        alert('優惠套用次數過多,可能造成機器異常,請重新確定商品數量');
        return;
      }
    } else {
      this
        .set_discount_amount(CONSTANTS)
        .set_total_price();
    } 

    if (this.quantity != 0) { 
      // let orderSaveResult = (await this.save_sql({ saveRelationTable:true, passStockQtyValidate: true, currentUserId: currentUserId })); 
      // remainingOrderSql = orderSaveResult.sql; 
      // allCouponRelationAndBuyAndGiftSql = [...allCouponRelationAndBuyAndGiftSql, ...remainingOrderSql]
      allCouponRelationAndBuyAndGiftModels = [ ...allCouponRelationAndBuyAndGiftModels, ...[this] ];
    }
 
    async function create_coupon_relation_and_buy_and_gift_sql(thisOrder, couponModel, createdBy, giftProductModels, CONSTANTS, 
        couponBuyAndGiftProducts = [],
        couponBuyAndGiftProductModels = []
      ){
      let buyOrder = null; 
      let couponRelation = null;
      let couponRelationId = null; 
      // let buyOrderSql = [];
      // let giftOrderSql = [];
      // let couponRelationSql = []; 
      let buyCouponProductRelation = null;
      // let buyCouponProductRelationSql = [];
 
      if (!couponModel.buy_count) {
        alert('create_coupon_relation_and_buy_and_gift_sql 沒設定 couponModel.buy_count');
        return;
      }
  
      //buyOrderSql
      let quantityWas = thisOrder.quantity; 
      buyOrder = thisOrder.split_buy_order(CONSTANTS, couponModel.buy_count, createdBy);
  
      thisOrder
        .set_discount_amount(CONSTANTS)
        .set_total_price();
      
      if (!buyOrder) {
        return [];
      } 
 
      couponRelation = new CouponRelationModel()
        .set_coupon_relation_columns(couponModel) 
        .set_shipment_discount_amount(couponModel, buyOrder.total_price, CONSTANTS)
        .set_whose_buy(thisOrder.user_id)
        .set_buy_and_gift_count(couponModel);//, giftProductModels, CONSTANTS

      //couponRelationSql
      // couponRelationSql = couponRelation.save_sql({
      //   saveRelationTable: true, 
      //   CONSTANTS: CONSTANTS, 
      //   afterSaveCallBack: function(result, sqlString, sqlParams, datasByTableName){
      //     couponRelationId = result.rows[0].id; 
      //   } 
      // });
      
      let buyOrderId = null;
      let giftOrderId = null;
      let validateSql = [];
      let giftOrders = [];

      if (couponModel.gift_product_id) {
  

        //這一段判斷 是否需要使用 create_gift_order........

        //giftOrderSqls
        let createGiftResult = couponModel.create_gift_order(buyOrder, createdBy, CONSTANTS);
  
        if (createGiftResult["success"]) {
          giftOrders = createGiftResult["orders"];
          couponRelation.gift_orders = giftOrders;
        } 
 
        // let productIds = giftOrders.map(o => o.product_id).filter(i => i).unique(); 
        let products = couponBuyAndGiftProducts.to_products_class(); //productIds.length == 0 ? [] : (await new ProductModel().where("id in ?",[productIds]).get_datas()).rows.to_products_class();
        let productModels = couponBuyAndGiftProductModels.to_product_models_class();//productIds.length == 0 ? [] : (await new ProductModelClass().where("product_id in ?",[productIds]).get_datas()).rows.to_product_models_class();
 
        for ( let product of products) {
          product.load_product_models(productModels);
        }

        for (let giftOrder of giftOrders) {
          giftOrder.load_product(products);
          // let giftOrderSaveResult = (await giftOrder.save_sql({  
          //   caches: {
          //     products: products, //批次處理 
          //     productModels: productModels //批次處理
          //   }, 
          //   saveRelationTable:true, 
          //   passStockQtyValidate: true, 
          //   dynamicParams: { 
          //     coupon_relation_id: function(results) {   
          //       return results?.coupon_relations?.rows[0]?.id; 
          //     }
          //   }
          // }));

          // let sql = giftOrderSaveResult["sql"]; 

          //如果 贈品與購買的商品一樣 購買的商品要調整數量
          if ( giftOrder && 
            giftOrder.product_id == thisOrder.product_id && 
            thisOrder.quantity >= giftOrder.quantity
            ) { 
            thisOrder.set_quantity_and_price(thisOrder.quantity - giftOrder.quantity, thisOrder.shipped_qty_for_shipment, thisOrder.price, thisOrder.coupon_type, thisOrder.cost, 0, CONSTANTS);
          } 

          // giftOrderSql = [
          //   ...giftOrderSql,
          //   ...sql
          // ];  
        }

        //如果產生贈品失敗(應該是庫存不足) 直接 return;
        if (!createGiftResult["success"]) {  
          thisOrder.set_quantity_and_price(quantityWas, thisOrder.shipped_qty_for_shipment, thisOrder.price, thisOrder.coupon_type, thisOrder.cost, thisOrder.shipped_qty, CONSTANTS);
          return {
            models : null,
            sql : null
          } 
        }

        //驗證贈品是否庫存有問題
        // if (giftOrders.length > 0) {
        //   validateSql = [
        //     ...validateSql,
        //     ...new ProductModel({id: giftOrders[0].product_id}).validate_product_and_product_model_stock_qty_sql()
        //   ];
        // } 
      } 
 
      //設定 buyOrder 的 total_price
      buyOrder.coupon_relation = couponRelation;
      buyOrder
        .set_discount_amount(CONSTANTS, couponRelation)
        .set_total_price();

      // let buyOrderSaveResult = (await buyOrder.save_sql({ 
      //   saveRelationTable:true, 
      //   passStockQtyValidate: true, 
      //   afterSaveCallBack: function(result, sqlString, sqlParams, datasByTableName){
      //     buyOrderId = result.rows[0].id; 
      //   }, 
      //   dynamicParams: { 
      //     coupon_relation_id: function(results) {   
      //       return results?.coupon_relations?.rows[0]?.id; 
      //     }
      //   }
      // }));   


      // buyOrderSql = buyOrderSaveResult.sql;
  
      //前面的步驟可能會調整到 quantity 再重算一次 total_price
      thisOrder
        .set_discount_amount(CONSTANTS)
        .set_total_price(); 

      couponRelation.gift_product = couponModel.gift_product;
      couponRelation.buy_product = couponModel.buy_product;
      buyOrder.product = couponModel.buy_product; 
      buyOrder.coupon_relation = couponRelation;

      if (giftOrders.length > 0 && couponRelation) { 
        for ( let giftOrder of giftOrders ) {
          giftOrder.coupon_relation = couponRelation;
        } 
      } 

      return {
        models : { buyOrder : buyOrder, giftOrders: giftOrders, couponRelation: couponRelation }
        // ,sql : [ ...couponRelationSql, ...buyOrderSql, ...giftOrderSql, ...validateSql]
      } 
    }
 
    return {
      // sql: allCouponRelationAndBuyAndGiftSql,
      models : allCouponRelationAndBuyAndGiftModels
    };
  }
  
} 


 
export class AdvanceBalanceModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "advance_balances";
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 10000),
      site_id : Math.floor(Math.random() * 100) + 1,
      user_id : Math.floor(Math.random() * 100) + 1,
      amount : Math.random() * 100  
    }
 
    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }  

  update_balance_sql() {
    let sql = [];
 
    return [{
      tableName: "advance_balances",
      params:[ this.site_id, this.user_id, this.id ],
      sql: `
        UPDATE advance_balances
        SET balance = ( select SUM(amount) FROM advances WHERE site_id = $1 AND user_id = $2 )
        WHERE id = $3`
    }]; 
  }
 
  add_balance_sql(addBalance) {  
    return [{ 
      params:[this.site_id, this.user_id],
      sql: `
        INSERT INTO advance_balances (site_id, user_id, balance, id)
        SELECT  $1, $2, 0 ,(SELECT COALESCE((SELECT MIN(id) FROM advance_balances WHERE id < 0), 0)-1)
        WHERE
        NOT EXISTS (  SELECT 1 FROM advance_balances WHERE site_id = $1 AND user_id = $2 );`
    },
    {
      tableName: "advance_balances",
      params:[this.user_id, this.site_id, addBalance ],
      sql: `
        UPDATE advance_balances
        SET balance = balance + $3
        WHERE site_id = $2 AND user_id = $1`
    },{ 
      sql: `
        SELECT * 
        FROM advance_balances 
        WHERE site_id = $2 AND user_id = $1 AND balance < 0
      `, 
      params: [this.user_id, this.site_id ],
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length == 0); 
        return success;
      },
      errorMessage: "預儲金不足"
    }]; 
  } 

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 
 
  save_sql(options = {}){ 
    let that = this; 
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      that.tableName, 
      "id,site_id,user_id,amount"  
    )[0];

    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};
   
    return [
    saveSql,   
    ...this.validate_column_logic_sql() 
    ]
  } 
} 
  
export class ChargedRecordPaymentModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "charged_record_payments";
  }
     
  async save_by_ajax(params) { 
    let chargedRecordPaymentJson = this.to_json([
      "id", 
      "charged_record_id",
      "site_id",
      "number",
      "charged_plan_id",
      "record_type",
      "amount",
      "pay_info",
      "paid",
      "max_storage_size_in_gb",
      "created_at",
      "updated_at" 
    ]);
   
    let result = await super.save_ajax({ 
      tablePluralName: "charged_record_payments",
      tableSingularName: "charged_record_payment", 
      data : chargedRecordPaymentJson,
      id : chargedRecordPaymentJson.id,
      domain : params["domain"],
      isNew : chargedRecordPaymentJson.id ? false : true,
      isBackend : [undefined, null, ""].includes(params["isBackend"]) ? true : params["isBackend"],
      isAdmin : [undefined, null, ""].includes(params["isAdmin"]) ? false : params["isAdmin"]
    }); 
    return result;
  } 
} 


export class ChargedRecordModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "charged_records";
  }
     
  async save_by_ajax(params) { 
    let chargedRecordJson = this.to_json([
      "id",
      "site_id",
      "amount",
      "tax",
      "valid_start_date",
      "valid_end_date",
      "remark",
      "created_by",
      "updated_by",
      "received_by",
      "charged_plan_id",
      "other_amount",
      "confirmed",
      "pay_method",
      "invoice_info",
      "invoice_number",
      "invoice_date",
      "invoice_processing_status",
      "invoice_vendor_no",
      "invoice_order_no",
      "pay_info",
      "other_amount_need_tax",
      "other_tax",
      "other_usage_amount",
      "other_usage_tax",
      "max_storage_size_in_gb",
      "record_type",
      "created_at",
      "updated_at",
    ]);
   
    let result = await super.save_ajax({ 
      tablePluralName: "charged_records",
      tableSingularName: "charged_record", 
      path: "fee",
      data : chargedRecordJson,
      id : chargedRecordJson.id,
      domain : params["domain"],
      isNew : chargedRecordJson.id ? false : true,
      isBackend : [undefined, null, ""].includes(params["isBackend"]) ? true : params["isBackend"],
      isAdmin : [undefined, null, ""].includes(params["isAdmin"]) ? false : params["isAdmin"]
    }); 
    return result;
  } 
} 




export class SiteModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "sites";
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 1000000),
      title : Math.random().toString(36).substring(7),
      pos_data_shared_type : Math.floor(Math.random() * 3),
      created_at : new Date(),
      updated_at : new Date(),
      tax_rate : (Math.random() * 10).toFixed(2),
      tax_included : Math.random() >= 0.5
    }
 
    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }
 
  to_json(whitelist){
    let data = whitelist ? whitelist : ["id","title","pos_data_shared_type","created_at","updated_at","tax_rate","tax_included"] 
    return super.to_json(data);
  }
 
  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 
 
  async save_by_ajax(params) { 

    let siteJson = this.to_json([
      "id",
      "title",
      "user_id",
      "domain",
      "zip",
      "address",
      "tel",
      "email",
      "fax",
      "remark",
      "billing_date",
      "invoice_received_name",
      "invoice_vat",
      "invoice_type",
      "invoice_email",
      "invoice_zip",
      "invoice_address",
      "disabled",
      "disabled_at",
      "disabled_by",
      "published",
      "published_at",
      "published_by",
      "created_at",
      "updated_at",
      "pos_data_shared_type",
      "tax_rate",
      "tax_included",
      "charged_plan_id",
      "invoice_donation_code",
      "invoice_carrier_number",
      "invoice_carrier_type"
    ]);
   
    let result = await super.save_ajax({ 
      tablePluralName: "sites",
      tableSingularName: "site", 
      data : siteJson,
      id : siteJson.id,
      domain : params["domain"],
      isNew : siteJson.id ? false : true,
      isBackend : [undefined, null, ""].includes(params["isBackend"]) ? true : params["isBackend"],
      isAdmin : [undefined, null, ""].includes(params["isAdmin"]) ? false : params["isAdmin"]
    }); 
    return result;
  }
 
  async register_by_ajax(params) { 
    return await this.ajax({ 
      method: "POST",  
      body:params, 
      url:`/sites`
    }); 
  }
} 
 
export class UserModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "users";
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 1000000),
      name : Math.random().toString(36).substring(7),
      mobile : "0911122233" 
    }
 
    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 
 
  save_sql(options = {}){ 
    let that = this; 
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      "users", 
      "id,name,mobile"   
    )[0];

    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};
    
    return [
      saveSql 
    ]
  }
 
} 


export class ProductCategoryModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "product_categories";
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 1000000),
      site_id : Math.floor(Math.random() * 1000000),
      name : Math.random().toString(36).substring(7),
      order_index : Math.floor(Math.random() * 100),
      created_at : new Date(),
      updated_at : new Date()
    } 

    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  }   
  
  async load_product_tags(productTags = null) {  
    if( productTags ) {
      this.product_tags = productTags.filter(p=>( p.product_category_id == this.id ));  
      return this;
    }    
  }
}

export class ProductTagModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "product_tags";
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 1000000),
      site_id : Math.floor(Math.random() * 1000000),
      product_category_id : Math.floor(Math.random() * 1000000),
      name : Math.random().toString(36).substring(7),
      order_index : Math.floor(Math.random() * 100),
      created_at : new Date(),
      updated_at : new Date()
    } 

    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 
}
 
export class ProductTagRelationModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "product_tag_relations";
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 1000000),
      product_id : Math.floor(Math.random() * 1000000),
      product_tag_id : Math.floor(Math.random() * 1000000) 
    } 

    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 
}
 
export class MemberLevelModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "member_levels";
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 1000000),
      site_id : Math.floor(Math.random() * 1000000),
      product_category_id : Math.floor(Math.random() * 1000000),
      name : Math.random().toString(36).substring(7),
      order_index : Math.floor(Math.random() * 100),
      created_at : new Date(),
      updated_at : new Date()
    } 

    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 


  save_sql(options = {}){ 
    let that = this; 
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      "member_levels", 
      "id,site_id,name,created_at,updated_at"   
    )[0];

    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};
   
    if( options["saveRelationTable"] ) {
        
    }
 
    return [
    saveSql 
    ]
  }

}


export class MembershipModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "memberships";
  }

  async get_autocomplete(params={}){  
    return await super.ajax({
      ...params,
      method:"GET",  
      url:`/${ params.domain }/backend/memberships/autocomplete.json?limit=10&term=${ params.value }&searchOtherData=${ params.searchOtherData }` 
    }); 
  } 

  async load_advance_balance(advanceBalances) {
    if( advanceBalances ) {
      return this.advance_balance = advanceBalances.filter(b=>( b.site_id == this.site_id && b.user_id == this.user_id ));  
    } else { 
      this.advance_balance = (await new AdvanceBalanceModel().where("site_id",this.site_id).where("user_id",this.user_id).get_datas()).rows.to_advance_balances_class().first();
    }  
  }
  
  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  } 
 

  async get_datas_by_ajax(params) { 
    let result = await super.get_datas_by_ajax(params);
 
    if (!result.success && result.errors && result.errors.length > 0 ) {
      await this.Swal.fire('發生錯誤', result.errors.join(","), 'error'); 
    } else { 
      return result?.react_props;
    }
  }
 
  validate_column_logic_sql(){
    return [];  
  }

  save_sql(options = {}){ 
    let that = this; 
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      "memberships", 
      "id,site_id,user_id,member_level_id,disabled,remark,created_at,updated_at"   
    )[0];

    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};
  
    let userSaveSql = []; 
    let updateMembershipUserIdSaveSql = []; 
    if( options["saveRelationTable"] ) {
       
      if (!this?.user?.name) { 
        alert(`請先設定this.user.name`);
        return [];
      }
      if (!this?.user?.mobile) { 
        alert(`請先設定this.user.mobile`);
        return [];
      }

      userSaveSql = new UserModel({ 
        id: this?.user_id,
        name: this?.user?.name,
        mobile: this?.user?.mobile 
      }).save_sql(); 

      if (!this?.user_id) {
 
        updateMembershipUserIdSaveSql = [{ 
          id: "{@id}",
          user_id: "{@user_id}" 
        }].toSaveSqlObjs(
          "memberships", 
          "id(IsDynamicParam),site_id,user_id(IsDynamicParam),member_level_id,disabled,remark,created_at,updated_at"   
        )[0];

        updateMembershipUserIdSaveSql = [{...updateMembershipUserIdSaveSql, dynamicParams: {
          user_id: function(results){
            return results.users.rows[0].id;  
          },
          id: function(results){
            return results.memberships.rows[0].id;  
          }
        }}]; 
      }
    }
 
    return [
    saveSql,   
    ...this.validate_column_logic_sql(),
    ...userSaveSql,
    ...updateMembershipUserIdSaveSql
    ]
  } 
}

 
export class CouponModel extends Model {
  constructor(that) { 
    super(that)
    this.limit_amount = this.limit_amount ? this.limit_amount*1 : this.limit_amount;
    this.limit_amount_was = this.limit_amount_was ? this.limit_amount_was*1 : this.limit_amount_was;
    this.tableName = "coupons";
  }
  
  allowed_as_a_gift(productId, productModelId = null){
    if (!this.coupon_product_relations) {
      alert(`CouponProductRelationModel 要先 load_coupon_product_relations`);
    }
    let isGift = this.coupon_product_relations.filter(r => ( r.product_id == productId && ( productModelId ? r.product_model_id == productModelId : true ) ) ).length > 0;
    return isGift;
  }

 
  // set_insufficient_gift_products_by_order(order, requestQuantity){ 
  //   if (requestQuantity == 0) {
  //     this.coupon.set_insufficient_gift_products(order.uuid, null);
  //   } else if( this.coupon.gift_product ) { 
  //     let productModelIds = this.coupon.coupon_product_relations.map(c => c.product_model_id).filter(m => m);
  //     this.coupon.set_insufficient_gift_products(order.uuid, "quantity", requestQuantity);
  //     this.coupon.set_insufficient_gift_products(order.uuid, "product_id", order.product_id);
  //     this.coupon.set_insufficient_gift_products(order.uuid, "product_name", order.product.name);  
  //     this.coupon.set_insufficient_gift_products(order.uuid, "product_ids", productModelIds);  
  //   }    
  // }


  // 分割出訂單作為贈品 因為贈品價格會變成0 等於會減少訂單總金額 反而導致不滿足優惠的最低金額 
  // 這邊要做計算是否會導致上述狀況 應該要減少多少才不會導致優惠的最低金額不滿足
  shipmentCouponRequestQuantity(giftCount, giftOrdersQuantitySum, orderPrice, limitAmount, orderAmount){
    // giftOrdersQuantitySum 代表目前 shipment.coupon_relation.gift_orders 的 quantity 的 總和

    //正常的情況 giftCount - giftOrdersQuantitySum 就是目前需要的數量
    let requestQuantity = ( giftCount - giftOrdersQuantitySum ); 

    //檢查 requestQuantity 會不會影響到目前的訂單總金額 導致不滿足 滿額金額
    if ( (orderAmount - requestQuantity*orderPrice) < limitAmount ) {
      
      if ( requestQuantity <= 1 ) {
        return 0;
      }
      //如果計算出來的金額會導致 訂單總金額 不滿足 滿額優惠的最低金額 就繼續算出最少只能多少數量 才不至於影響優惠的成立
      return this.shipmentCouponRequestQuantity(giftCount, giftOrdersQuantitySum+1, orderPrice, limitAmount, orderAmount);
    }

    return requestQuantity;
  } 





  // 分割出訂單作為贈品 因為贈品價格會變成0 等於會減少訂單總金額 反而導致不滿足優惠的最低金額 
  // 這邊要做計算是否會導致上述狀況 如果需求的數量會導致滿額優惠不成立 就回傳 false 反之 true
  // validateCouponRequestQuantity(giftCount, giftOrdersQuantitySum, orderPrice, limitAmount, orderAmount){
  //   // giftOrdersQuantitySum 代表目前 shipment.coupon_relation.gift_orders 的 quantity 的 總和

  //   //正常的情況 giftCount - giftOrdersQuantitySum 就是目前需要的數量
  //   let requestQuantity = ( giftCount - giftOrdersQuantitySum ); 

  //   //檢查 requestQuantity 會不會影響到目前的訂單總金額 導致不滿足 滿額金額
  //   if ( (orderAmount - requestQuantity*orderPrice) < limitAmount ) {
  //     return false;
  //   } 
  //   return true;
  // } 

 
  // simulate_orders_apply_coupon(ordersQuantityWas, notMatchedOrders);
  simulate_shipment_apply_coupon(orders, shipment, orderAmount) {
    let rollbackData = {};
    let tempSplitOrders = [];
    let newSplitOrders = [];
    let couponRelation = null;
    // shipment.coupon_relation = null;
 
    let noCouponCheckoutOrders = orders.filter(o => !o.coupon_type && o.status == this.CONSTANTS.Order.STATUS["現場結帳中"] );
 
    //如果有滿額優惠 先還原 再重新判斷優惠
    // if (shipment.coupon_relation && shipment.coupon_relation.gift_orders) {

    //   for ( let giftOrder of shipment.coupon_relation.gift_orders ) {
    //     giftOrder
    //       .set_quantity_and_price(giftOrder.quantity, giftOrder.price, null, giftOrder.cost, giftOrder.shipped_qty, false, this.CONSTANTS)
    //       .set_discount_amount(this.CONSTANTS)
    //       .set_total_price();

    //     noCouponCheckoutOrders = [ ...noCouponCheckoutOrders, giftOrder ];
    //   } 
    // }
 
    //已有優惠 且 優惠的滿額金額 比 目前要套用的優惠滿額金額還要高 就不繼續執行
    // if (shipment.coupon_relation && (shipment.coupon_relation?.coupon?.limit_amount ?? 0) >= this.limit_amount) {
    
    //已有優惠 就跳出 不繼續執行
    if ( shipment.coupon_relation ) {
      return shipment;
    }
 
    if (!couponRelation) {
      couponRelation = new CouponRelationModel()
        .set_import({ "CONSTANTS" : this.CONSTANTS })
        .set_coupon_relation_columns(this)  
        .set_buy_and_gift_count(this);  
    }

    //符合滿額優惠金額
    if ( this.limit_amount && orderAmount >= this.limit_amount ) {
 
      //用來計算目前贈品的價值 以方便判斷是否滿額優惠最低金額會被影響
      let sumOfGiftOrdersPrice = 0;

      for ( let order of noCouponCheckoutOrders ) {
 
        //訂單符合贈品要求
        if( 
          couponRelation.coupon.coupon_product_relations
                .filter(c => c.coupon_type == this.CONSTANTS.CouponProductRelation.TYPE["贈品"] )
                .filter(c => c.product_id == order.product_id && c.product_model_id == order.product_model_id ).length > 0
          ) 
        {

          //目前 gift_orders的 quantity 總和
          let giftOrdersQuantitySum = couponRelation.gift_orders_quantity_sum();

          // //要分割出多少數量 
          let requestQuantity = ( couponRelation.gift_count - giftOrdersQuantitySum ); 


          // 分割出訂單作為贈品 因為贈品價格會變成0 等於會減少訂單總金額 反而導致不滿足優惠的最低金額 
          // 這邊要做計算是否會導致上述狀況 應該要減少多少才不會導致優惠的最低金額不滿足
          // let requestQuantity = this.shipmentCouponRequestQuantity(couponRelation.gift_count, giftOrdersQuantitySum, order.price_if_not_gift, this.limit_amount, orderAmount); 
 
          if (requestQuantity && order.quantity ) { 

            // 如果有紀錄過還原資料 就不覆蓋 保留最初的quantity 
            if (!rollbackData[order.uuid]) {
              rollbackData[order.uuid] = { 
                order: order,
                quantity_was: order.quantity*1,
                price_was: order.price*1,
                cost_was: order.cost,
                shipped_qty_was: order.shipped_qty,
              }
            } 

            //把order的數量分配給 gift_order 有可能剛好分割完 order.quantity 會變成 0
            let giftOrder = order.split_gift_order(this.CONSTANTS, ( requestQuantity > order.quantity ? order.quantity : requestQuantity ) , order.created_by);
             
            sumOfGiftOrdersPrice = sumOfGiftOrdersPrice + giftOrder.price_if_not_gift*giftOrder.quantity;

            giftOrder.quantity_was = rollbackData[order.uuid].quantity_was;
            giftOrder.price_was = rollbackData[order.uuid].price_was;
            giftOrder.cost_was = rollbackData[order.uuid].cost_was;
            giftOrder.shipped_qty_was = rollbackData[order.uuid].shipped_qty_was; 

            //各種關聯設定 
            giftOrder.product = order.product; 
            couponRelation.gift_orders = couponRelation.gift_orders ?? []; 
            couponRelation.gift_orders.push(giftOrder); 
            couponRelation.set_whose_buy(giftOrder.user_id);
            giftOrder.coupon_relation = couponRelation;

            tempSplitOrders = [...tempSplitOrders, giftOrder];  
            // orderQuantityChanged = true; 
          } 
        } 
      }
 
      if ( (orderAmount - sumOfGiftOrdersPrice) >= this.limit_amount && couponRelation.coupon_can_be_used()) {
        shipment.coupon_relation = couponRelation;
        newSplitOrders = tempSplitOrders;
      } else {
        //如果沒湊成優惠條件 要還原 
        for ( let key in rollbackData ) { 
          let data = rollbackData[key];

          // quantity 還原
          data.order
            .set_quantity_and_price(data.quantity_was, data.shipped_qty_for_shipment_was, data.price_was, null, data.cost_was, data.shipped_qty_was, this.CONSTANTS)
            .set_discount_amount(this.CONSTANTS)
            .set_total_price();
            
          // coupon?.insufficient_buy_products?.[item?.uuid]


          // console.log(` ${ Utility.invert(this.CONSTANTS.Coupon.TYPE_CODE)[this.type_code] }(${ this.name }): 還缺少O件商品 可滿足此優惠 `);

        } 

        couponRelation.clear_buy_order_and_gift_order_bind();
        rollbackData = {};
        couponRelation.buy_order = null;
        couponRelation.gift_orders = null; 
      }  
    }
    return newSplitOrders;
  } 


  simulate_orders_apply_coupon(ordersQuantityWas, orders, couponRelation = null ,allSplitOrders = [], tempSplitOrders = [], rollbackData = {}, index = 0) {
    let newSplitOrders = [];
    let orderQuantityChanged = false;
 
    if (!couponRelation) {
      couponRelation = new CouponRelationModel()
        .set_import({ "CONSTANTS" : this.CONSTANTS })
        .set_coupon_relation_columns(this)  
        .set_buy_and_gift_count(this); 
    } 

    let order = orders[index];
     
    //如果沒有訂單可以處理了 就紀錄還缺少多少(如果沒湊齊) 就整理資料回傳 
    if (!order) {

      //如果沒湊成優惠條件 要還原 
      if ( !couponRelation.coupon_can_be_used() ) {

        for ( let key in rollbackData ) { 
          let data = rollbackData[key];

          // quantity 還原
          data.order
            .set_quantity_and_price(data.quantity_was, data.shipped_qty_for_shipment_was, data.order.price, null, data.order.cost, data.order.shipped_qty, this.CONSTANTS)
            .set_discount_amount(this.CONSTANTS)
            .set_total_price();
            
        } 

        couponRelation.clear_buy_order_and_gift_order_bind();
        rollbackData = {};
        couponRelation.buy_order = null;
        couponRelation.gift_orders = null;
      } 
  
      for ( let order of orders ) {  
        // 數量改了 要重新調整商品提醒
        if (ordersQuantityWas[order.uuid] != order.quantity) {
          for ( let activeCoupon of order.active_coupons ) {  
            activeCoupon 
              .order_coupon_buy_product_validate(order); 
          }           
        }
      }

      return allSplitOrders;
    }
  

    //如果order quantity 被分配完了 或是 已經湊齊條件 就下一個
    if (order.quantity == 0 ) {
      return this.simulate_orders_apply_coupon(ordersQuantityWas, orders, couponRelation, allSplitOrders, tempSplitOrders, rollbackData, index + 1); 
    } 

    // coupon_type 如果有值 就等於優惠商品或是贈品 則不處理這種訂單 跳下一個
    if ( order.coupon_type ) {
      return this.simulate_orders_apply_coupon(ordersQuantityWas, orders, couponRelation, allSplitOrders, tempSplitOrders, rollbackData, index + 1); 
    } 
 
    if (!order.uuid) {
      order.set_uuid();
    }

    if ([this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"], this.CONSTANTS.Coupon.TYPE_CODE["買多折扣"]].includes(couponRelation.type_code*1)) {
      if ( 
          // (!couponRelation.buy_order || couponRelation.buy_order.quantity < couponRelation.buy_count ) && 
          !couponRelation.buy_order && 

          couponRelation.coupon.coupon_product_relations
          .filter(c => c.coupon_type == this.CONSTANTS.CouponProductRelation.TYPE["優惠商品"] )
          .filter(c => c.product_id == order.product_id ).length > 0
         ) {

        //要分割出多少數量
        let requestQuantity = ( couponRelation.buy_count - ( couponRelation?.buy_order?.quantity ?? 0 ) );
  
        // 如果有紀錄過還原資料 就不覆蓋 保留最初的quantity 
        if (!rollbackData[order.uuid]) {
          rollbackData[order.uuid] = { 
            order: order,
            quantity_was: order.quantity,
          }
        } 
  
        //如果目前的數量 大於 優惠需要的數量 就進行配對
        if ( order.quantity >= requestQuantity ) {

          //如果已經有buy_order 就直接改數量就好 沒有buy_order 就要create一個新的
          if (couponRelation.buy_order) { 
            couponRelation.buy_order
              .set_quantity_and_price(couponRelation.buy_order.quantity + requestQuantity, couponRelation.buy_order.shipped_qty_for_shipment, couponRelation.buy_order.price, couponRelation.buy_order.coupon_type, couponRelation.buy_order.cost, couponRelation.buy_order.shipped_qty, this.CONSTANTS)
              .set_discount_amount(this.CONSTANTS, couponRelation)
              .set_total_price(); 

            order
              .set_quantity_and_price(order.quantity - requestQuantity, order.shipped_qty_for_shipment, order.price, order.coupon_type, order.cost, order.shipped_qty, this.CONSTANTS)
              .set_total_price(); 
          } else {   
            //把order的數量分配給 buy_order 有可能剛好分割完 order.quantity 會變成 0
            let buyOrder = order.split_buy_order(this.CONSTANTS, ( requestQuantity > order.quantity ? order.quantity : requestQuantity ) , order.created_by);

            //各種關聯設定
            buyOrder.product = order.product;
            buyOrder.coupon_relation = couponRelation;
            couponRelation.buy_order = buyOrder;

            // 因為 coupon_relation 連上了 所要  buyOrder 要重新算折扣
            buyOrder
              .set_discount_amount(this.CONSTANTS, couponRelation)
              .set_total_price(); 

            //設定 coupon_relation
            buyOrder.coupon_relation
            .set_shipment_discount_amount(couponRelation.coupon, buyOrder.total_price, this.CONSTANTS)
            .set_whose_buy(buyOrder.user_id) 
   
            newSplitOrders = [...newSplitOrders, buyOrder];
          } 
         
          orderQuantityChanged = true;
        } 
      }  


      if( 
        couponRelation.coupon.coupon_product_relations
        .filter(c => c.coupon_type == this.CONSTANTS.CouponProductRelation.TYPE["贈品"] )
        .filter(c => c.product_id == order.product_id && c.product_model_id == order.product_model_id ).length > 0
      ) {

        //目前 gift_orders的 quantity 總和
        let giftOrdersQuantitySum = couponRelation.gift_orders_quantity_sum();

        //要分割出多少數量 
        let requestQuantity = ( couponRelation.gift_count - giftOrdersQuantitySum );
        let meetTheQuantity = ( requestQuantity > order.quantity ? false : true );

        if (requestQuantity && order.quantity ) { 

          // 如果有紀錄過還原資料 就不覆蓋 保留最初的quantity 
          if (!rollbackData[order.uuid]) {
            rollbackData[order.uuid] = { 
              order: order,
              quantity_was: order.quantity,
            }
          } 

          //把order的數量分配給 gift_order 有可能剛好分割完 order.quantity 會變成 0
          let giftOrder = order.split_gift_order(this.CONSTANTS, ( requestQuantity > order.quantity ? order.quantity : requestQuantity ) , order.created_by);
           

          //各種關聯設定 
          giftOrder.product = order.product; 
          couponRelation.gift_orders = couponRelation.gift_orders ?? []; 
          couponRelation.gift_orders.push(giftOrder);
          giftOrder.coupon_relation = couponRelation;

          newSplitOrders = [...newSplitOrders, giftOrder];  
          orderQuantityChanged = true;

        } 
      }
    }

    if (this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"] == couponRelation.type_code) {
      let giftOrdersQuantitySum = couponRelation.gift_orders_quantity_sum();
      let requestQuantity = ( couponRelation.gift_count - giftOrdersQuantitySum );
 
      if (requestQuantity == 0) {
        this.set_insufficient_gift_products(order.uuid, null);
      } else if( this.gift_product ) { 
        let productModelIds = this.coupon_product_relations.map(c => c.product_model_id).filter(m => m);
        let allCouponProductRelationProductModelNames = {};

        for (let couponProductRelation of this.coupon_product_relations ) {
          let productModels = ( couponProductRelation?.product?.product_models ?? [] );
          for (let productModel of productModels ) {
             allCouponProductRelationProductModelNames[productModel.id] = productModel.name;
          }
        } 

        let productModelNames = productModelIds.map( id => allCouponProductRelationProductModelNames[id] );

        this.set_insufficient_gift_products(order.uuid, "quantity", requestQuantity);
        this.set_insufficient_gift_products(order.uuid, "product_id", order.product_id);
        this.set_insufficient_gift_products(order.uuid, "product_name", order.product.name);  
        this.set_insufficient_gift_products(order.uuid, "product_model_ids", productModelIds);  
        this.set_insufficient_gift_products(order.uuid, "product_model_names", productModelNames);  
      } 
    }

    tempSplitOrders = [ ...tempSplitOrders, ...newSplitOrders ]
  
    if ( couponRelation.coupon_can_be_used() ) {
      allSplitOrders = [ ...allSplitOrders, ...tempSplitOrders ];  
      return this.simulate_orders_apply_coupon(ordersQuantityWas, orders, null, allSplitOrders, [], {}, 0); 
    }
 
    //如果數量有變更 可能是buy_orders 條件已滿 就重頭再跑檢查一次 
    if (orderQuantityChanged) { 
      return this.simulate_orders_apply_coupon(ordersQuantityWas, orders, couponRelation, allSplitOrders, tempSplitOrders, rollbackData, 0);
    }

    return this.simulate_orders_apply_coupon(ordersQuantityWas, orders, couponRelation, allSplitOrders, tempSplitOrders, rollbackData, index + 1);
  }
 
  async load_gift_and_buy_product(productModels = []) {
    let productIds = [this.buy_product_id, this.gift_product_id];
    productIds = productIds.filter(p => p);
    // console.log('load_gift_and_buy_product');
    if (productIds.length != 0) { 
      // let productModels = productIds.length == 0 ? [] : (await new ProductModel().where("id in ?",[productIds]).get_datas()).rows.to_products_class();
      
      productModels = productModels.to_products_class();

      if (this.buy_product_id) {
        this.load_buy_product(productModels); 
      }
      if (this.gift_product_id) {
        this.load_gift_product(productModels); 
      }   
    } 
    return this;  
  }
  
  load_coupon_product_relations(coupon_product_relations) { 
    if( coupon_product_relations ){
      this.coupon_product_relations = coupon_product_relations.filter(p=>(p.coupon_id == this.id && p.coupon_id ));  
    } 
    return this;
  } 

  batch_load_coupon_product_relations_and_related_product(params) {
    let {
      CONSTANTS, 
      coupons, 
      coupon_product_relations, 
      products,   
      product_models  
    } = params;

    let couponModels = coupons.to_coupons_class();
    // let buyProductIds = couponModels.map(c => c.buy_product_id).unique().filter(i => i ); 
    // let giftProductIds = couponModels.map(c => c.gift_product_id).unique().filter(i => i ); 
    // let productIds = [...buyProductIds, ...giftProductIds].unique();

    // let couponProductRelationModels = productIds.length == 0 ? [] : (await (new CouponProductRelationModel()).where(" product_id in ? ",[productIds]).get_datas()).rows.to_coupon_product_relations_class();
    // let productModels = productIds.length == 0 ? [] : (await (new ProductModel()).where(" id in ? ",[productIds]).get_datas()).rows.to_products_class();
    // let productModelClasses = productIds.length == 0 ? [] : (await (new ProductModelClass()).where(" product_id in ? ",[productIds]).get_datas()).rows.to_product_models_class();
 
    let couponProductRelationModels = coupon_product_relations?.to_coupon_product_relations_class(); //to_coupon_product_relations_class
    let productModels = products?.to_products_class(); //to_products_class
    let productModelClasses = product_models?.to_product_models_class(); //to_product_models_class

    for (let couponModel of couponModels) {
      couponModel.load_coupon_product_relations_and_related_product({ 
        CONSTANTS : CONSTANTS,
        coupon_product_relations : couponProductRelationModels,
        products : productModels,
        productModels : productModelClasses 
      }); 
    }
    return couponModels;
  } 
 
  load_coupon_product_relations_and_related_product(params) {
    let { CONSTANTS, coupon_product_relations, products = null, productModels = null } = params; 
    
    this.load_coupon_product_relations(coupon_product_relations);

    if (products) {
      products = products.to_products_class();
    }

    if (productModels) {
      productModels = productModels.to_product_models_class();
    }

    this.coupon_product_relations.to_coupon_product_relations_class();

    let productIds = [this.buy_product_id, this.gift_product_id].unique().filter(i => i ); 
      
    for ( let productModel of productModels ) {
      productModel.load_coupon_product_relations(this.coupon_product_relations);
    }

    if (this.buy_product_id) { 
      this.load_buy_product(products);
    }
    if (this.gift_product_id) { 
      this.load_gift_product(products);
    }
  
    this.coupon_product_relations = this.coupon_product_relations.to_coupon_product_relations_class();
    for( let couponProductRelationModel of this.coupon_product_relations ) {
      couponProductRelationModel.load_product(products);  
      couponProductRelationModel.product.load_product_models(productModels);
    }
  
    return this;
  } 

  set_insufficient_gift_products(orderUUId, key = null, value = null){
    if ( orderUUId && key ) { 
      this.insufficient_gift_products = this.insufficient_gift_products ?? {};
      this.insufficient_gift_products[orderUUId] = this?.insufficient_gift_products?.[orderUUId] ?? {};  
      this.insufficient_gift_products[orderUUId][key] = value;  
    } else if( orderUUId && !key ) {  
      this.insufficient_gift_products = this.insufficient_gift_products ?? {};
      this.insufficient_gift_products[orderUUId] = {}; 
    } else if( !orderUUId && !key ) {  
      this.insufficient_gift_products = {}; 
    }
  }

  set_used_desc(usedDesc){
    this.used_desc = usedDesc;
    return this;  
  }
 
  set_site(siteId){
    this.site_id = siteId;
    return this;  
  }
 
  set_type_code(typeCode){
    this.type_code = typeCode;
    return this;  
  } 
 
  set_name(name){
    this.name = name;
    return this;  
  } 

  set_disabled(disabled){
    this.disabled = disabled;
    return this;  
  } 
 
  set_discount(discountType, discount, CONSTANTS){ 
    if (CONSTANTS.Coupon.DISCOUNT_TYPE["固定金額"] == discountType || CONSTANTS.Coupon.DISCOUNT_TYPE["比率"] == discountType ) {
      if (!discount) {
        alert('set_discount discount 必須有值');
        return;
      }
      if ( isNaN(discount*1) ) {
        alert('set_discount discount 必須是數字');
        return;
      }
    }
  
    this.discount = discount;
    this.discount_type = discountType;
    return this;  
  } 
  
  set_time_period(startTime, endTime){
    this.start_time = startTime;
    this.end_time = endTime;
    return this;  
  } 

  set_limit(limitAmount, limitedCount, limitUserUsedCount){
    this.limit_amount = limitAmount ? limitAmount : 0;
    this.limited_count = limitedCount;
    this.limit_user_used_count = limitUserUsedCount;
    return this;  
  } 

  set_white_list(limitUsed, couponLimits){ 
    if (limitUsed && couponLimits.length == 0) {
      alert('set_white_list 沒有設定 couponLimits');
      return;
    }
 
    //如果 限制使用對象 = false, 限制條件就要刪除
    if( !limitUsed ) {
      couponLimits = couponLimits.filter(c => c.id);
      for(const couponLimit of couponLimits){
        couponLimit["destroy"] = true;
      }
    }

    this.coupon_limits = couponLimits;
    this.limit_used = limitUsed;  
    return this;  
  } 
 
  set_buy_product(buyProductId, buyCount, CONSTANTS){
    let that = this;

    if (buyCount <= 0) {
      alert(' buyCount 必須大於 0');
      return;
    }

    this.buy_product_id = buyProductId; 
    this.buy_count = ( buyCount ?? 1 ); 

    if (buyProductId) {
      this.coupon_product_relations = this.coupon_product_relations ?? [];
      let couponProductRelationModel = new CouponProductRelationModel();
      
      couponProductRelationModel
        .set_coupon_product_relation(that.id, buyProductId, null, CONSTANTS.CouponProductRelation.TYPE["優惠商品"]);

      this.coupon_product_relations.push(couponProductRelationModel);  
    }
 
    return this;  
  } 
 
  set_gift_product(gift, giftProductId, giftProductModelIds, giftCount, CONSTANTS) {
    let that = this;

    if ( gift && giftProductId ) {
      alert(` gift 和 giftProductId 不可同時存在`);
    }

    if (giftCount <= 0) {
      alert(' giftCount 必須大於 0');
      return;
    }

    this.gift = gift; 
    this.gift_product_id = giftProductId; 
    this.gift_count = ( giftCount ?? 1 ); 

    if (giftProductId) { 
      this.coupon_product_relations = this.coupon_product_relations ?? [];
      let couponProductRelationModel = new CouponProductRelationModel(); 

      couponProductRelationModel
        .set_coupon_product_relation(that.id, giftProductId, null, CONSTANTS.CouponProductRelation.TYPE["贈品"]);

      this.coupon_product_relations.push(couponProductRelationModel);  

      for ( let giftProductModelId of giftProductModelIds ) {
        let giftCouponProductRelationModel = new CouponProductRelationModel();

        giftCouponProductRelationModel
          .set_coupon_product_relation(that.id, giftProductId, giftProductModelId, CONSTANTS.CouponProductRelation.TYPE["贈品"]);

        this.coupon_product_relations.push(giftCouponProductRelationModel);       
      }  
    }

    return this;  
  }  
 
  set_editor(editUserId) {  
    this.created_by = this.created_by ? this.created_by : editUserId;
    this.updated_by = editUserId; 
    return this;       
  }
 
  random_data(){ 
    let data = { 
      id : Math.floor(Math.random() * 1000000),
      site_id : Math.floor(Math.random() * 1000000),
      type_code : Math.floor(Math.random() * 1000000),
      name : Math.random().toString(36).substring(7),
      code : Math.random().toString(36).substring(7),
      discount_type : Math.floor(Math.random() * 3),
      discount : (Math.random() * 100).toFixed(2),
      limit_amount : (Math.random() * 100).toFixed(2),
      limited_count : Math.floor(Math.random() * 100),
      start_time : new Date(),
      end_time : new Date(),
      disabled : Math.random() >= 0.5,
      used_count : Math.floor(Math.random() * 100),
      used_desc : Math.random().toString(36).substring(7),
      limit_used : Math.random() >= 0.5,
      gift : Math.random().toString(36).substring(7),
      limit_user_used_count : 2,
      created_by : Math.floor(Math.random() * 1000000),
      updated_by : Math.floor(Math.random() * 1000000),
      created_at : new Date(),
      updated_at : new Date()
    } 

    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }

  to_json(whitelist){ 
    let data = whitelist ? whitelist : ["id","site_id","type_code","name","code","discount_type","discount","limit_amount","limited_count","start_time","end_time","disabled","used_count","used_desc","limit_used","gift","created_by","updated_by","created_at","updated_at","buy_product_id","gift_product_id","buy_count","gift_count","limit_user_used_count"] 
    return super.to_json(data);
  }

  active(){
    let nowTime = new Date();
    this
    .where("start_time <= ?",[nowTime.toString().toYYYYMMDD()])
    .where("end_time >= ?",[nowTime.toString().toYYYYMMDD()])
    .where("disabled",false);

    return this;
  }
 
  product_coupons(CONSTANTS){ 
    this.where("type_code in ?",[[CONSTANTS.Coupon.TYPE_CODE["買多折扣"], CONSTANTS.Coupon.TYPE_CODE["買多贈品"]]]) 
    return this;
  }
 
  amount_coupons(CONSTANTS){ 
    this.where("type_code in ?",[[CONSTANTS.Coupon.TYPE_CODE["滿額折扣"], CONSTANTS.Coupon.TYPE_CODE["滿額贈品"]]]) 
    return this;
  }

  async destroy(options = {}){
    let that = this;  
    let destroyCouponLimitSql = [];
    let destroyCouponProductRelationSql = [];
    let success = true;
    let errors = [];
 
    if (options["saveRelationTable"]) { 
      let destroyIds = (await this.get_datas()).rows.map(d=>d.id); 
      destroyCouponLimitSql = destroyIds.length == 0 ? [] : (new CouponLimitModel().where("coupon_id in ?",[destroyIds]).destroy_sql());
      destroyCouponProductRelationSql = destroyIds.length == 0 ? [] : (new CouponProductRelationModel().where("coupon_id in ?",[destroyIds]).destroy_sql());
    }
 
    let sqlObjs = [{sql: "BEGIN"}, 
      ...super.destroy_sql(),
      ...destroyCouponLimitSql,
      ...destroyCouponProductRelationSql,
      // { 
      //   sql: "SELECT * FROM member_levels WHERE id=0", 
      //   params: [],
      //   validate: function(currentResult, allResults){ 
      //     let success = false; 
      //     return success;
      //   },
      //   errorMessage: "就是錯" 
      // }, 
    {sql: "END"}];
 
    let rowCount = 0; 
    await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => { 
      rowCount = results?.datasByTableName[that.tableName]?.rowCount; 
    }).catch(async err => { 
      if( err?.message ) { 
        success = false;
        errors.push(err?.message);
        // alert('發生錯誤', err.message, 'error'); 
      } 
      // throw err;
    }); 
  
    return {
      success: success,
      errors: errors,
      rowCount: rowCount 
    };
  }
 
  create_gift_order( buyOrder, createdBy, CONSTANTS){
    let that = this;
    let giftCount = this.gift_count ? this.gift_count : 1;  
    let buyQuantity = giftCount;
    let success = true;
    let errors = [];
    let giftOrders = [];

    if ( this.gift_product ) { 
      let result = recursionGiftOrders({
        giftProduct: this.gift_product, 
        giftOrders: giftOrders, 
        buyOrder: buyOrder,
        giftCount: giftCount,  
        createdBy: createdBy,
        CONSTANTS: CONSTANTS        
      });

      giftOrders = result["giftOrders"];
      giftCount = result["giftCount"]; 
    } 

    //如果 giftCount 沒有歸0 代表庫存不夠用
    if (giftCount != 0) {
      giftOrders = [];
      success = false;
      errors.push("贈品庫存不足");
    }  

    //用遞迴處理贈品判斷
    function recursionGiftOrders(params){
      let {
        giftProduct, 
        giftOrders, 
        buyOrder,
        giftCount,  
        createdBy,
        CONSTANTS,
        modelsIndex = -1
      } = params;

      let giftProductModel = null;
      if (giftProduct.product_models.length > 0) {
        modelsIndex = modelsIndex + 1;
        giftProductModel = giftProduct.product_models[modelsIndex];
      }

      if (giftCount == 0) {
        return {};
      }

      //沒庫存就直接跳過
      if( giftProduct.stock_qty === 0 || giftProduct.stock_qty === "0" ){
        return {};
      } 
      if (giftProductModel) {
        if ( giftProductModel.stock_qty === 0 || giftProductModel.stock_qty === "0"  ) {
          recursionGiftOrders({
            ...params,
            modelsIndex: modelsIndex        
          });
        }

        if (!that.allowed_as_a_gift(giftProductModel.product_id, giftProductModel.id)) {
          recursionGiftOrders({
            ...params,
            modelsIndex: modelsIndex        
          });
        }
      } 
  
      let result = pushToGiftOrders(giftOrders, buyOrder, giftProduct, giftProductModel, giftCount, createdBy, giftProduct.price, giftProduct.cost, CONSTANTS);
      giftOrders = result["giftOrders"];
      giftCount = result["giftCount"];

      //做完就return
      if (modelsIndex = -1 || giftProduct.product_models.length == (modelsIndex + 1) ) {
        return result;
      } else {
        recursionGiftOrders({
          ...params,
          modelsIndex: modelsIndex        
        });
      }
  
    }
  
    function pushToGiftOrders(giftOrders, buyOrder, giftProduct, giftProductModel, giftCount, createdBy, giftProductPrice, giftProductCost, CONSTANTS) {
      let quantity = giftCount; 
      let giftProductId = giftProduct?.id;
      let giftProductModelId = giftProductModel?.id;
      let giftProductModelName = giftProductModel?.name;
  
      //如果庫存不足就先扣除部分
      let productOrModel = giftProductModel ? giftProductModel : giftProduct;

      if ( productOrModel.stock_qty && productOrModel.stock_qty < quantity ) { 
        quantity = productOrModel.stock_qty;
        giftCount = giftCount - quantity; 
      } else { 
        quantity = giftCount;
        giftCount = 0;
      }

      let giftOrder = new OrderModel().set_import({ CONSTANTS: CONSTANTS }); 
      giftOrder 
        .set_site(buyOrder.site_id) 
        .set_status(buyOrder.status, createdBy) 
        .set_buyer_related_information(buyOrder.user_id, buyOrder.member_level_id, buyOrder.buyer_name, buyOrder.buyer_mobile)
        .set_product_related_information(giftProductId, giftProductModelId, giftProductModelName) 
        .set_constants(CONSTANTS.Order.SALE_TYPE["贈品"], CONSTANTS.Order.SOURCE["POS機"])
        .set_editor(createdBy) 
        .set_quantity_and_price(quantity, null, giftProductPrice, CONSTANTS.CouponProductRelation.TYPE["贈品"], giftProductCost, 0, CONSTANTS)
        .set_discount_amount(CONSTANTS)
        .set_total_price(true);

      giftOrders.push(giftOrder); 
      return {
        giftOrders: giftOrders,
        giftCount: giftCount
      };
    }
 
    return {
      success : success,
      errors : errors,
      orders : giftOrders
    }; 
  }
  
  async is_first_use_this_coupon(userId){
    let isFirstUseThisCoupon = false;
    if (!this.id) { 
      alert(`請先設定id`);
      return false;
    }

    if (!userId) { 
      alert(`沒有傳入userId`);
      return false;
    }    
 
    let sqlObjs = [{sql: "BEGIN"}, 
      { 
        tableName: "coupon_relations",
        sql: "SELECT * FROM coupon_relations INNER JOIN shipments ON coupon_relations.shipment_id = shipments.id WHERE shipments.user_id = $1 AND coupon_id = $2", 
        params: [ userId, this.id ] 
      }, 
      {sql: "END"}];
 
    await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => { 
      isFirstUseThisCoupon = results?.datasByTableName?.coupon_relations?.rows.length == 0; 
    }).catch(async err => { 
      if( err?.message ){ 
        alert(`發生錯誤 ${ err.message }`);
        await Swal.fire('發生錯誤', err.message, 'error'); 
      } 
      throw err;
    }); 
 
    return isFirstUseThisCoupon;
  }

  // async get_validates(options) { 
  //   let that = this;

  //   let { orders, CONSTANTS, userId, checkedCoupons } = options;
  //   checkedCoupons = checkedCoupons ? checkedCoupons : [];

  //   if (!this.coupon_limits) { 
  //     alert(`請先load coupon_limits`);
  //     return [];
  //   }

  //   if(!CONSTANTS){
  //     alert(`沒有傳入 get_validates CONSTANTS參數`);
  //     return [];
  //   }

  //   if (!orders) {  
  //     return [];
  //   }

  //   if (!this.site_id) { 
  //     alert(`沒有設定site_id`);
  //     return [];
  //   }

  //   if (!userId) { 
  //     return [];
  //   }    

  //   if ( !Array.isArray(options.allProductTagRelations) ) {
  //     alert('options.allProductTagRelations 尚未定義');
  //     return [];
  //   }
 
  //   if ( options.isFirstBuy == undefined ) {
  //     alert('options.isFirstBuy 尚未定義');
  //     return [];
  //   }

  //   if ( options.currentMembership == undefined ) {
  //     alert('options.currentMembership 尚未定義');
  //     return [];
  //   }

  //   if( !this.site ){ 
  //     await this.load_site();
  //   } 

  //   let productTagCouponLimit = this.coupon_limits.filter(function(v,i){ return v.resource_type == CONSTANTS.CouponLimit.RESOURCE_TYPE["商品標籤"] });
  //   let currentMembership = options.currentMembership;  
  //   let memberLevelId = currentMembership["member_level_id"]; 
  //   let isFirstBuy = options.isFirstBuy; 

  //   // # 先把商品標籤驗證放到前面  判斷有哪些訂單可以使用優惠
  //   let couponOrderIds = []; 
  //   let allOrderIds = orders.map(o => o.id ); 
  //   let productTagIdsThatCanUseCoupon = [];

  //   for ( const order of orders ) {
  //     let productTagIds = options.allProductTagRelations.filter(r=> r.product_id == order.product_id).map(o=>o.product_tag_id);
  //     let couponLimitProductTagIds = productTagCouponLimit.map((limit)=>limit.resource_id);

  //     // 商品標籤 與 限制標籤 是否有交集, 有交集算是符合條件
  //     let intersection = productTagIds.filter(function(n) {
  //       return couponLimitProductTagIds.indexOf(n) !== -1;
  //     });

  //     if( intersection.length > 0 ){
  //       couponOrderIds.push(order.id);
  //       productTagIdsThatCanUseCoupon = [...productTagIdsThatCanUseCoupon, ...productTagIds ]; 
  //     } 
  //   }
 
  //   // uniq Array
  //   productTagIdsThatCanUseCoupon = Array.from(new Set(productTagIdsThatCanUseCoupon));

  //   //沒有商品標籤限制 則 couponOrderIds 就是 allOrderIds
  //   if( productTagCouponLimit.length == 0 ){
  //     couponOrderIds = allOrderIds;
  //   } 
 
  //   // // 根據可以使用優惠的訂單 算出應付的款項 再根據應付的款項算出折扣金額
  //   // let couponOrderAmount = 0;

  //   //從 couponOrderIds 選出價格最高的來做為優惠的訂單
  //   // let couponOrderId = null;
  //   // let maxTotalPriceOrder["total_price"] = 0;
  //   let maxTotalPriceOrder = { total_price : 0, id: null };
  //   for (const order of orders.filter(function(o,i){ return couponOrderIds.includes(o["id"]) })) {
  //     let totalPrice = 0;

  //     if( order.shipped_qty_for_shipment ){
  //       totalPrice = order.shipped_qty_for_shipment*(order.package_qty || 1)*order.price;
  //     } else {
  //       totalPrice = order.quantity*order.price;
  //     }
       
  //     // couponOrderAmount = couponOrderAmount + totalPrice;
  //     if (totalPrice > maxTotalPriceOrder["total_price"]) {
  //       maxTotalPriceOrder = {
  //         id: order.id,
  //         total_price: totalPrice
  //       }; 
  //     }
  //   }
 
  //   let totalPriceTax = that.site.tax_included ? Math.round(maxTotalPriceOrder["total_price"] - (maxTotalPriceOrder["total_price"] / (1 + that.site.tax_rate/100))) : Math.round(maxTotalPriceOrder["total_price"] * (that.site.tax_rate / 100));
  //   let needToPayAmount = maxTotalPriceOrder["total_price"] + ( that.site.tax_included ? 0 : totalPriceTax ); 
 
  //   let isFirstUsedCoupon = await this.is_first_use_this_coupon(userId); 
 
  //   let validate1 = this.validate_coupon_limit(CONSTANTS, CONSTANTS.CouponLimit.RESOURCE_TYPE["會員"]);
  //   let validate2 = this.validate_coupon_limit(CONSTANTS, CONSTANTS.CouponLimit.RESOURCE_TYPE["會員等級"], {memberLevelId: memberLevelId});
  //   let validate3 = this.validate_coupon_limit(CONSTANTS, CONSTANTS.CouponLimit.RESOURCE_TYPE["商品標籤"], {orders: orders, allProductTagRelations : options.allProductTagRelations});
 
  //   this.validates = [ 
  //     { 
  //       name: `同樣類型優惠不可多選`, 
  //       validate: checkedCoupons.filter((c)=> ( c.id != this.id && c.type_code == this.type_code)).length <= 0,
  //       needToValidate: true 
  //     },
  //     {  
  //       name: `符合使用對象(會員)[${ validate1.limitString }]`, 
  //       validate: validate1.validate, 
  //       needToValidate: validate1.needToValidate 
  //     },
  //     { 
  //       name: `符合使用對象(會員等級)[${ validate2.limitString }]`, 
  //       validate: validate2.validate, 
  //       needToValidate: validate2.needToValidate
  //     },
  //     { 
  //       name: `符合使用對象(商品標籤)[${ validate3.limitString }]`, 
  //       validate: validate3.validate, 
  //       needToValidate: validate3.needToValidate
  //     },
  //     { 
  //       name: "符合折扣碼", 
  //       validate: this.codeValidate || options.checkedCoupons.filter(c=>c.id == that.id).length > 0, 
  //       needToValidate: CONSTANTS.Coupon.TYPE_CODE["折扣碼"] == this.type_code ? true : false, 
  //     },
  //     { 
  //       name: `符合使用金額限制[ ${ needToPayAmount } >= ${ this.limit_amount } ](${ this.limit_amount ?? ""})`, 
  //       validate: needToPayAmount >= this.limit_amount, 
  //       needToValidate: true 
  //     },
  //     { 
  //       name: `符合限制次數(${ this.limited_count ? ` ${this.limited_count} - ${ (this.used_count || 0) } ` : "無限制"})`, 
  //       validate: this.limited_count ? ( (this.limited_count - (this.used_count || 0))  > 0 ) : true,    
  //       needToValidate: CONSTANTS.Coupon.TYPE_CODE["首購優惠"] == this.type_code ? false : true 
  //     },
  //     { 
  //       name: `符合時間起迄( ${ this.start_time.toString().toYYYYMMDD() }~ ${ this.end_time.toString().toYYYYMMDD() } )`, 
  //       validate: this.start_time.toString().toYYYYMMDD() <= new Date().toString().toYYYYMMDD() && new Date().toString().toYYYYMMDD() <= this.end_time.toString().toYYYYMMDD(), 
  //       needToValidate: true 
  //     },
  //     { 
  //       name: "符合1個帳號只能使用1次", 
  //       validate: isFirstUsedCoupon, //這個只能在後端驗證 
  //       needToValidate: this.limit_user_used_once//CONSTANTS.Coupon.TYPE_CODE["首購優惠"] == this.type_code ? false : true 
  //     }, 
  //     { 
  //       name: "首次購買", 
  //       validate: isFirstBuy,
  //       needToValidate: CONSTANTS.Coupon.TYPE_CODE["首購優惠"] == this.type_code ? true : false 
  //     }  
  //   ]; 

  //   let allPass = true;

  //   for (const validate of this.validates) {
  //     if( validate.needToValidate && !validate.validate ){
  //       allPass = false;
  //     }
  //   }
 
  //   this.allPass = allPass;

  //   if( CONSTANTS.Coupon.DISCOUNT_TYPE["贈品"] == this.discount_type ){
  //     this.discountAmount = 0; 
  //   } else {
  //     this.discountAmount = ( CONSTANTS.Coupon.DISCOUNT_TYPE["比率"] == this.discount_type ? ( Math.round(needToPayAmount * this.discount / 100) ) : this.discount );  
  //   }

  //   return { 
  //     "validates": this.validates,
  //     "couponOrderIds": maxTotalPriceOrder.id ? [maxTotalPriceOrder.id] : [],
  //     "isFirstBuy": isFirstBuy,       
  //     "discountAmount": this.discountAmount,   
  //     "allPass" : allPass    
  //   }
  // }



  //======================================================


  get_discount_amount(needToPayAmount, CONSTANTS){
    let discount_amount = null;
    if( CONSTANTS.Coupon.DISCOUNT_TYPE["固定金額"] == this.discount_type ){
      discount_amount = this.discount; 
    } else if(CONSTANTS.Coupon.DISCOUNT_TYPE["比率"] == this.discount_type) {
      discount_amount = Math.round(needToPayAmount * this.discount / 100);  
    } else {
      alert(`發生錯誤 set_shipment_discount_amount coupon沒有 discount_type 或是 數值不正確`);
      return;
    }    

    if (!discount_amount && discount_amount != 0 ) {
      alert('couponmodel discount_amount 金額計算錯誤');
    }
    return discount_amount;
  } 

  cost_of_gift(CONSTANTS) {
    let cost = null; 
    if( CONSTANTS.Coupon.TYPE_CODE["買多贈品"] == this.type_code || CONSTANTS.Coupon.TYPE_CODE["滿額贈品"] == this.type_code){
      if (this.gift_product_id) {
        if (!this.gift_product ) {
          alert(`coupon cost_of_gift 要 load_gift_product `);
          return;
        }
        cost = ( this.gift_product.price ?? 0 ) * this.gift_count;
      } else if(this.gift) {
        cost = 0;
      }
    } else {
      alert(`發生錯誤 cost_of_gift 不是 買多贈品 或是 滿額贈品`);
      return;
    }
    return cost;
  }

  // validate(params){
  //   this.errors = [];

  //   switch (this.type_code) {
  //     case CONSTANTS.Coupon.TYPE_CODE["買多折扣"]: 
  //       if ( !params.orderModels || !params.memberLevelId ) {
  //         alert('params.orderModels 或是 params.memberLevelId 尚未定義');
  //         return false;
  //       } else { 
  //         validate1(params.orderModels, params.memberLevelId);
  //       } 
  //     　break;
  //     case CONSTANTS.Coupon.TYPE_CODE["買多贈品"]:
  //       validate2(orderModels, memberLevelId); 
  //     　break; 
  //     case CONSTANTS.Coupon.TYPE_CODE["滿額紅利(回饋儲值金)"]: 
  //       validate3(orderModels, memberLevelId); 
  //     　break;
  //     case CONSTANTS.Coupon.TYPE_CODE["滿額贈品"]: 
  //       validate4(orderModels, memberLevelId); 
  //     　break;
  //     case CONSTANTS.Coupon.TYPE_CODE["滿額折扣"]: 
  //       validate5(orderModels, memberLevelId); 
  //     　break; 
  //     default: 
  //   }
  //   return false;
  // }
  
  // validate1(orderModels, memberLevelId){ // "買多折扣" : 1
  //   let success = true;
  //   let errors = [];
  //   let couponAmount = 0;
  //   let noCouponProduct = {};


  //   // // 1個帳號能使用幾次
  //   // if (this.limit_user_used_count) {
  //   //   //判斷是否用過這個coupon
  //   //   // coupon_relations 考慮加上 user_id
  //   // }


  //   // 是否停用
 
  //   // 是否符合限制次數

  //   // 是否符合 使用開始時間 ~ 使用結束時間






  //   //根據 orderModels 統計目前可以被用於優惠的商品有哪些? 有多少?
  //   for (let orderModel of orderModels) {
 
  //     // let buyCount = orderModel?.coupon_relation?.buy_count ?? 0;
  //     // let giftCount = orderModel?.coupon_relation?.gift_count ?? 0;
  //     noCouponProduct[orderModel.product_id] = orderModel.quantity;

  //     let couponRelation = orderModel.coupon_relation;

  //     if (couponRelation == undefined) {
  //       alert('orderModel 請先 load_coupon_relation');
  //       return false;
  //     }

  //     if (couponRelation) {
  //       noCouponProduct[couponRelation.buy_product_id] = noCouponProduct[couponRelation.buy_product_id] ?? 0;
  //       noCouponProduct[couponRelation.gift_product_id] = noCouponProduct[couponRelation.gift_product_id] ?? 0;
  //       noCouponProduct[couponRelation.buy_product_id] -= ( couponRelation?.buy_count ?? 0 )*( couponRelation?.coupon_relation_used_count ?? 1 );
  //       noCouponProduct[couponRelation.gift_product_id] -= ( couponRelation?.gift_count ?? 0 )*( couponRelation?.coupon_relation_used_count ?? 1 );
  //     } 
  //   }
 
  //   // 選擇優惠商品 && 買多少商品才有優惠
  //   // 用 noCouponProduct 來判斷 是否符合 優惠商品 要求
  //   if(!this.auto_create_coupon_relations(orderModels)){
  //     console.log(this.couponRelationNotices.join(",")); 
  //   }
 

  //   // for (let orderModel of orderModels) {
      
  //   // }
   
  //   // 限制使用對象
  //   let validateMembership = this.validate_coupon_limit(CONSTANTS, CONSTANTS.CouponLimit.RESOURCE_TYPE["會員"]);
  //   let validateMemberLevel = this.validate_coupon_limit(CONSTANTS, CONSTANTS.CouponLimit.RESOURCE_TYPE["會員等級"], {memberLevelId: memberLevelId});
  //   // let validate3 = this.validate_coupon_limit(CONSTANTS, CONSTANTS.CouponLimit.RESOURCE_TYPE["商品標籤"], {orders: orders, allProductTagRelations : options.allProductTagRelations});
    
  //   if (!validateMembership.validate) {
  //     this.errors = ["有限制會員(${ validateMembership.limitString })"];
  //     return false;
  //   }
  //   if (!validateMemberLevel.validate) {
  //     this.errors = ["有限制會員等級(${ validateMembership.limitString })"];
  //     return false;
  //   }
 
  //   //折扣方式 比率或是固定金額 (算優惠金額)

  //   return true;
  // }
  // validate2(orders){ // "買多贈品" : 2, 

  // }
  // validate3(orders){ // "滿額紅利(回饋儲值金)" : 3,

  // }
  // validate4(orders){ // "滿額贈品" : 4,

  // }
  // validate5(orders){ // "滿額折扣" : 5

  // }   

  // auto_create_coupon_relations(orderModels, coupon){
  //   for(let orderModel of orderModels) {
  //     let couponRelation = new CouponRelationModel()
  //       .set_coupon_relation_columns(coupon) 
  //       .set_shipment_discount_amount(coupon, needToPayAmount, CONSTANTS)
  //       .set_whose_buy(orderModel.user_id)
  //       .set_buy_order()
  //       .set_gift_order();
 
  //   }
  //   return orderModels;
  // }

  //根據傳進來的優惠以及目前結帳中的訂單 過濾出目前可以被使用的優惠
  // filter_coupon(coupons, orders, memberLevelId) { 
  //   let canBeUsedCoupon = [];
  //   let canNotBeUsedCoupons = []; 
  //   let couponModels = coupons.to_coupons_class();
  //   for ( let couponModel of couponModels) { 
  //     if (couponModel.validate({ orderModels: orders.to_orders_class(), memberLevelId: memberLevelId })) {
  //       canBeUsedCoupon.push(couponModel);
  //     } else {
  //       canNotBeUsedCoupons.push(couponModel); 
  //     }
  //   } 
  //   return {
  //     "canBeUsedCoupons": canBeUsedCoupon,
  //     "canNotBeUsedCoupons": canNotBeUsedCoupons,
  //   };
  // }  

  get_best_coupon(coupons){
    return {};
  } 
 
  order_coupon_buy_product_validate(targetOrder) {
    let success = true; 
    this.errorMessages = [];
    this.noticeMessages = [];
    this.insufficient_buy_products = this.insufficient_buy_products ?? {};

    this.insufficient_buy_products[targetOrder.uuid] = null;

    if (!targetOrder.quantity && targetOrder.quantity != 0) {
      alert('order_coupon_buy_product_validate 沒有定義 targetOrder.quantity');
      return this;
    }

    let quantity = targetOrder.quantity ?? 0 ;
 
    if (!this.CONSTANTS) {
      alert('order_coupon_buy_product_validate 沒定義 this.CONSTANTS');
      return false;
    }
    if (this.buy_product_id != targetOrder.product_id) {
      alert('order_coupon_buy_product_validate 的 targetOrder 的 this.buy_product_id != targetOrder.product_id');
      return false;
    }

    if (
        [this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"], this.CONSTANTS.Coupon.TYPE_CODE["買多折扣"]].includes(this.type_code*1) && 
        !this.buy_product 
      ) {
      alert('order_coupon_buy_product_validate 沒有 load buy_product');
      return false;
    }  
    if (this.gift_product_id && !this.gift_product ) {
      alert('order_coupon_buy_product_validate 沒有 load couponModel.gift_product');
      return false;
    } 
 
    if (success && this.buy_count > quantity) {
      success = false;
      this.errorMessages.push("數量未達到優惠標準");
      this.noticeMessages.push(`再購買${ this.buy_count - quantity }件商品可使用此優惠`);

      this.insufficient_buy_products[targetOrder.uuid] = this.insufficient_buy_products[targetOrder.uuid] ?? {};
      this.insufficient_buy_products[targetOrder.uuid]["quantity"] = this.buy_count - quantity; 
      this.insufficient_buy_products[targetOrder.uuid]["product_id"] = this.buy_product_id; 
      this.insufficient_buy_products[targetOrder.uuid]["product_name"] = this.buy_product.name;   
    } 

    if ( [this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"], this.CONSTANTS.Coupon.TYPE_CODE["滿額贈品"]].includes(this.type_code) ) {
      if ( !this.gift && this.gift_product.stock_qty && this.gift_product.stock_qty < this.gift_count ) {
        success = false;
        this.errorMessages.push("贈品庫存不足"); 
        this.noticeMessages.push(`贈品庫存不足`);

        this.insufficient_buy_products[targetOrder.uuid] = this.insufficient_buy_products[targetOrder.uuid] ?? {};
        this.insufficient_buy_products[targetOrder.uuid]["error"] = "贈品庫存不足"; 
      }   
    }

    if (success) {
      this.can_be_used_to_target_order = this.can_be_used_to_target_order ?? {};
      this.can_be_used_to_target_order[targetOrder.uuid] = true;
    }
 
    return success;
  }

 
  // order_coupon_gift_product_validate(targetOrder) {
  //   let success = true; 
  //   // this.errorMessages = [];
  //   // this.noticeMessages = [];
  //   this.insufficient_gift_products_of_target_order_with_coupon = [];

  //   if (!targetOrder.quantity) {
  //     alert('order_coupon_gift_product_validate 沒有定義 targetOrder.quantity');
  //     return this;
  //   }

  //   let quantity = targetOrder.quantity ?? 0 ;
 
  //   if (!this.CONSTANTS) {
  //     alert('order_coupon_gift_product_validate 沒定義 this.CONSTANTS');
  //     return false;
  //   }
  //   if (this.buy_product_id != targetOrder.product_id) {
  //     alert('order_coupon_gift_product_validate 的 targetOrder 的 this.buy_product_id != targetOrder.product_id');
  //     return false;
  //   }

  //   if (
  //       [this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"], this.CONSTANTS.Coupon.TYPE_CODE["買多折扣"]].includes(this.type_code*1) && 
  //       !this.buy_product 
  //     ) {
  //     alert('order_coupon_gift_product_validate 沒有 load buy_product');
  //     return false;
  //   }  
  //   if (this.gift_product_id && !this.gift_product ) {
  //     alert('order_coupon_gift_product_validate 沒有 load couponModel.gift_product');
  //     return false;
  //   } 
 
  //   if (success && this.buy_count > quantity) {
  //     success = false;
  //     this.errorMessages.push("數量未達到優惠標準");
  //     this.noticeMessages.push(`再購買${ this.buy_count - quantity }件商品可使用此優惠`);
  //     this.insufficient_gift_products_of_target_order_with_coupon = [
  //       ...this.insufficient_gift_products_of_target_order_with_coupon,
  //       { quantity: this.buy_count - quantity, product_id : this.buy_product_id, product_name: this.buy_product.name  }
  //     ]
  //   } 

  //   if ( [this.CONSTANTS.Coupon.TYPE_CODE["買多贈品"], this.CONSTANTS.Coupon.TYPE_CODE["滿額贈品"]].includes(this.type_code) ) {
  //     if (success && !this.gift && this.gift_product.stock_qty && this.gift_product.stock_qty < this.gift_count ) {
  //       success = false;
  //       this.errorMessages.push("贈品庫存不足"); 
  //       this.noticeMessages.push(`贈品庫存不足`);
  //     }   
  //   }
 
  //   return success;
  // }

 
  amount_coupon_validate(amount){
    let success = true;
    let canBeUsed = true;
    this.errorMessages = [];
    this.noticeMessages = [];

    // if ( success && amount < this.limit_amount ) {
    //   success = false;
    //   canBeUsed = false;
    //   this.errorMessages.push("優惠非針對此商品");
    // }
    if ( success && amount < this.limit_amount ) {
      success = false;
      this.errorMessages.push("金額未達到優惠標準");
      this.noticeMessages.push(`金額達到${ this.limit_amount }可使用此優惠`);
    }    
 
    return {
      success: success,
      canBeUsed: canBeUsed
    };
  } 
 
  //======================================================




  // validate_coupon_limit(CONSTANTS, type, options){
 
  //   if (type == CONSTANTS.CouponLimit.RESOURCE_TYPE["會員"]) {
  //     let memberCouponLimit = this.coupon_limits.filter(function(v,i){ return v.resource_type == CONSTANTS.CouponLimit.RESOURCE_TYPE["會員"] });
  //     return {
  //       limitString: memberCouponLimit.map((limit, index)=>{ return limit?.resource?.name }).join(','),
  //       validate: ( memberCouponLimit.length == 0 || memberCouponLimit.filter(function(v,i){ return v?.resource?.id == userId }).length > 0),
  //       needToValidate: memberCouponLimit.length == 0 ? false : true 
  //     }
  //   }

  //   if (type == CONSTANTS.CouponLimit.RESOURCE_TYPE["會員等級"]) {
  //     let memberLevelCouponLimit = this.coupon_limits.filter(function(v,i){ return v.resource_type == CONSTANTS.CouponLimit.RESOURCE_TYPE["會員等級"] });
  //     return {
  //       limitString: memberLevelCouponLimit.map((limit, index)=>{ return limit?.resource?.name }).join(','),
  //       validate: ( memberLevelCouponLimit.length == 0 || memberLevelCouponLimit.filter(function(v,i){ return v?.resource?.id == options.memberLevelId }).length > 0),
  //       needToValidate: memberLevelCouponLimit.length == 0 ? false : true
  //     } 
  //   }
  //   if (type == CONSTANTS.CouponLimit.RESOURCE_TYPE["商品標籤"]) {

  //     if ( !Array.isArray(options.allProductTagRelations) ) {
  //       alert('options.allProductTagRelations 尚未定義');
  //       return;
  //     }

  //     let productTagCouponLimit = this.coupon_limits.filter(function(v,i){ return v.resource_type == CONSTANTS.CouponLimit.RESOURCE_TYPE["商品標籤"] });
  //     let validate = false;
  //     let productIds = options.orders.map(o=>o.product_id);
  //     let productTagRelations = options.allProductTagRelations.filter(r => productIds.includes(r.product_id));

  //     // ( await (new ProductTagRelationModel()
  //     //   .select('product_tag_relations.*, products.name')
  //     //   .join(" INNER JOIN products ON products.id = product_tag_relations.product_id")
  //     //   .where("product_id in ?", [productIds])
  //     //   .get_datas()) ).rows;

  //     let couponLimitProductTagIds = productTagCouponLimit.map((limit)=>limit.resource_id);
 
  //     // 商品標籤 與 限制標籤 是否有交集, 有交集算是符合條件
  //     let productTagIsValid = false;
  //     for (let productTagRelation of productTagRelations) {
  //       if (couponLimitProductTagIds.indexOf(productTagRelation.product_tag_id) !== -1) {
  //         productTagIsValid = true;
  //         productTagRelation.valid = true;
  //       }
  //     }

  //     return {
  //       limitString: productTagCouponLimit.map((limit, index)=>{ 
  //         let validProductNames = productTagRelations.filter(p=>(p.valid && limit["resource_id"] == p.product_tag_id)).map(o=>o.name);
  //         let limitString = `${ limit["resource"]["name"] }(${ Array.from(new Set(validProductNames)).join(',') })`;
  //         return limitString;
  //       }).join(','),
  //       validate: ( productTagCouponLimit.length == 0 || productTagIsValid ) , 
  //       needToValidate: productTagCouponLimit.length == 0 ? false : true
  //     }  
  //   }
  // }

  // async get_datas(currentPage, pageSize) {
 
  async get_datas(options = {}) {
    let result = await super.get_datas(); 
     
    if(options["loadRelativedTables"]) { 
      for( let row of result.rows ) { 
        let couponModel = new CouponModel(row); 
        await couponModel.load_coupon_limits();
        row["coupon_limits"] = couponModel["coupon_limits"];
      } 
    }

    return result;
  } 
 
  validate_column_logic_sql(){
    return [{ 
      sql: "SELECT * FROM coupons WHERE id = {@coupon_id} AND coupons.updated_by IS NOT NULL AND coupons.created_by IS NOT NULL", 
      dynamicParams: {
        coupon_id: function(results){    
          return results.coupons.rows[0].id;  
        }
      },
      validate: function(currentResult, allResults){   
        let success = (currentResult.rows.length != 0); 
        return success;
      },
      errorMessage: "updated_by 或是 created_by 沒有設定"
    }];  
  } 

  async save_by_ajax(params) { 
    let couponJson = this.to_json(["id","site_id","type_code","name","code","discount_type","discount","limit_amount","limited_count","start_time","end_time","disabled","used_count","used_desc","limit_used","gift","created_by","updated_by","created_at","updated_at","buy_product_id","gift_product_id","buy_count","gift_count","limit_user_used_count","coupon_limits","coupon_product_relations"]);
  
    couponJson.coupon_limits = couponJson.coupon_limits ? couponJson.coupon_limits.to_coupon_limits_class() : [];
    for (let couponLimit of couponJson.coupon_limits ) { 
      couponLimit = couponLimit.to_json(["id","coupon_id","resource_type","resource_id","used_at","created_at","updated_at"]);
    }
 
    couponJson.coupon_product_relations = couponJson.coupon_product_relations ? couponJson.coupon_product_relations.to_coupon_product_relations_class() : [];
    for (let couponProductRelation of couponJson.coupon_product_relations ) {
      couponProductRelation = couponProductRelation.to_json(["id","coupon_id","product_id","product_model_id","type","created_at","updated_at"]);
    }
 
    let result = await super.save_ajax({ 
      tablePluralName: "coupons",
      tableSingularName: "coupon", 
      data : couponJson,
      id : couponJson.id,
      domain : params["domain"],
      isNew : couponJson.id ? false : true,
      isBackend : [undefined, null, ""].includes(params["isBackend"]) ? true : params["isBackend"]
    });

    return result;
  }

  save_sql(options = {}){ 
    let that = this; 

 

    // this.afterSaveData = {...this};
    // let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
    //   "coupons", 
    //   "id,site_id,type_code,name,code,discount_type,discount,limit_amount,limited_count,start_time,end_time,disabled(FalseIfBlank),used_count,used_desc,limit_used(FalseIfBlank),gift,created_by,updated_by,created_at,updated_at,buy_product_id(NullIfEmpty),gift_product_id(NullIfEmpty),buy_count,gift_count,limit_user_used_count", 
    //   {
    //     shipment_id: function(results){   
    //       return results.shipments.rows?.first()?.id 
    //     }
    //   }  
    // )[0];

    // saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
    //   that.afterSaveData["id"] = result.rows[0].id;
    // }};

    // let couponModel = [{...this.afterSaveData}].to_coupons_class().first();
  
    // let couponLimitSaveSql = [];
    // let destroyAllCouponProductRelationsSql = [];
    // let couponProductRelationsSaveSql = [];
      
    // if( options["saveRelationTable"] ) {
      // for(const couponLimit of this.coupon_limits){
      //   if(couponLimit["destroy"]){ 
      //     couponLimitSaveSql = [
      //       ...couponLimitSaveSql, 
      //       ...(new CouponLimitModel(couponLimit)).destroy_sql() 
      //     ]; 
      //   } else {
      //     couponLimitSaveSql = [
      //       ...couponLimitSaveSql, 
      //       ...(new CouponLimitModel(couponLimit)).save_sql({
      //         dynamicParams: {
      //           coupon_id: function(results) {   
      //             return results.coupons.rows[0].id;  
      //           }
      //         }
      //       }) 
      //     ];          
      //   } 
      // }

      // if (this.id) {
      //   destroyAllCouponProductRelationsSql = [
      //     ...destroyAllCouponProductRelationsSql,
      //     ...(new CouponProductRelationModel().where("coupon_id",this.id).destroy_sql())
      //   ] 
      // }

      // if (this.coupon_product_relations) { 
      //   for ( let couponProductRelationModel of this.coupon_product_relations) { 

      //     if (![this.buy_product_id, this.gift_product_id].includes(couponProductRelationModel.product_id)) {
      //       alert(' couponmodel save_sql coupon_product_relations 裡面的 product_id 與 buy_product_id 或是 gift_product_id 不符合');
      //       return;
      //     }

      //     couponProductRelationsSaveSql = [
      //       ...couponProductRelationsSaveSql, 
      //       ...couponProductRelationModel.save_sql({
      //         dynamicParams: {
      //           coupon_id: function(results) {   
      //             return results.coupons.rows[0].id;  
      //           }
      //         }
      //       }) 
      //     ];

      //   }
      // }
    // }
 
    // return [
    // saveSql,   
    // ...this.validate_column_logic_sql(),
    // ...couponLimitSaveSql,
    // ...destroyAllCouponProductRelationsSql,
    // ...couponProductRelationsSaveSql
    // ]
  }

  load_coupon_limits(coupon_limits) { 
    if (coupon_limits) { 
      this["coupon_limits"] = coupon_limits.filter(o => o.coupon_id == this["id"]);
    } 
    return this; 
  } 
 
  // async batch_load_coupon_limits(coupons){ 
  //   let couponIds = coupons.map(c => c.id); 
  //   let couponLimits = (await (new CouponLimitModel()).where("coupon_id in ? ",[couponIds]).get_datas()).rows.to_coupon_limits_class();

  //   coupons = coupons.to_coupons_class();
  //   for ( let coupon of coupons ) {
  //     coupon.load_coupon_limits(couponLimits); 
  //   }
 
  //   return coupons;
  // } 
}
 
export class CouponLimitModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "coupon_limits";
  }
 
  random_data() { 
    let data = { 
      id : Math.floor(Math.random() * 1000000),
      coupon_id : Math.floor(Math.random() * 1000000),
      resource_type : Math.random().toString(36).substring(7),
      resource_id : Math.floor(Math.random() * 1000000),
      used_at : new Date(),
      created_at : new Date(),
      updated_at : new Date()
    } 

    for (const key in data) { 
      this[`${key}`] = data[key];
    }  
  }

  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  }  
 
  validate_coupon_id_sql(){ 
    if (this.id) {
      return [{ 
        sql: `
          SELECT * 
          FROM coupon_limits 
          INNER JOIN coupons ON coupon_limits.coupon_id = coupons.id 
          WHERE coupon_limits.id = $1
        `, 
        params: [ this.id ],
        validate: function(currentResult, allResults){   
          let success = (currentResult.rows.length != 0); 
          return success;
        },
        errorMessage: "無相對應的優惠資料"
      }];
    } else {
      return [];
    } 
  }

  validate_column_logic_sql(){
    return [
      ...this.validate_coupon_id_sql()
    ];
  } 

  filter_coupon(params) {
    let {
      coupons, 
      CONSTANTS, 
      userId, 
      memberLevelId, 
      couponLimits = null 
    } = params;

    let passCoupon = [];  
    coupons.load_coupon_limits(couponLimits);

    for ( let coupon of coupons ) {
      let allPass = {};

      //檢查限制
      for ( let limit of coupon.coupon_limits ) {
        let result = limit.validate_coupon_limit(CONSTANTS, userId, memberLevelId);
        if (allPass[result["type"]] == undefined) {
          allPass[result["type"]] = result["valid"];
        } else {
          allPass[result["type"]] = allPass[result["type"]] || result["valid"];
        } 
      }

      //如果符合限制就留下
      if (!Object.values(allPass).includes(false)) { 
        passCoupon.push(coupon);
      } 
    }
    return passCoupon;
  }
 
  validate_coupon_limit(CONSTANTS, userId, memberLevelId) {
    let valid = false;
    if (this.resource_type == CONSTANTS.CouponLimit.RESOURCE_TYPE["會員"]) {
      valid = (this.resource_id == userId);
    }

    if (this.resource_type == CONSTANTS.CouponLimit.RESOURCE_TYPE["會員等級"]) {
      valid = (this.resource_id == memberLevelId);
    } 

    return {
      type: this.resource_type,
      valid: valid
    };
  } 

  save_sql(options = {}){ 
    let that = this; 
    let dynamicParams = options["dynamicParams"] ?? {};
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      "coupon_limits", 
      `id,coupon_id${ dynamicParams["coupon_id"] ? "(IsDynamicParam)" : "" },resource_type,resource_id,used_at,created_at,updated_at`,
      {...dynamicParams}  
    )[0];

    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};
 
    return [
    saveSql,   
    ...this.validate_column_logic_sql()
    ]
  }

  async load_resource(resources) { 
    if (!["ProductTag","MemberLevel","User"].includes(this.resource_type)) { 
      alert(`請先定義 resource_type 且必須是 ProductTag, MemberLevel, User 其中之一`);
      return null;
    } 
    if (!this.resource_id) { 
      alert(`請先定義 resource_id`);
      return null;
    }

    let row = {};
    if ( this.resource_type == "ProductTag" ) {
      row = await this.load_one_relation_table_by_foreign_key(resources, "product_tags", "product_tag", "resource_id");
    } else if( this.resource_type == "MemberLevel" ) {
      row = await this.load_one_relation_table_by_foreign_key(resources, "member_levels", "member_level", "resource_id");
    } else if( this.resource_type == "User" ) {
      row = await this.load_one_relation_table_by_foreign_key(resources, "users", "user", "resource_id");
    }

    return row; 
  } 

}
 
export class ProductPriceModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "product_prices";
  }
  
  async get_datas(options) {
    let result = await super.get_datas()
    return result;
  }  
  
  validate_column_logic_sql(){
    return [ 
    ];
  }  
 
  save_sql(options = {}){ 
    let that = this; 
    let dynamicParams = options["dynamicParams"] ?? {};
    this.afterSaveData = {...this};
    let saveSql = [{...this.afterSaveData}].toSaveSqlObjs(
      "product_prices", 
      `id,product_id${ dynamicParams["product_id$"] ? "(IsDynamicParam)" : "" },member_level_id,product_model_id(NullIfEmpty),price(NullIfEmpty),created_at,updated_at`,
      {...dynamicParams}  
    )[0];
 
    saveSql = {...saveSql, afterExcute: function(result, sqlString, sqlParams, datasByTableName){
      that.afterSaveData["id"] = result.rows[0].id;
    }};
 
    return [
    saveSql,   
    ...this.validate_column_logic_sql()
    ]
  } 
}
  
export class ProductImageModel extends Model {
  constructor(that) { 
    super(that)
    this.tableName = "product_images";
  } 
}
 

//saveSqls = new SqlTest().insert_between(saveSqls);
export class SqlTest {
  insert_between(array) {
    let index = 0;
    let testSql = { 
      sql: "SELECT * FROM member_levels WHERE id=0", 
      params: [],
      validate: function(currentResult, allResults){ 
        console.log(index++);
        let success = true; 
        return success;
      },
      errorMessage: "就是錯" 
    };

    return array.reduce((acc, curr, index) => {
      if (index !== 0) {
        acc.push(testSql);
      }
      acc.push(curr);
      return acc;
    }, []);
  } 
}


export class InvoiceProduct {
  constructor() {   
    this.is_gift = null;
    this.is_last = null;
    this.product_id = null; 
    this.product_model_id = null; 
    this.product_models = null; 
    this.message_id = null;
    // this.gift = null; 
    this.quantity = null; 
    this.price = null; 
    this.product_name = null; 
    this.stock_qty = null; 
    this.update_quantity_fn = null;
    this.success = true;
    this.errors = [];
    this.uuid = null;
    this.type_code = null;
    // this.coupon_relation = null; 
  }
  
  set_product(productId, productModelId, product, productModels){ 
    this.product_id = productId;
    this.product_model_id = productModelId;
    this.product = product;
    this.product_models = productModels;

    if ( this.product_id ) {
      if (this.product_model_id) {
        this.stock_qty = this.product_models?.filter(m => m.id == this.product_model_id)?.first()?.stock_qty;  
      } else {
        this.stock_qty = this.product?.stock_qty;  
      }
    }

    return this;
  }

  set_uuid(uuid){
    this.uuid = uuid; 
    return this;
  }
  set_is_last(is_last){
    this.is_last = is_last; 
    return this;
  }
  set_message_id(message_id){
    this.message_id = message_id; 
    return this;
  }
  set_data(productName, quantity, price, gift, isGift, typeCode) { 
    this.is_gift = isGift;
    this.type_code = typeCode;
    this.gift = gift;
    this.quantity = quantity;
    this.price = price; 
    this.product_name = productName; 
    return this;
  }
 
  set_update_quantity_fn(fn){
    this.update_quantity_fn = fn; 
    return this;
  }

  set_quantity(quantity) {
    this.quantity = (quantity ?? 0)*1;
    return this;
  }
 
  new_update_quantity_fn(giftOrders){
    // let giftCount = 0;
    return function(invoice_product_uuid, quantity, invoiceProduct){  
      let targetGiftOrder = giftOrders.filter(o => o.invoice_product_uuid == invoice_product_uuid).first();
      targetGiftOrder.quantity = quantity;

      let giftCount = targetGiftOrder.coupon_relation.gift_count;

      let sum = 0;
      for ( let giftOrder of giftOrders ) {
        sum = sum + giftOrder.quantity;
      }

      if (invoiceProduct.stock_qty && (invoiceProduct.stock_qty - quantity) < 0 ) {
        invoiceProduct.success = false;
        invoiceProduct.errors = [`商品【${ invoiceProduct.product_name }】庫存只有${ invoiceProduct.stock_qty }個,請重新選擇`];    
      } else if ( sum > giftCount ) {
        invoiceProduct.success = false;
        invoiceProduct.errors = [`贈品數量過多請減少${ sum - giftCount }個`];        
        // return `贈品數量過多請減少${ sum - giftCount }個`;
      } else if( sum < giftCount ) {
        invoiceProduct.success = false;
        invoiceProduct.errors = [`可再選擇${ giftCount - sum }個商品`];   
        // return `可再選擇${ giftCount - sum }個商品`;
      } else {
        invoiceProduct.success = true;
        invoiceProduct.errors = [];
      }
    }
  }
 
  create_invoice_product_list(shipment, CONSTANTS){ 
    let that = this;
    let invoiceProductList = []; 
    for (let order of shipment.buy_orders) {
      let thisCouponRelation = order?.coupon_relation;
 
      invoiceProductList = [...invoiceProductList, 
        (new InvoiceProduct())
        .set_data(`${ order.product.name }${ order.model ? `(${ order.model })` : `` }`,order.quantity, order.price, null, false, thisCouponRelation?.type_code)
        .set_product(order.product_id, order.product_model_id, order?.product, order?.product?.product_models)
      ]
      if (
        order.coupon_relation &&   
        [CONSTANTS.Coupon.TYPE_CODE["買多贈品"], CONSTANTS.Coupon.TYPE_CODE["買多折扣"]].includes(order.coupon_relation.type_code)
      ) {   
        if (CONSTANTS.Coupon.TYPE_CODE["買多贈品"] == order.coupon_relation.type_code) { 
          if ( thisCouponRelation.gift_product_id ) {
            let giftOrders = [];
            let newUpdateQuantityFn = this.new_update_quantity_fn(giftOrders);
            let messageId = Utility.generateID();
            for (let giftOrder of order.coupon_relation.gift_orders ) {
              giftOrder.invoice_product_uuid = "id"+Utility.generateID();
              giftOrders.push(giftOrder);
              invoiceProductList = [...invoiceProductList, 
                (new InvoiceProduct())
                .set_message_id(messageId)
                .set_update_quantity_fn(newUpdateQuantityFn)
                .set_uuid(giftOrder.invoice_product_uuid) 
                .set_product(giftOrder.product_id, giftOrder.product_model_id, giftOrder.product, giftOrder?.product?.product_models)
                .set_data(`${ thisCouponRelation.gift_product.name }${ giftOrder.model ? `(${ giftOrder.model })` : `` }`, giftOrder.quantity, giftOrder.price, null, true, thisCouponRelation?.type_code)
              ]
            } 
            invoiceProductList[invoiceProductList.length-1].set_is_last(true);
          } else {
            invoiceProductList = [...invoiceProductList, 
              (new InvoiceProduct())
              .set_data(thisCouponRelation.gift, thisCouponRelation.gift_count, null, thisCouponRelation.gift, true, thisCouponRelation?.type_code)
              .set_product(order.product_id, order.product_model_id, order?.product, order?.product?.product_models) 
            ]
          } 
        } else if(CONSTANTS.Coupon.TYPE_CODE["買多折扣"] == order.coupon_relation.type_code){
          invoiceProductList = [...invoiceProductList, 
            (new InvoiceProduct())
            .set_data("折扣金額", 1, -1*order.discount_amount, null, true, thisCouponRelation?.type_code)

            // (productName, quantity, price, gift, is_gift)
            // .set_product(order.product_id, order.product_model_id, order?.product, order?.product?.product_models) 
          ]
        }
 
      } 
    } 
    return invoiceProductList;
  }
} 





export class Cart {
  constructor() {  
    this.CONSTANTS = null;
    this.Swal = null;
    this.membership = null; 
    this.created_by = null;  
    this.site = null;  
    this.orders = [];
    this.all_active_coupons = null;
    this.shipment = new ShipmentModel();
  }

  async get_all_active_coupons(params){
    let {
      coupon_product_relations: [],
      product_models: [],
      product: []
    } = params;

    if (this.all_active_coupons) {
      return this.all_active_coupons;
    }

    //判斷優惠
    let activeCoupons = (await (new CouponModel()).active().get_datas()).rows.to_coupons_class(); 
     
    for ( let coupon of activeCoupons ) {
      coupon.set_uuid();
    }

    //根據優惠限制 過濾出可以被使用的優惠 
    activeCoupons = await (new CouponLimitModel()).filter_coupon({ 
      coupons : activeCoupons, 
      CONSTANTS: this.CONSTANTS, 
      userId : this.membership.user.id, 
      memberLevelId : this.membership.member_level.id 
    });

    //批次讀取資料
    activeCoupons = (new CouponModel()).batch_load_coupon_product_relations_and_related_product({
      CONSTANTS : this.CONSTANTS, 
      coupons : activeCoupons,      
      coupon_product_relations : coupon_product_relations,
      products : products,
      product_models : product_models
    });

    this.all_active_coupons = activeCoupons;

    return this.all_active_coupons;
  }

  async set_active_coupons() {
    if ( !this?.membership?.user?.id || !this?.membership?.member_level?.id ) {
      alert('請先 set_membership');
      return this;
    }  

    this.orders = this.orders.to_orders_class();

    if (!this.orders || this.orders.length == 0) {
      return this;
    }

    let allActiveCoupons = await this.get_all_active_coupons();
 
    for ( let order of this.orders ) {
      order.set_uuid();

      //不處理 優惠商品 或是 贈品
      if ( !order.coupon_type && order.status == this.CONSTANTS.Order.STATUS["現場結帳中"] ) {
        order
          .set_import({ "Swal" : this.Swal, "CONSTANTS" : this.CONSTANTS })
          .set_active_coupons(allActiveCoupons);        
      } 
    }
 
    return this;
  }

  set_site(site) { 
    this.site = site; 
    return this;
  }

  set_import(params) {  
    for( let key in params ) { 
      this[key] = params[key];
    } 
    return this;
  }
  
  set_editor(createdBy) {
    this.created_by = createdBy;  
    return this;   
  }

  async set_membership(membershipId, onSiteCheckoutOrders = [], activeCoupons = [], currentSite = null) {
    //優惠需要重新讀取 這裡設定為null , get_all_active_coupons 會重新讀取
    this.all_active_coupons = null;

    if (!membershipId) {
      this.membership = null;
      return this;
    }
 
    let result = await new MembershipModel() 
    .set_import({
      "Swal" : this.Swal, 
      "CONSTANTS" : this.CONSTANTS
    })
    .get_datas_by_ajax({
      "id" : membershipId,
      "domain" : this.site.domain,
      "isBackend" : true,
    });

    // let membership = new MembershipModel((await new MembershipModel().where("id", membershipId).get_datas()).rows[0]);
    // await membership.load_user();
    // await membership.load_member_level();
    // await membership.load_advance_balance();

    let membership = result?.datas?.to_memberships_class()?.first();
    if (membership) {
      await membership.load_user(result?.users ?? []);
      await membership.load_member_level(result?.member_levels ?? []);
      await membership.load_advance_balance(result?.advance_balances ?? []);
    }
  
    this.membership = membership;  
    this.remove_shipment_coupon_relation();
  
    this.active_coupons = [];
    this.shipment = new ShipmentModel({ site_id: this.membership.site_id});
    this.shipment
      .set_import({ "CONSTANTS" : this.CONSTANTS })
      .load_site([currentSite]);

    await this.refresh_shipment(activeCoupons, currentSite);
 
    return this;
  }
 
  get_not_on_sale_and_being_checkout_product(ean, productId, productModelId) {
 
    let orders = this.orders.filter(order => !order.coupon_type && order.status == this.CONSTANTS.Order.STATUS["現場結帳中"] ); 

    if (ean) { 
      orders = orders.filter(order => ( order.product_model_id ? ( order?.product?.product_models?.filter(m => order?.product_model_id == m.id && m.ean == ean ).length > 0 ) : (order?.product?.ean === ean)) );
    } else if(productId && productModelId) { 
      orders = orders.filter(order => ( order.product_model_id ? ( order?.product?.product_models?.filter(m => order?.product_model_id == m.id && m.id == productModelId ).length > 0 ) : (order?.product?.id === productId)) );
    }

    let order = orders.first(); 

    if (order) { 
      return order;
    }
    return null;
  }
 
  set_orders_coupon_relation() { 
    let that = this;
    let allCoupons = [];
    for ( let order of this.orders ) {
      if (order.active_coupons) { 
        allCoupons = [...allCoupons, ...order.active_coupons ];
      }
    } 

    function create_coupon_relation(orders, allCoupons, index) {

      let coupon = allCoupons[index];

      if (!coupon) {
        return orders;
      }

      let couponModel = [coupon].to_coupons_class()?.first();
  
      //已經套用優惠的訂單不再繼續配對
      let notMatchedOrders = that.orders
                            .filter(o => !o.coupon_relation || o.coupon_relation.coupon_can_be_used() ) //剔除已經採用優惠的訂單
                            .filter(o => couponModel.gift_product_id == o.product_id || couponModel.buy_product_id == o.product_id ); //剔除跟這次優惠搭不上邊得訂單
   
      let ordersQuantityWas = {};
      for (let notMatchedOrder of notMatchedOrders ) {
        ordersQuantityWas[notMatchedOrder.uuid] = notMatchedOrder.quantity; 
      }

      let newSplitOrders = couponModel
                          .set_import({ "CONSTANTS" : that.CONSTANTS })
                          .simulate_orders_apply_coupon(ordersQuantityWas, notMatchedOrders);
 
      orders = [ ...orders, ...newSplitOrders ]; 
      return create_coupon_relation(orders, allCoupons, index+1);
    }

    let orders = create_coupon_relation(that.orders.filter(o => o.status == this.CONSTANTS.Order.STATUS["現場結帳中"]), allCoupons, 0);

    // create_coupon_relation 處理的是 現場結帳中 的訂單 , 處理完後需要再把 非現場結帳中 的訂單放回去
    this.orders = [ ...orders, ...this.orders.filter(o => o.status != this.CONSTANTS.Order.STATUS["現場結帳中"]) ];

    this.orders = this.orders.filter(o => o.quantity );
 
    // this.shipment
    //   .set_order_amount(this.get_shipment_orders_amount())
    //   .set_tax_and_discount_amount()// 結帳優惠還沒做
    //   // .set_tax(this.site.tax_included, this.site.tax_rate)  
    //   .set_total_amount(); 
 
    return this.orders;
  }

  get_all_quantity_qty(orders, stockQtyList = {}) {
    let that = this; 

    if (orders.length == 1) {
      let order = orders.first(); 
      stockQtyList[order.product_id] = stockQtyList[order.product_id] ? stockQtyList[order.product_id] : {};

      if (order.product_model_id) {
        stockQtyList[order.product_id][order.product_model_id] = stockQtyList[order.product_id][order.product_model_id] ? stockQtyList[order.product_id][order.product_model_id] : {};
        stockQtyList[order.product_id][order.product_model_id].quantity = stockQtyList[order.product_id][order.product_model_id].quantity ? (order.quantity + stockQtyList[order.product_id][order.product_model_id].quantity) : order.quantity;
        stockQtyList[order.product_id][order.product_model_id].model = order.model;
        stockQtyList[order.product_id][order.product_model_id].type = 'product_model';
      } else {
        stockQtyList[order.product_id].quantity = stockQtyList[order.product_id].quantity ? (order.quantity + stockQtyList[order.product_id].quantity) : order.quantity;
        stockQtyList[order.product_id].name = order.product.name;
        stockQtyList[order.product_id].type = 'product';
      } 
    } else {
      for ( let order of orders ) {
        if (order.coupon_relation) {  
          if (order.coupon_relation.buy_order) { 
            that.get_all_quantity_qty([order.coupon_relation.buy_order], stockQtyList);
          }

          if (order.coupon_relation.gift_orders) {
            for ( let giftOrder of order.coupon_relation.gift_orders ) {
              that.get_all_quantity_qty([giftOrder], stockQtyList);
            }
          }
 
        } else { 
          that.get_all_quantity_qty([order], stockQtyList);
        } 
      }      
    } 

    return stockQtyList;
  }


  get_product_ids_and_product_model_ids(orders, currentOrder) {
    let that = this; 
    let productIds = [];
    let productModelIds = [];

    if (orders) { 
      for ( let order of orders ) {
        let { product_ids, product_model_ids } = that.get_product_ids_and_product_model_ids(null, order);
        productIds = [...productIds, ...product_ids];
        productModelIds = [...productModelIds, ...product_model_ids];
      }      
    } else if( currentOrder ) {
      if (currentOrder.coupon_relation) { 
        if (currentOrder.coupon_relation.buy_order) { 
          let buyOrder = currentOrder.coupon_relation.buy_order;
          productIds.push(buyOrder.product_id); 
          if (buyOrder.product_model_id) { 
            productModelIds.push(buyOrder.product_model_id); 
          }   
        }

        if (currentOrder.coupon_relation.gift_orders) {
          for ( let giftOrder of currentOrder.coupon_relation.gift_orders ) { 
            productIds.push(giftOrder.product_id); 
            if (giftOrder.product_model_id) { 
              productModelIds.push(giftOrder.product_model_id); 
            } 
          }
        }  

      } else { 
        productIds.push(currentOrder.product_id); 
        if (currentOrder.product_model_id) { 
          productModelIds.push(currentOrder.product_model_id); 
        }  
      }
    } 
    return {
      product_ids: productIds.unique(),
      product_model_ids: productModelIds.unique()
    };
  }


  check_stock_qty(stockQtyList, productId = "", productName = "", productModelId = "", productModelName = "" , quantity = 0){
    let that = this;
    let validateSqls = [];
    if (stockQtyList) { 
      for ( let key in stockQtyList ) {
        if (stockQtyList[key]["type"] == 'product') { 
          // that.check_stock_qty(null, key, stockQtyList[key]["name"], null, null, stockQtyList[key]['quantity'], validateSqls )
          validateSqls = [ ...validateSqls, ...that.check_stock_qty(null, key, stockQtyList[key]["name"], null, null, stockQtyList[key]['quantity']) ];

          //處理 product_models
          for ( let productModelId in stockQtyList[key] ) {
            //如果是數字 就代表是 product_model_id
            if (isNaN(productModelId) == false) { 
              // that.check_stock_qty(null, key, stockQtyList[key]["name"], productModelId, stockQtyList[key][productModelId]['model'], stockQtyList[key][productModelId]['quantity'], validateSqls )
              validateSqls = [ ...validateSqls, ...that.check_stock_qty(null, key, stockQtyList[key]["name"], productModelId, stockQtyList[key][productModelId]['model'], stockQtyList[key][productModelId]['quantity']) ];
            }
          } 
        }  
      }      
    } else if( productId ) {
 
      if (!quantity) {
        return validateSqls;
      } 

      if (productModelId) {
        validateSqls = [ ...validateSqls, { 
          sql: "SELECT * FROM product_models WHERE id=$1 AND stock_qty IS NOT NULL AND stock_qty < $2 ", 
          params: [productModelId, quantity],
          validate: function(currentResult, allResults){ 
            let success = false; 
            return success;
          },
          errorMessage: `${ productName }(${ productModelName })庫存不足`
        }];
      } else {
        validateSqls = [ ...validateSqls, { 
          sql: "SELECT * FROM products WHERE id=$1 AND stock_qty IS NOT NULL AND stock_qty < $2 ", 
          params: [productId, quantity],
          validate: function(currentResult, allResults){ 
            let success = false; 
            return success;
          },
          errorMessage: `${ productName }庫存不足`
        }];
      }
    }
 
    return validateSqls;
  }

  async destroy_old_checkout_orders() { 
    let destroyOrders = (await new OrderModel().where("status", this.CONSTANTS.Order.STATUS["現場結帳中"]).get_datas()).rows.to_orders_class();
    for (let order of destroyOrders ) {
      await order.destroy({destroyRelationTable:true, CONSTANTS: this.CONSTANTS});
    } 
  }

  validate_orders() {
    let that = this;
    let success = true;
    let errors = [];

    for ( let order of this.orders ) { 
      if ( success && (order.shipped_qty + ( order.shipped_qty_for_shipment ?? 0 ) ) > order.quantity ) {
        success = false;
        errors.push('訂單出貨數量超出正常數量');
      }
    }

    if (!success) {
      setTimeout(function(){
        that.Swal.fire('發生錯誤', errors.join(','), 'error');       
      });      
    }
 
    return success;
  }
 
  async save_orders() {
    let that = this;

    if (!that.validate_orders()) {
      return;
    }

    //沒有id的儲存到資料庫
    let orders = this.orders.filter(o => !o.id);
 
    let { product_ids , product_model_ids } = that.get_product_ids_and_product_model_ids(orders);
 
    let products = product_ids.length == 0 ? [] : (await (new ProductModel()).where(" id in ? ",[product_ids]).get_datas()).rows.to_products_class();
    let productModels = product_model_ids.length == 0 ? [] : (await (new ProductModelClass()).where(" id in ? ",[product_model_ids]).get_datas()).rows.to_product_models_class();
    let allSql = [];
    let allErrors = []; 
    for ( let orderModel of orders.filter(o => o.coupon_type != this.CONSTANTS.CouponProductRelation.TYPE["贈品"]) ) { 
      let { sql, errors } = await that.order_to_sql(orderModel, products, productModels);
      allSql = [ ...allSql, ...sql ]; 
      allErrors = [ ...allErrors, ...errors ];  

      if (allErrors.length > 0) {
        break;
      }
    }
 
    if (allErrors.length > 0) {
      await that.Swal.fire('發生錯誤', allErrors.join(','), 'error'); 
    } else if ( allSql.length >= 0 ) { 
      let sqlObjs = [{sql: "BEGIN"}, 
        ...allSql, 
        // { 
        //   sql: "SELECT * FROM member_levels WHERE id=0", 
        //   params: [],
        //   validate: function(currentResult, allResults){ 
        //     let success = false; 
        //     return success;
        //   },
        //   errorMessage: "就是錯" 
        // },                       
        {sql: "END"}]; 

      if ( this.orders.length > 0 ) {
        await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => {
          let { success, errors } = await that.check_all_orders_stock_qty();

          if (success) { 
            await that.save_shipment();
          } else {  
            rollbackOrderData(orders); 
            await that.Swal.fire('發生錯誤', errors.join(','), 'error');  
          } 
        }).catch(async err => { 
          if( err?.message ){ 
            await that.Swal.fire('發生錯誤', err.message, 'error'); 
            rollbackOrderData(orders);
          } 
          throw err;
        }); 
      } else {
        let { success, errors } = await that.check_all_orders_stock_qty();

        if (success) { 
          await that.save_shipment();
        } else { 
          rollbackOrderData(orders);
          await that.Swal.fire('發生錯誤', errors.join(','), 'error');  
        } 
      } 
    } 
 
    function rollbackOrderData(orders){
      for ( let order of orders ) {
        order.id = null;
        order.coupon_relation_id = null; 
      }
    } 
  }   

  async check_all_orders_stock_qty() {
    let that = this;
    let success = true;
    let errors = [];

    let { product_ids , product_model_ids } = that.get_product_ids_and_product_model_ids(this.orders);
 
    //再次讀取資料 用來判斷 庫存是否足夠
    let products = product_ids.length == 0 ? [] : (await (new ProductModel()).where(" id in ? ",[product_ids]).get_datas()).rows.to_products_class();
    let productModels = product_model_ids.length == 0 ? [] : (await (new ProductModelClass()).where(" id in ? ",[product_model_ids]).get_datas()).rows.to_product_models_class();

    for ( let productModel of productModels ) {
      productModel.load_product(products);
      if (success && ![undefined, null, ""].includes(productModel.stock_qty) && productModel.stock_qty*1 < 0 ) {
        success = false;
        errors.push(` ${ productModel.product.name }(${ productModel.name }) 庫存不足 缺少${ productModel.stock_qty*-1 }件 `);
      }
    }
    for ( let product of products ) {
      if (success && ![undefined, null, ""].includes(product.stock_qty) && product.stock_qty*1 < 0 ) {
        success = false;
        errors.push(` ${ product.product.name } 庫存不足 缺少${ product.stock_qty*-1 }件 `);
      }
    }

    return {
      success : success,
      errors : errors
    };
  }

  async save_shipment(){
    let that = this;
    let shipmentSql = [];
    let allErrors = []; 

    shipmentSql = await that.shipment_sql();
  
    let sqlObjs = [{sql: "BEGIN"}, 
      ...shipmentSql, 
      // { 
      //   sql: "SELECT * FROM member_levels WHERE id=0", 
      //   params: [],
      //   validate: function(currentResult, allResults){ 
      //     let success = false; 
      //     return success;
      //   },
      //   errorMessage: "就是錯" 
      // },
      {sql: "END"}]; 

    await window.db.queryWithPool(sqlObjs, "ROLLBACK").then(async (results) => {
      await that.Swal.fire('成功', '儲存成功', 'success'); 
    }).catch(async err => { 
      if( err?.message ){ 
        await that.Swal.fire('發生錯誤', err.message, 'error'); 
      }  
    }); 
 
    // if (allErrors.length > 0) {
    //   await that.Swal.fire('發生錯誤', allErrors.join(','), 'error'); 
    // } else if ( shipmentSql.length > 0 ) { 
 
    // } 
  }
 
  get_shipment_orders_amount() { 
    let shipmentOrders = this?.orders?.filter(item => item.coupon_type != this.CONSTANTS.CouponProductRelation.TYPE["贈品"] ) ?? [];
    let sum = shipmentOrders.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.total_price;
    }, 0); 
    return sum;
  } 

  shipment_sql() {
    let shipmentSql = [];
    // let shipment = new ShipmentModel();

    if ( this.orders && this.orders.length > 0) {
      let firstOrder = this.orders[0];

      if (this.membership.user_id != firstOrder.user_id) {
        alert('shipment_sql user_id 不同步');
        return [];
      }

      this.shipment 
        .set_import({ "CONSTANTS" : this.CONSTANTS })
        // .set_remark(remark) //結帳 沒有地方填寫
        .set_buyer(this.membership.user_id)
        .set_receiver(this.membership.user.name, this.membership.user.mobile)
        .set_shipped_at(new Date())
        .set_status(this.CONSTANTS.Shipment.STATUS["已取貨"]);
        // .set_order_amount(this.get_shipment_orders_amount())
        // .set_tax(this.site.tax_included, this.site.tax_rate)  
        // .set_discount_amount(0); //結帳優惠 還沒做
        // .set_advance_amount(0) // 儲值金額 還沒做
        // .set_total_amount();

      // this.orders 裡面包含滿額優惠的order, 原因是為了方便在結帳畫面顯示資訊, 這裡必須先替除掉 才能算是 shipment的orders
      this.shipment.orders  = this.orders.filter(o => ![this.CONSTANTS.Coupon.TYPE_CODE["滿額折扣"], this.CONSTANTS.Coupon.TYPE_CODE["滿額贈品"]].includes(o?.coupon_relation?.type_code)); 
  
      return this.shipment.save_sql({
        saveRelationTable: true,
        CONSTANTS: this.CONSTANTS,
        coupons: null  
      });
    } else { 
      return [];
    }  
 
  }

  async order_to_sql(order, products, productModels) {
    let sql = [];
    let errors = [];

    if (
      order.coupon_relation && 
      ( order.coupon_relation.type_code == this.CONSTANTS.Coupon.TYPE_CODE["滿額贈品"] ||
        order.coupon_relation.type_code == this.CONSTANTS.Coupon.TYPE_CODE["滿額折扣"]
      )
    ) {
      return {
        sql: [],
        errors : []
      };
    }

    //有優惠
    if (order.coupon_relation) { 
      //coupon_relation sql
      let couponRelationSql = order.coupon_relation.save_sql({
        saveRelationTable: true, 
        CONSTANTS: this.CONSTANTS,  
      });      

      sql = [ ...sql, ...couponRelationSql ];   

      //buy_order sql   ps. buy_order 基本上就是 order本身
      let buyOrderSaveResult = (await order.coupon_relation.buy_order.save_sql({ 
        saveRelationTable:true, 
        passStockQtyValidate: true,         
        afterSaveCallBack: function(result, sqlString, sqlParams, datasByTableName){
          order.coupon_relation.buy_order.id = result.rows[0].id; 
        },   
        dynamicParams: { 
          coupon_relation_id: function(results) {   
            return results?.coupon_relations?.rows[0]?.id; 
          }
        }
      }));    
 
      if (buyOrderSaveResult["errors"] && buyOrderSaveResult["errors"].length > 0) {
        errors = [ ...errors, ...buyOrderSaveResult["errors"] ];
      } else { 
        sql = [ ...sql, ...buyOrderSaveResult["sql"] ];  
      } 
 
      //gift_orders sql   
      for (let giftOrder of ( order.coupon_relation.gift_orders ?? [])) {
        giftOrder.load_product(products);
        let giftOrderSaveResult = (await giftOrder.save_sql({  
          caches: {
            products: products, //批次處理 
            productModels: productModels //批次處理
          }, 
          saveRelationTable:true, 
          passStockQtyValidate: true,         
          afterSaveCallBack: function(result, sqlString, sqlParams, datasByTableName){
            giftOrder.id = result.rows[0].id; 
          },  
          dynamicParams: { 
            coupon_relation_id: function(results) {   
              return results?.coupon_relations?.rows[0]?.id; 
            }
          }
        }));

        if (giftOrderSaveResult["errors"] && giftOrderSaveResult["errors"].length > 0) {
          errors = [ ...errors, ...giftOrderSaveResult["errors"] ];
        } else { 
          sql = [ ...sql, ...giftOrderSaveResult["sql"] ];  
        }  

        if (errors.length > 0) {
          break;
        }
      }
 
    } else {

      //沒有優惠 
      let orderSaveResult = (await order.save_sql({ 
        saveRelationTable:true, 
        passStockQtyValidate: true, 
        caches: {
          products: products, //批次處理 
          productModels: productModels //批次處理
        },         
        afterSaveCallBack: function(result, sqlString, sqlParams, datasByTableName){
          order.id = result.rows[0].id; 
        } 
      }));   
 
      if (orderSaveResult["errors"] && orderSaveResult["errors"].length > 0) {
        errors = [ ...errors, ...orderSaveResult["errors"] ];
      } else { 
        sql = [ ...sql, ...orderSaveResult["sql"] ];  
      }  
    } 

    return {
      sql: sql,
      errors : errors
    };
  }

  remove_shipment_coupon_relation() { 
    if ( this?.shipment?.coupon_relation ) { 
      if (this.shipment.coupon_relation?.gift_orders) { 
        for ( let giftOrder of this.shipment.coupon_relation.gift_orders ) {
          giftOrder
            .set_quantity_and_price(giftOrder.quantity, giftOrder.shipped_qty_for_shipment_was, giftOrder.price_was, null, giftOrder.cost_was, giftOrder.shipped_qty_was, this.CONSTANTS)
            .set_discount_amount(this.CONSTANTS)
            .set_total_price();

          giftOrder.coupon_relation = null; 
        }  
      }
  
      this.shipment.coupon_relation = null;
    }

    this.orders = this.orders.filter(o => o.quantity*1 );
  }

  async refresh_shipment(activeCoupons = null, currentSite = null){ 
    let allActiveCoupons = activeCoupons ? activeCoupons : (await this.get_all_active_coupons());
    let shipmentActiveCoupons = allActiveCoupons.filter(c => (c.type_code == this.CONSTANTS.Coupon.TYPE_CODE["滿額贈品"] || c.type_code == this.CONSTANTS.Coupon.TYPE_CODE["滿額折扣"]))

    shipmentActiveCoupons = shipmentActiveCoupons.sort((item1, item2) => item2.limit_amount - item1.limit_amount);
 
    //先清除 shipment 優惠 優惠的贈品 加回去 orders
    // this.remove_shipment_coupon_relation(); 

    shipmentActiveCoupons = shipmentActiveCoupons.to_coupons_class();
    for ( let coupon of shipmentActiveCoupons ) {
      let newSplitOrders = coupon
                          .set_import({ "CONSTANTS" : this.CONSTANTS })
                          .simulate_shipment_apply_coupon(this.orders, this.shipment, this.get_shipment_orders_amount());
      if (newSplitOrders.length > 0) {
        this.orders = [ ...newSplitOrders, ...this.orders ];
        break;
      }
    }
 
    // this.orders = this.orders.filter(o => o.quantity && ( !o.coupon_relation || ![this.CONSTANTS.Coupon.TYPE_CODE["滿額折扣"], this.CONSTANTS.Coupon.TYPE_CODE["滿額贈品"]].includes(o?.coupon_relation?.type_code) ) );

    this.shipment.active_coupons = shipmentActiveCoupons;

    if (!this.shipment.site) {
      await this.shipment.load_site([currentSite]);
    }

    this.shipment
      .set_order_amount(this.get_shipment_orders_amount())
      .set_tax_and_discount_amount()// 結帳優惠還沒做
      // .set_tax(this.site.tax_included, this.site.tax_rate) // tax 還沒算
      .set_total_amount(); 

    this.orders = this.combine_orders(this.orders);  
  }
 
  //有時候訂單分割又還原 導致明明一樣的訂單 分成好幾份 這個function 用來合併同樣的訂單
  combine_orders(orders, tempOrders = []) {
    let currentOrder = orders.shift();

    if (currentOrder) {
      for (let order of orders) {
        if (
          !order.coupon_relation && 
          !currentOrder.coupon_relation &&
          !order.shipped_qty && 
          !currentOrder.shipped_qty && 
          order.status == this.CONSTANTS.Order.STATUS["現場結帳中"] && 
          currentOrder.status == this.CONSTANTS.Order.STATUS["現場結帳中"] &&                    
          currentOrder.product_id == order.product_id &&
          currentOrder.product_model_id == order.product_model_id &&
          currentOrder.price == order.price 
        ) {

          //增加quantity
          currentOrder.set_quantity_and_price( currentOrder.quantity*1 + order.quantity*1, currentOrder.shipped_qty_for_shipment, currentOrder.price, null, currentOrder.cost, 0, this.CONSTANTS)
            .set_discount_amount(this.CONSTANTS)
            .set_total_price();

          //減少quantity
          order.set_quantity_and_price( 0, order.shipped_qty_for_shipment, order.price, null, order.cost, 0, this.CONSTANTS)
            .set_discount_amount(this.CONSTANTS)
            .set_total_price(); 
        }
      }     

      tempOrders.push(currentOrder);
    } else {
      return tempOrders;
    }
 
    return this.combine_orders(orders.filter(o => o.quantity != 0), tempOrders);
  }

  async remove_order(uuid){
    //清除滿額優惠 待後續重新計算
    this.remove_shipment_coupon_relation();

    this.orders = this.orders.filter(o => o.uuid != uuid);
  
    //取得目前可用的所有優惠
    await this.set_active_coupons();  

    //訂單 以及 商品優惠 處理
    this.set_orders_coupon_relation(false);  

    //出貨單 以及 滿額優惠 處理
    await this.refresh_shipment(); 
 
    this.orders = this.orders.filter(o => o.quantity*1 ); 
    return this;  
  }
 
  async set_shipped_qty_for_shipment(uuid, shippedQtyForShipment){
    for ( let order of this.orders ) {
      if (order.uuid == uuid) { 
           
        //清除滿額優惠 待後續重新計算
        this.remove_shipment_coupon_relation();
       
        if (order.coupon_relation) {
          await this.Swal.fire('發生錯誤', "優惠商品不可部分出貨", 'error'); 
          return this;
        }
 
        //出貨量不能超出 quantity
        shippedQtyForShipment = shippedQtyForShipment > ( order.quantity - order.shipped_qty ) ? ( order.quantity - order.shipped_qty ) : shippedQtyForShipment;  

        order.set_quantity_and_price( order.quantity, shippedQtyForShipment, order.price, order.coupon_type, order.cost, order.shipped_qty, this.CONSTANTS)
          .set_discount_amount(this.CONSTANTS)
          .set_total_price();

        //取得目前可用的所有優惠
        await this.set_active_coupons();  

        //訂單 以及 商品優惠 處理
        this.set_orders_coupon_relation(false);  

        //出貨單 以及 滿額優惠 處理
        await this.refresh_shipment(); 
      } 
    }
    return this;
  }

  async add_order(orderQty, ean = null, productId = null, productModelId = null) {
  
    //清除滿額優惠 待後續重新計算
    this.remove_shipment_coupon_relation();

    let order = this.get_not_on_sale_and_being_checkout_product(ean, productId, productModelId);
    if (order) {  
      order.set_quantity_and_price( order.quantity*1 + orderQty*1, order.shipped_qty_for_shipment, order.price, null, order.cost, 0, this.CONSTANTS)
        .set_discount_amount(this.CONSTANTS)
        .set_total_price();

      // await this.refresh_shipment();
 
    } else {
 
      if (!ean) { 
        await this.Swal.fire('發生錯誤', "商品條碼為空值", 'error');  
        return this;
      }

      if (!orderQty) { 
        await this.Swal.fire('發生錯誤', "商品數量尚未輸入", 'error'); 
        return this;
      }
   
      let success = true;
      let productModelDatas = await new ProductModelClass().where("ean", ean).get_datas();
      let productData = null;
      let productModel = null;  
      let user = this.membership.user;
      let member_level = this.membership.member_level;

      if(!user || !member_level) {
        await this.Swal.fire('尚未指定購買人', "請先點選右側的輸入會員", 'error'); 
        return this;
      }
   
      let currentPrice = null;
      let currentProductModelId = null;
   
      if(productModelDatas.rows.length > 0){
        //如果 國際條碼是屬於product_model 的 需要使用此product_model 的 product 資料 
        // let a = await new ProductModelClass().where("id", -1).get_datas()
        productModel = new ProductModelClass(productModelDatas.rows[0]);
        await productModel.load_product();
        productModel.product = [productModel.product].to_products_class().first();
        let allProductPrices = (await new ProductPriceModel().where("product_id in ?", [[productModel.product_id]]).get_datas()).rows.to_product_prices_class();
        productModel.product.load_product_prices(allProductPrices);
        productModel.load_product_prices(allProductPrices); 
        currentProductModelId = productModel.id;
        currentPrice = await productModel.get_current_price(member_level.id);
        productData = productModel.product;  
      } else {
        //如果 國際條碼是不屬於product_model 就再用ean 去抓取 product資料
        // let product = new ProductModel();
        let productResult = await new ProductModel().where("ean", ean).get_datas(); 
        if( productResult && productResult.rows[0]) {
          productData = productResult.rows.to_products_class().first(); 
          await productData.load_product_prices(); 
          currentPrice = await productData.get_current_price(member_level.id); 
        } else { 
          await this.Swal.fire('發生錯誤', "查無此商品", 'error');  
          return this;
        }
      }

      if( currentPrice == null ) { 
        await this.Swal.fire('發生錯誤', "金額計算發生錯誤", 'error');  
        return this;
      }

      success = productData ? true : false;

      if(!success){
        await this.Swal.fire('發生錯誤', "查無此商品", 'error');  
        return this;
      } 
     
      if(success) {

        order = new OrderModel().set_import({ CONSTANTS: this.CONSTANTS }); 
        order 
          .set_uuid()
          .set_site(this.site.id) 
          .set_status(this.CONSTANTS.Order.STATUS["現場結帳中"], this.created_by) 
          .set_is_on_site_checkout_order(true)
          .set_buyer_related_information(this.membership.user.id, this.membership.member_level.id, this.membership.user.name, this.membership.user.mobile)
          .set_product_related_information(productData.id, currentProductModelId, productModel ? productModel.name : "") 
          .set_constants(this.CONSTANTS.Order.SALE_TYPE["現貨"], this.CONSTANTS.Order.SOURCE["POS機"])
          .set_editor(this.created_by) 
          .set_quantity_and_price(orderQty, null, currentPrice, null, productData.cost, 0, this.CONSTANTS)
          .set_discount_amount(this.CONSTANTS)
          .set_total_price();

        await order.load_product();
        order.product = [order.product].to_products_class().first();
        await order.product.load_product_models(); 
   
      }
   
      if (order) { 
        this.orders = [ order, ...this.orders ];
      } 

    }
 
 
    //取得目前可用的所有優惠
    await this.set_active_coupons();  

    //訂單 以及 商品優惠 處理
    this.set_orders_coupon_relation(false);  

    //出貨單 以及 滿額優惠 處理
    await this.refresh_shipment(); 


    this.orders = this.orders.filter(o => o.quantity*1);
    return this;
  }
}   
 
export class AuthorizationModel extends Model {
  constructor(that) { 
    super(that) 
  }
   
  async login_by_form(params) {
    await new SessionModel().set_session({
      auth: { is_login: true, is_extension_child_window : ( window.outerWidth <= 300 && window.outerHeight <= 300 ? true : false ) }
    });    
    new FormClass({ action: "/users/sign_in", method: "POST", data: params }).submit();
  }
  async register_by_form(params) {
    await new SessionModel().set_session({
      auth: { is_login: false, is_extension_child_window : ( window.outerWidth <= 300 && window.outerHeight <= 300 ? true : false ) }
    });    
    new FormClass({ action: "/users", method: "POST", data: params }).submit();
  }  
  async facebook_login_by_form(params = {}) {
    await new SessionModel().set_session({
      auth: { is_login: true, is_extension_child_window : ( window.outerWidth <= 300 && window.outerHeight <= 300 ? true : false ) }
    });    
    new FormClass({ action: "/users/auth/facebook_for_customer", method: "GET", data: params }).submit();
  }  
  async facebook_register_by_form(params = {}) {
    await new SessionModel().set_session({
      auth: { is_login: false, is_extension_child_window : ( window.outerWidth <= 300 && window.outerHeight <= 300 ? true : false ) }
    }); 
    new FormClass({ action: "/users/auth/facebook_for_customer", method: "GET" }).submit();
  }  

} 
  

export class ExtensionModel extends Model {
  constructor(that) { 
    super(that) 
  }

  async get_user_sites_by_ajax(params = {}) {   
    params["isAjax"] = true; 
    let searchParams = new URLSearchParams(params);
 
    return await this.ajax({
      ...params,
      method: "GET",   
      url:`/extensions/get_user_sites.json?${ searchParams.toString() }`
    }); 
  }
} 
  

export class SessionModel extends Model {
  constructor(that) { 
    super(that) 
  }
   
  async set_session(body) { 
    return await super.ajax({ 
      method:"PUT",  
      body:body,
      url:'/sessions/set_session' 
    });  
  } 
} 
 
export class FormClass {
  constructor(options) {  
    let that = this;
    this.$form = $('<form>', {
      action: options.action,
      method: options.method,
      style: 'display: none;'
    });
 
    $.each(options.data, function(name, value) {
      $('<input>', {
        type: 'hidden',
        name: name,
        value: value
      }).appendTo(that.$form);
    });
  }
  
  submit() {
    this.$form.appendTo('body');
    this.$form.submit();  
  }  
} 
 
 
